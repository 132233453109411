import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Brand } from 'src/app/models/interfaces/brand';
import { BrandsService } from 'src/app/services/brands/brands.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ConfirmationDialogComponent } from '../../alert/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  dataSource = new MatTableDataSource<Brand>();
  displayedColumns: string[] = ["name", "rank", "delete"];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  //Filter
  brandSearchText?: string

  constructor(private router: Router,
    private brandsService: BrandsService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.getBrands()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  getBrands() {
    this.brandsService.getAllBrands(this.getQueryParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<Brand>(data.data.results);
            if (data.data.results.length < 1 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  onAddClick() {
    this.router.navigate(['./brands/create'])
  }

  onFilterSubmit() {
    this.getBrands()
  }

  onFilterClear() {
    this.brandSearchText = undefined;
    this.getBrands()
  }

  deleteBrand(id: string) {
    this.brandsService.deleteBrand(id).
      pipe(first())
      .subscribe(
        data => {
          if (data) {
            this.utilities.showSnackbar(data.message)
            this.getBrands();
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  deleteAfterConfirmation(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Do you really want to delete this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteBrand(id);
      }
    });
  }

  navigateToDetails(id: string) {
    this.router.navigate(['./brands/' + id])
  }

  pageChanged(event: PageEvent) {
    this.getBrands()
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.getBrands()
    }
  }

  private getQueryParams(): any {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    if (this.brandSearchText) {
      params["search"] = this.brandSearchText;
    }
    return params;
  }

}

<mat-card class="push-content">
    <mat-card-content>
        <form [formGroup]="pushNotificationForm">
            <mat-form-field class = "tp-full-width">
                <mat-label>Notification Title</mat-label>
                <input matInput placeholder = "Notification Title" formControlName="notificationTitle">
                <mat-error *ngIf="f.notificationTitle.hasError('required')">
                    Title is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class = "tp-full-width">
                <mat-label>Notification Content</mat-label>
                <textarea matInput placeholder = "Notification Content" formControlName="notificationBody"></textarea>
                <mat-error *ngIf="f.notificationBody.hasError('required')">
                    Notification body is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Push Notification Type</mat-label>
                <mat-select formControlName="pushNotificationType" (selectionChange)="pushNotificationTypeChanged()">
                    <mat-option *ngFor="let type of pushNotificationTypes" [value]="type.value">
                        {{type.viewValue}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.pushNotificationType.hasError('required')">
                    Push Notification Type is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="tp-full-width" appearance="fill" *ngIf="showSubscriptionTypes">
                <mat-label>Subscription Type</mat-label>
                <mat-select formControlName=pushNotificationSubscriptionType>
                    <mat-option *ngFor="let subscription of availableSubscriptionTypes" [value]="subscription.value">
                      {{subscription.viewValue}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.pushNotificationSubscriptionType.hasError('required')">
                    Notification Subscription Type is Required!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width-field" appearance="outline"  (click)="openDialog('user')" *ngIf="showUserSpecific">
                <mat-label>Notification User</mat-label>
                <input matInput placeholder="Notification User" formControlName="notificationUser" readonly>
                <mat-error *ngIf="f.notificationUser.hasError('required')">
                    Notification User is Required!
                </mat-error>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions class="update-mat-card-actions">
        <button mat-raised-button (click)="reset()" color="basic">Reset</button>
        <button mat-raised-button (click)="submit()" color="warn">Submit</button>
    </mat-card-actions>
</mat-card>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Order } from 'src/app/models/interfaces/order';
import { OrderDetails } from 'src/app/models/interfaces/order-details';
import { OrderProductItem } from 'src/app/models/interfaces/order-product-item';
import { OrderStatus } from 'src/app/models/internal/order-status';
import { OrderStatusTypes } from 'src/app/models/objects/order-status-types';
import { OrderValueDetailsResponseData } from 'src/app/models/response/orders/order-value/order-value-details-response-data';
import { OrderValueResponseData } from 'src/app/models/response/orders/order-value/order-value-response-data';
import { OrdersServiceService } from 'src/app/services/orders/orders-service.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  orderId!: string

  availableOrderStatusTypes!: OrderStatus[]

  orderDetails?: OrderDetails

  orderUpdatedStatus?: string

  orderValueResponse?: OrderValueDetailsResponseData

  clearCoupon = false

  constructor(private route: ActivatedRoute,
    private ordersService: OrdersServiceService,
    private utilities: Utilities) {
    this.availableOrderStatusTypes = OrderStatusTypes;
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.orderId = inputId
    }
    this.callOrderDetails()
  }

  ngOnInit(): void {
  }

  update(): void {
    this.ordersService.updateOrderDetails(this.orderId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          this.callOrderDetails()
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  updateOrderValue() {
    this.ordersService.getOrderValueDetails(this.getOrderValueParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.orderValueResponse = data.data
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  updateOrderDetailsWithOrderValue(responseData: OrderValueDetailsResponseData) {
    if (this.orderDetails != null) {
      this.orderDetails.total_cost = (responseData.total_cost ?? 0).toString();
      this.orderDetails.discounted_cost = (responseData.discounted_cost ?? 0).toString();
      this.orderDetails.items = responseData.item_details
    }

  }

  getInvoicename(): string | undefined {
    return this.orderDetails?.invoice_path.split('/').slice(-1).pop()
  }

  invoiceAction(): void {
    if (this.orderDetails?.invoice_path) {

    } else {

    }
  }

  reset(): void {
    this.callOrderDetails()
    // this.resetUpdatedValues();
  }

  callGenerateInvoice() {
    this.ordersService.generateInvoice(this.orderId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.status == 1) {
            this.utilities.showSnackbar(data.message)
            this.callOrderDetails()
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  private callOrderDetails() {
    this.ordersService.getOrderDetails(this.orderId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.orderDetails = data.data
            this.updateOrderValue();
            this.resetUpdatedValues();
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  cartUpdateEvent(data: any) {
    this.clearCoupon = data["clearCoupons"] ?? false
    this.orderDetails!.items = data["products"] ?? []
    this.updateOrderValue()
  }

  private getUserUpdateParams(): any {
    let params: any = {}
    params["status"] = this.orderUpdatedStatus;

    let item_details: any = []
    this.orderDetails?.items?.forEach(element => {
      item_details.push({
        "id": element.id,
        "quantity": element.quantity,
        "item_cost": element.sold_cost,
        "coupon_id": this.clearCoupon ? null : element.coupon_details?.id
      })
    });
    params["is_coupon_applicable"] = !this.clearCoupon;
    params["items"] = item_details;
    return params
  }

  private resetUpdatedValues() {
    if (this.orderDetails) {
      this.orderUpdatedStatus = this.getOrderStatusIdFromStatus(this.orderDetails.status)
    }
  }

  private getOrderStatusIdFromStatus(status: string): string | undefined {
    return this.availableOrderStatusTypes.find(statusType => statusType.viewValue === status)?.value;
  }

  private getOrderValueParams(): any {
    let params: any = {}
    params["user_id"] = this.orderDetails?.user_id;
    let item_details: any = []
    this.orderDetails?.items?.forEach(element => {
      item_details.push({
        "id": element.id,
        "product_id": element.product_id,
        "quantity": element.quantity,
        "coupon_id": this.clearCoupon ? null : element.coupon_details?.id,
        "item_cost": element.sold_cost,
      })
    });
    params["item_details"] = item_details;
    return params;
  }

}

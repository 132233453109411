import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { AnalyticsResponseData } from 'src/app/models/response/analytics/analytics-response';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Utilities } from 'src/app/utilities/utilities';
import { UserSelectorDialogComponent } from '../dialogs/user-selector-dialog/user-selector-dialog.component';

interface AnalyticsTableData {
  position: number
  brand?: String
  category?: String
  subcategory?: String
  search?: String
}

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})

export class AnalyticsComponent implements OnInit {

  selectedUser?: ListItem

  displayedColumns: string[] = ['position', 'brand', 'category', 'subcategory', 'search'];
  dataSource = new MatTableDataSource<AnalyticsTableData>();


  analyticsResponseData?: AnalyticsResponseData
  constructor(private dialog: MatDialog,
    private service: AnalyticsService,
    private utilities: Utilities
  ) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(UserSelectorDialogComponent, {
      width: '40%',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.selectedUser = result
        this.fetchData()
      }
    })
  }

  fetchData() {
    if (this.selectedUser?.id == undefined) {
      return
    }
    this.service.getUserAnalytics(this.selectedUser!.id.toString())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.analyticsResponseData = data.data
            this.setupTableData();
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  setupTableData() {
    let data: AnalyticsTableData[] = []
    for (let i = 0; i < 5; i++) {
      const brand = (this.analyticsResponseData?.top_brands?.length ?? 0) > i ? this.analyticsResponseData?.top_brands![i] : undefined
      const category = (this.analyticsResponseData?.top_categories?.length ?? 0) > i ? this.analyticsResponseData?.top_categories![i] : undefined
      const subcategory = (this.analyticsResponseData?.top_sub_categories?.length ?? 0) > i ? this.analyticsResponseData?.top_sub_categories![i] : undefined
      const search = (this.analyticsResponseData?.top_search_keyword?.length ?? 0) > i ? this.analyticsResponseData?.top_search_keyword![i] : undefined
      data.push({
        position: i + 1,
        brand: brand,
        category: category,
        subcategory: subcategory,
        search: search
      })
    }
    this.dataSource.data = data
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { ItemType } from 'src/app/models/internal/item-type';
import { ItemTypes } from 'src/app/models/objects/item-types';
import { NewArrivalsService } from 'src/app/services/new-arrivals/new-arrivals.service';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { Location } from '@angular/common';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';

@Component({
  selector: 'app-new-arrivals-create',
  templateUrl: './new-arrivals-create.component.html',
  styleUrls: ['./new-arrivals-create.component.scss']
})
export class NewArrivalsCreateComponent implements OnInit {

  availableItemTypes!: ItemType[]

  selectedBrand?: ListItem
  selectedProduct?: ListItem
  selectedCategory?: ListItem
  selectedSubcategory?: ListItem

  @Input() newArrivalsForm!: FormGroup;

  fileToUpload: File | null = null;

  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private newArrivalsService: NewArrivalsService,
    private router: Router,
    private location: Location,
    private utilities: Utilities) {
    this.availableItemTypes = ItemTypes
  }

  ngOnInit(): void {
    this.newArrivalsForm = this.formBuilder.group({
      newArrivalsName: ['', Validators.required],
      newArrivalsRank: ['', Validators.required],
      newArrivalsContentType: ['', Validators.required],
      newArrivalsBrand: [''],
      newArrivalsProduct: [''],
      newArrivalsCategory: [''],
      newArrivalsSubcategory: [''],
    });
  }

  openDialog(type: string) {

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: { value: type } as ItemSelectorDialogModel

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.updateSelectedItem(type, result)
      }
    })
    this.f.newArrivalsBrand.markAsUntouched()
    this.f.newArrivalsCategory.markAsUntouched()
    this.f.newArrivalsSubcategory.markAsUntouched()
    this.f.newArrivalsProduct.markAsUntouched()
  }

  get f() { return this.newArrivalsForm.controls; }

  get showBrand() { return this.f.newArrivalsContentType.value && (this.f.newArrivalsContentType.value == "brand" || this.f.newArrivalsContentType.value == "combined") }

  get showProduct() { return this.f.newArrivalsContentType.value && (this.f.newArrivalsContentType.value == "product") }

  get showCategory() { return this.f.newArrivalsContentType.value && (this.f.newArrivalsContentType.value == "category" || this.f.newArrivalsContentType.value == "combined") }

  get showSubcategory() { return this.f.newArrivalsContentType.value && (this.f.newArrivalsContentType.value == "sub-category" || this.f.newArrivalsContentType.value == "combined") }


  updateSelectedItem(type: string, value: ListItem) {

    switch (type) {
      case "brand": {
        this.f.newArrivalsBrand.setValue(value.name)
        this.selectedBrand = value
        break;
      }
      case "category": {
        this.f.newArrivalsCategory.setValue(value.name)
        this.selectedCategory = value
        break;
      }
      case "sub-category": {
        this.f.newArrivalsSubcategory.setValue(value.name)
        this.selectedSubcategory = value
        break;
      }
      case "product": {
        this.f.newArrivalsProduct.setValue(value.name)
        this.selectedProduct = value
        break;
      }
    }
  }

  reset() {
    this.resetSelectedItem()
    this.newArrivalsForm.reset()
  }

  submit() {
    this.newArrivalsForm.markAllAsTouched()
    if (this.newArrivalsForm.invalid == true) {
      return;
    }
    if (this.selectedBrand == null && this.selectedCategory == null && this.selectedSubcategory == null && this.selectedProduct == null) {
      this.utilities.showSnackbar("Select atleast one item (Brand/Category/Subcategory/Product)")
      return;
    }
    if (this.fileToUpload == null) {
      this.utilities.showSnackbar("Add image to upload")
      return
    }
    this.newArrivalsService.createNewArrivals(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.data) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }
  resetSelectedItem() {
    this.selectedBrand = undefined;
    this.selectedCategory = undefined;
    this.selectedSubcategory = undefined;
    this.selectedProduct = undefined;
    this.f.newArrivalsBrand.setValue(undefined)
    this.f.newArrivalsCategory.setValue(undefined)
    this.f.newArrivalsSubcategory.setValue(undefined)
    this.f.newArrivalsProduct.setValue(undefined)
    this.updateValidators()
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  getParams(): any {
    let formData: FormData = new FormData();
    formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    formData.append('name', this.f.newArrivalsName.value);
    formData.append('rank', this.f.newArrivalsRank.value);
    formData.append('content_type', this.f.newArrivalsContentType.value);
    if (this.selectedBrand) {
      formData.append("brand", this.selectedBrand!.id.toString());
    }
    if (this.selectedProduct) {
      formData.append("product", this.selectedProduct!.id.toString());
    }
    if (this.selectedCategory) {
      formData.append("category", this.selectedCategory!.id.toString());
    }
    if (this.selectedSubcategory) {
      formData.append("sub_category", this.selectedSubcategory!.id.toString());
    }
    return formData;
  }

  updateValidators() {

    this.f.newArrivalsBrand.removeValidators(Validators.required)
    this.f.newArrivalsCategory.removeValidators(Validators.required)
    this.f.newArrivalsSubcategory.removeValidators(Validators.required)
    this.f.newArrivalsProduct.removeValidators(Validators.required)

    switch (this.f.newArrivalsContentType.value) {
      case "brand": {
        this.f.newArrivalsBrand.setValidators(Validators.required)
        break;
      }
      case "category": {
        this.f.newArrivalsCategory.setValidators(Validators.required)
        break;
      }
      case "sub-category": {
        this.f.newArrivalsSubcategory.setValidators(Validators.required)
        break;
      }
      case "product": {
        this.f.newArrivalsProduct.setValidators(Validators.required)
        break;
      }
      default: {
        break;
      }
    }
    this.f.newArrivalsBrand.updateValueAndValidity()
    this.f.newArrivalsCategory.updateValueAndValidity()
    this.f.newArrivalsSubcategory.updateValueAndValidity()
    this.f.newArrivalsProduct.updateValueAndValidity()
    this.newArrivalsForm.markAsUntouched()
  }
}

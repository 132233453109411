<mat-form-field>
    <input matInput [(ngModel)]=searchText (keyup)="applyFilter()" placeholder="Search {{selectedType?.viewValue}}">
</mat-form-field>
<mat-dialog-content>
    <table mat-table [dataSource]="dataSource" class="mat=elevation-z8 full-width-table">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [mat-dialog-close]="row"></tr>
        <tr class = "mat-row mat-no-data" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                No result found
            </td>
        </tr>
    </table>
    <mat-paginator #paginator [length]="totalItemsCount" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 50, 100]" (page)="pageChanged($event)">
    </mat-paginator>
</mat-dialog-content>
<mat-dialog-actions>
      <button mat-button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { NewArrival } from 'src/app/models/interfaces/new-arrival';
import { ItemType } from 'src/app/models/internal/item-type';
import { ItemTypes } from 'src/app/models/objects/item-types';
import { NewArrivalsService } from 'src/app/services/new-arrivals/new-arrivals.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ConfirmationDialogComponent } from '../../alert/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-new-arrivals-list',
  templateUrl: './new-arrivals-list.component.html',
  styleUrls: ['./new-arrivals-list.component.scss']
})
export class NewArrivalsListComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  dataSource = new MatTableDataSource<NewArrival>();
  displayedColumns: string[] = ["name", "type", "item", "rank", "delete"];
  availableNewArrivalsItemTypes!: ItemType[]

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  newArrivalsSearchText?: string
  newArrivalsFilterItemType?: string

  constructor(private router: Router,
    private newArrivalsService: NewArrivalsService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.availableNewArrivalsItemTypes = ItemTypes
    this.getNewArrivals()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  onAddClick() {
    this.router.navigate(['./new-arrivals/create'])
  }

  onFilterSubmit() {
    this.getNewArrivals()
  }

  onFilterClear() {
    this.newArrivalsSearchText = undefined;
    this.newArrivalsFilterItemType = undefined;
    this.getNewArrivals()
  }

  deleteNewArrivals(id: string) {
    this.newArrivalsService.deleteNewArrivals(id)
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data) {
            this.getNewArrivals();
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  deleteAfterConfirmation(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Do you really want to delete this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteNewArrivals(id);
      }
    });
  }

  navigateToDetails(id: string) {
    this.router.navigate(['./new-arrivals/' + id])
  }

  getNewArrivals() {
    this.newArrivalsService.getAllNewArrivals(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<NewArrival>(data.data.results);
            if (data.data.results.length < 1 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  pageChanged(event: PageEvent) {
    this.getNewArrivals()
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.getNewArrivals()
    }
  }

  getParams(): any {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    if (this.newArrivalsSearchText) {
      params["search"] = this.newArrivalsSearchText;
    }
    if (this.newArrivalsFilterItemType) {
      params["content_type"] = this.newArrivalsFilterItemType;
    }
    return params;
  }


}

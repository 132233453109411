import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { StoreList } from 'src/app/models/interfaces/store-list';
import { StoreService } from 'src/app/services/store/store.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-store-selector-dialog',
  templateUrl: './store-selector-dialog.component.html',
  styleUrls: ['./store-selector-dialog.component.scss']
})
export class StoreSelectorDialogComponent {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  dataSource = new MatTableDataSource<StoreList>();
  displayedColumns: string[] = ["address", "pincode"];

  constructor(public dialogRef: MatDialogRef<StoreSelectorDialogComponent>,
    private storeService: StoreService,
    private utilities: Utilities) {
    this.fetchStoreList()
  }


  fetchStoreList() {
    this.storeService.getStoreList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<StoreList>(data.data.results);
            if (data.data?.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.fetchStoreList()
    }
  }

  pageChanged(event: PageEvent) {
    this.fetchStoreList()
  }

  getParams() {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    return params;
  }

}

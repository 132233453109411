import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CategoryService } from 'src/app/services/category/category.service';
import { Location } from '@angular/common';
import { Utilities } from 'src/app/utilities/utilities';


@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss']
})
export class CategoryCreateComponent implements OnInit {

  @Input() categoryForm!: FormGroup;

  fileToUpload: File | null = null;

  constructor(private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private router: Router,
    private location: Location,
    private utilities: Utilities) { }

  ngOnInit(): void {
    this.categoryForm = this.formBuilder.group({
      categoryName: ['', Validators.required],
      categoryRank: ['', Validators.required]
    });
  }

  get f() { return this.categoryForm.controls; }

  reset() {
    this.fileToUpload = null
    this.categoryForm.reset()
  }

  submit() {
    this.categoryForm.markAllAsTouched()
    if (this.categoryForm.invalid == true) {
      return;
    }
    if (this.fileToUpload == null) {
      this.utilities.showSnackbar("Add image to upload")
      return
    }
    this.categoryService.createCategory(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.data) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        });
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  getParams(): any {
    let formData: FormData = new FormData();
    formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    formData.append('name', this.f.categoryName.value);
    formData.append('rank', this.f.categoryRank.value);
    return formData;
  }
}

<mat-toolbar color="primary" class="expanded-toolbar">
  <span>
    WAREHAUS Admin
  </span>
  <span class="spacer"></span>
  <button mat-icon-button class="logout-button" *ngIf="authService.currentUserValue" (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
  </button>
</mat-toolbar>
<div *ngIf="loaderService.isLoading | async" class="loading-container">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-sidenav-container>
  <mat-sidenav #drawer *ngIf="authService.isAdminUser" mode="side" opened role="navigation">
    <mat-nav-list>
      <a mat-list-item routerLink='/orders'><mat-icon>assignment</mat-icon>Orders</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/users'><mat-icon>people</mat-icon>Users</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/seller'><mat-icon>person</mat-icon>Sellers</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/brands'><mat-icon>turned_in</mat-icon>Brands</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/categories'><mat-icon>category</mat-icon>Categories</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/sub-categories'><mat-icon>apps</mat-icon>Sub Categories</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/products'><mat-icon>shopping_cart</mat-icon>Products</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/featured-products'><mat-icon>stars</mat-icon>Featured Products</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/new-arrivals'><mat-icon>fiber_new</mat-icon>New Arrivals</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/coupons'><mat-icon>style</mat-icon>Coupons</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/stores'><mat-icon>store</mat-icon>Stores</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/bulk-update'><mat-icon>update</mat-icon>Bulk Update</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/push-notification'><mat-icon>message</mat-icon>Push Notification</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink='/analytics'><mat-icon>analytics</mat-icon>Analytics</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav #drawer *ngIf="authService.isSellerUser" mode="side" opened role="navigation">
    <mat-nav-list>
      <a mat-list-item routerLink='/products'><mat-icon>shopping_cart</mat-icon>Products</a>
      <mat-divider></mat-divider>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="expanded-toolbar" *ngIf="authService.currentUserValue">
      <span>
        <app-ng-dynamic-breadcrumb [bgColor]="'transparent'" [fontColor]="'white'"></app-ng-dynamic-breadcrumb>
      </span>
    </mat-toolbar>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
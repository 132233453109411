import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Category } from 'src/app/models/interfaces/category';
import { CategoryService } from 'src/app/services/category/category.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ConfirmationDialogComponent } from '../../alert/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  dataSource = new MatTableDataSource<Category>();
  displayedColumns: string[] = ["name", "rank", "delete"];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  //Filter
  categorySearchText?: string

  constructor(private router: Router,
    private categoryService: CategoryService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.getCategories()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }


  getCategories() {
    this.categoryService.getAllCategory(this.getQueryParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<Category>(data.data.results);
            if (data.data.results.length < 1 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  onAddClick() {
    this.router.navigate(['./categories/create'])
  }

  onFilterSubmit() {
    this.getCategories()
  }

  onFilterClear() {
    this.categorySearchText = undefined;
    this.getCategories()
  }

  deleteCategory(id: string) {
    this.categoryService.deleteCategory(id).
      pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data) {
            this.getCategories();
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  deleteAfterConfirmation(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Do you really want to delete this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteCategory(id);
      }
    });
  }

  navigateToDetails(id: string) {
    this.router.navigate(['./categories/' + id])
  }

  pageChanged(event: PageEvent) {
    this.getCategories()
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.getCategories()
    }
  }

  private getQueryParams(): any {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    if (this.categorySearchText) {
      params["search"] = this.categorySearchText;
    }
    return params;
  }

}

import { Component, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OrderItem } from 'src/app/models/interfaces/order-item';
import { OrderValueResponseData } from 'src/app/models/response/orders/order-value/order-value-response-data';
import { EventEmitter } from '@angular/core';
import { Utilities } from 'src/app/utilities/utilities';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { OrderProductItem } from 'src/app/models/interfaces/order-product-item';
import { OrderDetails } from 'src/app/models/interfaces/order-details';
import { OrderValueDetailsResponseData } from 'src/app/models/response/orders/order-value/order-value-details-response-data';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})
export class OrderItemsComponent implements OnInit {
  dataSource = new MatTableDataSource<OrderItem>();

  // @Input() orderValue?: OrderValueDetailsResponseData;
  @Input() userId?: number;
  @Input() clearCoupon = false


  @Input()
  get orderValue(): OrderValueDetailsResponseData | undefined { return this._orderValue; }
  set orderValue(details: OrderValueDetailsResponseData | undefined) {
    this._orderValue = details;
    this.updateDataSource(this._orderValue)
  }
  private _orderValue?: OrderValueDetailsResponseData

  @Output() cartUpdateEvent: EventEmitter<any> = new EventEmitter();


  displayedColumns: string[] = ["id", "product_name", "product_price", "total_price", "discounted_price", "quantity", "coupon"];

  constructor(private utilities: Utilities) {
  }

  ngOnInit(): void {

  }

  deleteAfterConfirmation(index: number) {
    var itemList = this.dataSource.data;
    itemList.splice(index, 1);
    this.refreshCart()
  }

  updateDataSource(orderValueDetails: OrderValueDetailsResponseData | undefined) {
    if (orderValueDetails != undefined) {
      this.dataSource.data = orderValueDetails.item_details
    }
  }

  refreshCart() {
    this.cartUpdateEvent.emit({ products: this.dataSource.data, clearCoupons: this.clearCoupon })
  }

  onClearCoupon(): void {
    this.clearCoupon = true
    this.refreshCart()
  }

}

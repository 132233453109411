import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { SellerService } from 'src/app/services/seller/seller.service';
import { Utilities } from 'src/app/utilities/utilities';
import { MultiSelectBrandComponent } from '../../multi-select/multi-select-brand/multi-select-brand.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-seller-create',
  templateUrl: './seller-create.component.html',
  styleUrls: ['./seller-create.component.scss']
})
export class SellerCreateComponent implements OnInit {

  @Input() sellerForm!: FormGroup;
  @ViewChild(MultiSelectBrandComponent) brandSelector!: MultiSelectBrandComponent;


  hide = true;

  constructor(private formBuilder: FormBuilder,
    private sellerService: SellerService,
    private location: Location,
    private utilities: Utilities) { }

  ngOnInit(): void {
    this.sellerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', Validators.required],
      shippingCharge: ['', [Validators.required, Validators.min(0), Validators.max(99)]],
    });
  }

  get f() { return this.sellerForm.controls; }

  reset() {
    this.sellerForm.reset()
    this.brandSelector.resetAll()
  }

  submit() {
    this.sellerForm.markAllAsTouched()
    if (this.sellerForm.invalid == true) {
      return;
    }
    if (this.brandSelector.brands.length == 0) {
      this.utilities.showSnackbar("Invalid brands list")
      return
    }
    this.sellerService.createSeller(this.getParams()).
      pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.status == 1) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  getParams() {
    const formData: any = new FormData();

    let brands = this.brandSelector.getSelectedBrands().map(function (object) {
      return object.id
    })
    formData.append('first_name', this.f.firstName.value);
    formData.append('last_name', this.f.lastName.value);
    formData.append('country_code', "+91");
    formData.append('email', this.f.email.value);
    formData.append('phone', this.f.phone.value);
    formData.append('password', this.f.password.value);
    formData.append('brand', brands);
    formData.append('shipping_charge', this.f.shippingCharge.value)
    return formData
  }

}

<div>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Added Items ({{dataSource.data.length}})
        </mat-panel-title>
        <mat-panel-description>
         Order Cost: RS {{orderValue?.discounted_cost ?? 0.0}}
        </mat-panel-description>
      </mat-expansion-panel-header>
        <mat-action-row>
            <button mat-raised-button (click)="onAddClick()" color="primary">Add Item</button>
        </mat-action-row>
      <table mat-table [dataSource]="dataSource" class="mat=elevation-z8 full-width-table">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">{{element.product.id}}</td>
        </ng-container>
        <ng-container matColumnDef="product_name">
            <th mat-header-cell *matHeaderCellDef>Product Name</th>
            <td mat-cell *matCellDef="let element">{{element.product.name}}</td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let element"><mat-form-field>
              <input matInput maxlength="3" [(ngModel)]=element.quantity (change)="refreshCart()">
            </mat-form-field></td>
        </ng-container>
        <ng-container matColumnDef="coupon">
            <th mat-header-cell *matHeaderCellDef>Coupon</th>
            <td mat-cell *matCellDef="let element, let i = index">
            <button mat-button *ngIf="element.coupon == null" (click)="showCoupon(element.product)">Apply Coupon</button>
                <mat-chip-list *ngIf="element.coupon">
                    <mat-chip>
                        {{element.coupon?.code}}
                        <button matChipRemove (click)="removeCoupon(i)">
                        <mat-icon>cancel</mat-icon>
                        </button>
                     </mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>
        <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td mat-cell *matCellDef="let i = index" (click)="$event.stopPropagation()">
            <button mat-icon-button (click)="deleteAfterConfirmation(i)">
             <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </mat-expansion-panel>
  </div>
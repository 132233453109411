import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { ItemType } from 'src/app/models/internal/item-type';
import { UsersService } from 'src/app/services/users/users.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-user-selector-dialog',
  templateUrl: './user-selector-dialog.component.html',
  styleUrls: ['./user-selector-dialog.component.scss']
})
export class UserSelectorDialogComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  selectedType?: ItemType
  dataSource = new MatTableDataSource<ListItem>();
  displayedColumns: string[] = ["id", "name"];
  searchText?: string

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(public dialogRef: MatDialogRef<UserSelectorDialogComponent>,
    private usersService: UsersService,
    private utilities: Utilities) {
    this.fetchUsersList()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  itemSelected(id: string) {

  }

  applyFilter() {
    this.fetchUsersList()
  }


  fetchUsersList() {
    this.usersService.getMinifiedUserList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<ListItem>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.fetchUsersList()
    }
  }

  pageChanged(event: PageEvent) {
    this.fetchUsersList()
  }

  getParams() {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    if (this.searchText) {
      params["search"] = this.searchText;
    }
    return params;
  }


}

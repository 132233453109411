import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { NewArrivalsCreateResponse } from 'src/app/models/response/new-arrivals/new-arrivals-create/new-arrivals-create-response';
import { NewArrivalsDetailsResponse } from 'src/app/models/response/new-arrivals/new-arrivals-details/new-arrivals-details-response';
import { NewArrivalsListResponse } from 'src/app/models/response/new-arrivals/new-arrivals-list/new-arrivals-list-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class NewArrivalsService {

  constructor(private baseAPIService:BaseApiService) { }

  createNewArrivals(data:any):Observable<NewArrivalsCreateResponse>{
    return this.baseAPIService.post("/new-arrival/", data).pipe(map((response:NewArrivalsCreateResponse)=> {
      return response;
    }))
  }

  getAllNewArrivals(params:any):Observable<NewArrivalsListResponse>{
    return this.baseAPIService.get("/new-arrival/", params).pipe(map((response:NewArrivalsListResponse) => {
      return response;
    }))
  }

  updateNewArrivals(id:string,data:any):Observable<NewArrivalsDetailsResponse>{
    return this.baseAPIService.patch("/new-arrival/" + id + "/", data).pipe(map((response:NewArrivalsDetailsResponse)=> {
      return response;
    }))
  }

  getNewArrivalsDetails(id:string): Observable<NewArrivalsDetailsResponse>{
    return this.baseAPIService.get("/new-arrival/" + id + "/", null).pipe(map((response:NewArrivalsDetailsResponse) => {
      return response;
    }))
  }

  deleteNewArrivals(id:string): Observable<BaseResponse>{
    return this.baseAPIService.delete("/new-arrival/" + id + "/").pipe(map((response:BaseResponse) => {
      return response;
    }))
  }
}

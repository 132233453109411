import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { SubscriptionPlan } from 'src/app/models/interfaces/subscription-plan';
import { User } from 'src/app/models/interfaces/user';
import { Subscription } from 'src/app/models/internal/subscription';
import { ActiveInactiveStatus } from 'src/app/models/internal/active-inactive-status';
import { SubscriptionTypes } from 'src/app/models/objects/subscription-types';
import { UsersService } from 'src/app/services/users/users.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ActiveInactiveStatusTypes } from 'src/app/models/objects/active-inactive-status-types';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  userId!: string

  userDetails?: User

  availableUserSubscriptionTypes!: Subscription[]
  availabeUserStatusTypes!: ActiveInactiveStatus[]

  userUpdatedStatus?: number
  userOrderingEligibility?: boolean
  userUpdatedSubscription: SubscriptionPlan = { type: "Free", start_date: undefined, end_date: undefined }
  userCreditLimit?: number
  userCreditDays?: number

  constructor(private route: ActivatedRoute,
    private usersService: UsersService,
    private utilities: Utilities) {
    this.availableUserSubscriptionTypes = SubscriptionTypes;
    this.availabeUserStatusTypes = ActiveInactiveStatusTypes;
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.userId = inputId
    }
    this.callUserDetails()
  }

  ngOnInit(): void {
  }


  update(): void {
    this.usersService.updateUserSubscription(this.userId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )

  }

  reset(): void {
    this.resetUpdatedValues();
  }

  callUserDetails(): void {
    this.usersService.getUserDetails(this.userId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.userDetails = data.data;
            this.resetUpdatedValues();
            console.log(this.userDetails.address);
          }
          else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  private resetUpdatedValues() {
    if (this.userDetails) {
      this.userUpdatedStatus = this.getUserStatusIdFromStatus(this.userDetails.status)
      this.userUpdatedSubscription = Object.assign({}, this.userDetails.subscription_plan);
      this.userCreditDays = this.userDetails.credit_days
      this.userCreditLimit = this.userDetails.credit_limit
    }
  }

  private getUserStatusIdFromStatus(status: string): number | undefined {
    return this.availabeUserStatusTypes.find(statusType => statusType.viewValue === status)?.value;
  }

  private getUserUpdateParams(): any {
    let startDate = ""
    let endDate = ""
    let subscription_type = this.availableUserSubscriptionTypes.find(type => type.viewValue === this.userUpdatedSubscription.type)?.value
    if (this.userUpdatedSubscription.start_date) {
      startDate = this.utilities.getFormattedDateDDMMYYYY(this.userUpdatedSubscription.start_date) ?? ""
    }
    if (this.userUpdatedSubscription.end_date) {
      endDate = this.utilities.getFormattedDateDDMMYYYY(this.userUpdatedSubscription.end_date) ?? ""
    }
    return {
      "status": this.userUpdatedStatus,
      "is_eligible_to_order": this.userDetails?.is_eligible_to_order,
      "subscription_plan": {
        "type": subscription_type,
        "start_date": startDate,
        "end_date": endDate
      },
      "credit_limit": this.userCreditLimit,
      "credit_days": this.userCreditDays
    }
  }

}

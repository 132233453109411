<mat-card>
    <mat-card-content>
        <form [formGroup]="brandForm">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Brand Name</mat-label>
            <input matInput placeholder="Brand Name" formControlName="brandName" >
            <mat-error *ngIf="f.brandName.hasError('required')">
                Brand Name is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Brand Rank</mat-label>
            <input matInput type="number" placeholder="Brand Rank" formControlName="brandRank" >
            <mat-error *ngIf="f.brandRank.hasError('required')">
                Brand Rank is Required!
            </mat-error>
          </mat-form-field>
        </form>

        <button mat-raised-button (click)="fileInput.click()">
            <mat-icon>library_add</mat-icon>
            <span>{{fileToUpload?.name || " Select"}}</span>
            <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
          </button>
          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="submit()" color="warn">Submit</button>
        </mat-card-actions>
</mat-card>

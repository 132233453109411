import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route } from '@angular/router';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { StoreDetails } from 'src/app/models/interfaces/store-details';
import { ActiveInactiveStatus } from 'src/app/models/internal/active-inactive-status';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { ActiveInactiveStatusTypes } from 'src/app/models/objects/active-inactive-status-types';
import { StoreService } from 'src/app/services/store/store.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-store-update-create',
  templateUrl: './store-update-create.component.html',
  styleUrls: ['./store-update-create.component.scss']
})
export class StoreUpdateCreateComponent implements OnInit {

  @Input() storesForm!: FormGroup;

  storesId!: string
  storesDetails?: StoreDetails

  selectedState?: ListItem
  selectedDistrict?: ListItem
  availabeStatusTypes!: ActiveInactiveStatus[]

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private storesService: StoreService,
    private dialog: MatDialog,
    private utilities: Utilities,
    private location: Location,) {
    let inputId = route.snapshot.paramMap.get("id")
    this.availabeStatusTypes = ActiveInactiveStatusTypes
    if (inputId) {
      this.storesId = inputId
      this.callStoreDetails()
    }
  }

  ngOnInit(): void {
    this.storesForm = this.formBuilder.group({
      storeAddress: ['', Validators.required],
      state: ['', Validators.required],
      district: ['', Validators.required],
      pincode: ['', Validators.required],
      storeStatus: ['', Validators.required],
    });
  }

  get f() { return this.storesForm.controls; }

  openDialog(type: string) {

    let itemSelectorDialogModel = { value: type } as ItemSelectorDialogModel

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: itemSelectorDialogModel

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        if (type == "state") {
          this.f.state.setValue(result.name)
          this.selectedState = result
          this.f.district.setValue(undefined)
          this.selectedDistrict = undefined
        } else if (type == "district") {
          this.f.district.setValue(result.name)
          this.selectedDistrict = result
        }
      }
    })
  }


  callStoreDetails() {
    this.storesService.getStoreDetails(this.storesId)
      .pipe(first())
      .subscribe(
        data => {
          this.storesDetails = data.data
          this.reset()
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  create(): void {
    this.storesForm.markAllAsTouched()
    if (this.storesForm.invalid == true) {
      return;
    }
    this.storesService.createProduct(this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.status == 1) {
            this.location.back()
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  update(): void {
    this.storesForm.markAllAsTouched()
    if (this.storesForm.invalid == true) {
      return;
    }
    this.storesService.updateProduct(this.storesId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          this.callStoreDetails()
          this.utilities.showSnackbar(data.message)
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset(): void {
    if (this.storesDetails) {
      this.f.storeAddress.setValue(this.storesDetails.address)
      this.f.state.setValue(this.storesDetails.state_details.name)
      this.f.district.setValue(this.storesDetails.district_details.name)
      this.f.pincode.setValue(this.storesDetails.pincode)
      this.f.storeStatus.setValue(this.getStatusIdFromStatus(this.storesDetails.status))
    }
  }

  private getStatusIdFromStatus(status: string): number | undefined {
    return this.availabeStatusTypes.find(statusType => statusType.viewValue === status)?.value;
  }


  getUserUpdateParams(): any {
    let formData: FormData = new FormData();
    formData.append('address', this.f.storeAddress.value);
    formData.append('pincode', this.f.pincode.value);
    if (this.selectedState) {
      formData.append("state", this.selectedState!.id.toString());
    }
    if (this.selectedDistrict) {
      formData.append("district", this.selectedDistrict!.id.toString());
    }
    formData.append("status", this.f.storeStatus.value);
    return formData;
  }

}

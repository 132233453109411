import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ImageModule } from 'src/app/models/enums/image-module.enum';
import { Category } from 'src/app/models/interfaces/category';
import { CategoryService } from 'src/app/services/category/category.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class CategoryDetailsComponent implements OnInit {

  @Input() categoryForm!: FormGroup;

  categoryId!: string
  categoryDetails?: Category

  fileToUpload: File | null = null;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private utilities: Utilities) {
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.categoryId = inputId
    }
    this.callCategoryDetails()
  }

  ngOnInit(): void {
    this.categoryForm = this.formBuilder.group({
      categoryName: ['', Validators.required],
      categoryRank: ['', Validators.required]
    });
  }

  get f() { return this.categoryForm.controls; }

  update(): void {
    this.categoryForm.markAllAsTouched()
    if (this.categoryForm.invalid == true) {
      return;
    }
    this.categoryService.updateCategory(this.categoryId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.categoryDetails = data.data
            this.utilities.showSnackbar(data.message)
            this.resetUpdatedValues()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset(): void {
    this.resetUpdatedValues();
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  private callCategoryDetails() {
    this.categoryService.getCategoryDetails(this.categoryId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.categoryDetails = data.data;
            this.resetUpdatedValues();
          }
        }
      )
  }

  getImageUrls(): [string] {
    return [this.utilities.getImageUrl(ImageModule.Category, this.categoryDetails!.image)]
  }

  private getUserUpdateParams(): any {

    let formData: FormData = new FormData();
    if (this.fileToUpload) {
      formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    }
    formData.append('name', this.f.categoryName.value);
    formData.append('rank', this.f.categoryRank.value);
    return formData;
  }

  private resetUpdatedValues() {
    if (this.categoryDetails) {
      this.f.categoryName.setValue(this.categoryDetails.name)
      this.f.categoryRank.setValue(this.categoryDetails.rank)
    }
    this.fileToUpload = null
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(private baseAPIService:BaseApiService) { }

  sentPushNotification(data:any):Observable<BaseResponse>{
    return this.baseAPIService.post("/push-notification/", data).pipe(map((response:BaseResponse)=> {
      return response;
    }))
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CouponType } from 'src/app/models/internal/coupon-type';
import { CouponTypes } from 'src/app/models/objects/coupon-types';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { Location } from '@angular/common';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { UserSelectorDialogComponent } from '../../dialogs/user-selector-dialog/user-selector-dialog.component';
import { Subscription } from 'src/app/models/internal/subscription';
import { SubscriptionTypes } from 'src/app/models/objects/subscription-types';

@Component({
  selector: 'app-coupon-create',
  templateUrl: './coupon-create.component.html',
  styleUrls: ['./coupon-create.component.scss']
})
export class CouponCreateComponent implements OnInit {

  availableCouponTypes!: CouponType[]
  availableUserSubscriptionTypes!: Subscription[]

  selectedBrand?: ListItem
  selectedProduct?: ListItem
  selectedCategory?: ListItem
  selectedSubcategory?: ListItem
  selectedUser?: ListItem

  @Input() couponForm!: FormGroup;

  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private couponService: CouponService,
    private router: Router,
    private location: Location,
    private utilities: Utilities) {
    this.availableCouponTypes = CouponTypes
    this.availableUserSubscriptionTypes = SubscriptionTypes
  }

  ngOnInit(): void {
    this.couponForm = this.formBuilder.group({
      couponName: ['', Validators.required],
      couponCode: ['', Validators.required],
      couponEndDate: ['', Validators.required],
      couponDiscountPercentage: ['', Validators.required],
      couponMinCartValue: ['',],
      couponMaxLimit: ['',],
      couponMaxUsageLimit: ['',],
      couponType: ['', Validators.required],
      couponUser: [''],
      couponBrand: [''],
      couponProduct: [''],
      couponCategory: [''],
      couponSubcategory: [''],
      selectedUserSubscripion: [''],
    });
    this.subscribeValidatorForCoupenType()
  }

  openDialog(type: string) {

    if (type == "user") {
      const dialogRef = this.dialog.open(UserSelectorDialogComponent, {
        width: '40%',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result != undefined && result != "") {
          this.updateSelectedItem(type, result)
        }
      })
    } else {
      const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
        width: '40%',
        data: { value: type } as ItemSelectorDialogModel
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result != undefined && result != "") {
          this.updateSelectedItem(type, result)
        }
      })
    }
    this.f.couponUser.markAsUntouched()
  }

  get f() { return this.couponForm.controls; }

  get showUserSpecific() { return this.f.couponType.value && this.f.couponType.value == "user_specific" }

  get showUserPlan() { return this.f.couponType.value && this.f.couponType.value == "plan_based" }

  get showBrand() { return this.f.couponType.value && this.f.couponType.value == "combined" }

  get showProduct() { return this.f.couponType.value && (this.f.couponType.value == "product_specific") }

  get showCategory() { return this.f.couponType.value && this.f.couponType.value == "combined" }

  get showSubcategory() { return this.f.couponType.value && this.f.couponType.value == "combined" }


  subscribeValidatorForCoupenType() {
    this.couponForm?.get('couponType')?.valueChanges
      .subscribe(value => {
        this.resetSelectedItem()
      });
  }

  updateSelectedItem(type: string, value: ListItem) {

    switch (type) {
      case "brand": {
        this.f.couponBrand.setValue(value.name)
        this.selectedBrand = value
        break;
      }
      case "category": {
        this.f.couponCategory.setValue(value.name)
        this.selectedCategory = value
        break;
      }
      case "sub-category": {
        this.f.couponSubcategory.setValue(value.name)
        this.selectedSubcategory = value
        break;
      }
      case "product": {
        this.f.couponProduct.setValue(value.name)
        this.selectedProduct = value
        break;
      }
      case "user": {
        this.f.couponUser.setValue(value.name)
        this.selectedUser = value
        break;
      }
    }
  }

  reset() {
    this.couponForm.reset()
    this.resetSelectedItem()
  }

  submit() {
    this.couponForm.markAllAsTouched()
    if (this.couponForm.invalid == true) {
      return;
    }
    if (this.f.couponType.value == "combined" && this.selectedBrand == null && this.selectedCategory == null && this.selectedSubcategory == null) {
      this.utilities.showSnackbar("Select atleast one item (Brand/Category/Subcategory/Product)")
      return;
    }
    this.couponService.createCoupon(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.data) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  resetSelectedItem() {
    this.selectedBrand = undefined;
    this.selectedCategory = undefined;
    this.selectedSubcategory = undefined;
    this.selectedProduct = undefined;

    this.f.couponBrand.setValue(undefined)
    this.f.couponCategory.setValue(undefined)
    this.f.couponSubcategory.setValue(undefined)
    this.f.couponProduct.setValue(undefined)
    this.f.couponUser.setValue(undefined)
    this.f.selectedUserSubscripion.setValue(undefined)
    this.updateValidators()
  }

  getParams(): any {
    let formData: FormData = new FormData();
    formData.append('name', this.f.couponName.value);
    formData.append('code', this.f.couponCode.value);
    let endDate = this.utilities.getFormattedDateDDMMYYYY(this.f.couponEndDate.value)
    if (endDate) {
      formData.append('end_date', endDate);
    }
    formData.append('type', this.f.couponType.value);
    formData.append('discount_percentage', this.f.couponDiscountPercentage.value);
    formData.append('min_value', this.f.couponMinCartValue.value ?? 0);
    formData.append('max_value', this.f.couponMaxLimit.value ?? 0);
    formData.append('usage_limit', this.f.couponMaxUsageLimit.value ?? 0);

    if (this.f.couponUser.value) {
      formData.append('user', this.selectedUser!.id.toString());
    }
    if (this.selectedBrand) {
      formData.append("brand", this.selectedBrand!.id.toString());
    }
    if (this.selectedProduct) {
      formData.append("product", this.selectedProduct!.id.toString());
    }
    if (this.selectedCategory) {
      formData.append("category", this.selectedCategory!.id.toString());
    }
    if (this.selectedSubcategory) {
      formData.append("sub_category", this.selectedSubcategory!.id.toString());
    }
    if (this.f.selectedUserSubscripion) {
      formData.append("plan", this.f.selectedUserSubscripion.value)
    }
    return formData;
  }


  updateValidators() {

    this.f.couponUser.removeValidators(Validators.required)
    this.f.couponProduct.removeValidators(Validators.required)
    this.f.selectedUserSubscripion.removeValidators(Validators.required)
    switch (this.f.couponType.value) {
      case "user_specific": {
        this.f.couponUser.setValidators(Validators.required)
        break;
      }
      case "product_specific": {
        this.f.couponProduct.setValidators(Validators.required)
        break;
      }
      case "plan_based": {
        this.f.selectedUserSubscripion.setValidators(Validators.required)
        break;
      }
      default: {
        break;
      }
    }
    this.f.couponUser.updateValueAndValidity()
    this.f.couponProduct.updateValueAndValidity()
    this.f.selectedUserSubscripion.updateValueAndValidity()
    this.couponForm.markAsUntouched()
  }

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RouteConfigLoadEnd, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SubCategory } from 'src/app/models/interfaces/sub-category';
import { SubCategoryService } from 'src/app/services/sub-category/sub-category.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ConfirmationDialogComponent } from '../../alert/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-sub-category-list',
  templateUrl: './sub-category-list.component.html',
  styleUrls: ['./sub-category-list.component.scss']
})
export class SubCategoryListComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  dataSource = new MatTableDataSource<SubCategory>();
  displayedColumns: string[] = ["name", "rank", "delete"];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  //Filter
  subCategorySearchText?: string

  constructor(private router: Router,
    private subCategoryService: SubCategoryService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.getSubCategories()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }


  getSubCategories() {
    this.subCategoryService.getAllSubCategory(this.getQueryParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<SubCategory>(data.data.results);
            if (data.data.results.length < 1 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  onAddClick() {
    this.router.navigate(['./sub-categories/create'])
  }

  onFilterSubmit() {
    this.getSubCategories()
  }

  onFilterClear() {
    this.subCategorySearchText = undefined;
    this.getSubCategories()
  }

  deleteSubcategory(id: string) {
    this.subCategoryService.deleteSubCategory(id).
      pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data) {
            this.getSubCategories();
          }
        },
        error => {
          this.utilities.showSnackbar(error)
        }
      )
  }

  deleteAfterConfirmation(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Do you really want to delete this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteSubcategory(id);
      }
    });
  }

  navigateToDetails(id: string) {
    this.router.navigate(['./sub-categories/' + id])
  }

  pageChanged(event: PageEvent) {
    this.getSubCategories()
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.getSubCategories()
    }
  }

  private getQueryParams(): any {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    if (this.subCategorySearchText) {
      params["search"] = this.subCategorySearchText;
    }
    return params;
  }


}

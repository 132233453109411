import { DatePipe } from "@angular/common"
import { Injectable } from "@angular/core"
import { MatSnackBar } from "@angular/material/snack-bar"
import { environment } from "src/environments/environment"
import { ImageModule } from "../models/enums/image-module.enum"

@Injectable({
    providedIn: 'root'
  })
export class Utilities{

    constructor(private datePipe: DatePipe, private snackBar: MatSnackBar) {}

    public getDateFromStringDDMMYYYY(dateString:string): Date{
        return new  Date(dateString.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) 
    }

    public getFormattedDateDDMMYYYY(inputDate:Date): string | null{
        return this.datePipe.transform(inputDate,"dd-MM-yyyy")
    }

    public getImageUrl(module:ImageModule, name:string):string{
      let finalUrl = environment.imageUrl;
      finalUrl += module
      finalUrl += "/"
      finalUrl += name
      return finalUrl;
    }

    public showSnackbar(message:string){
      this.snackBar.open(message, 'Ok', {
        duration: 2000
      });
    }
}
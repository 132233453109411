import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ImageModule } from 'src/app/models/enums/image-module.enum';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { Product } from 'src/app/models/interfaces/product';
import { ActiveInactiveStatus } from 'src/app/models/internal/active-inactive-status';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { ActiveInactiveStatusTypes } from 'src/app/models/objects/active-inactive-status-types';
import { BrandsService } from 'src/app/services/brands/brands.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  @Input() productForm!: FormGroup;

  fileToUpload: File | null = null;
  availableCategories?: ListItem[];
  availableBrands?: ListItem[];

  productId!: string;
  productDetails?: Product;

  selectedBrand?: ListItem
  selectedCategory?: ListItem
  selectedSubCategory?: ListItem

  availabeProductStatusTypes!: ActiveInactiveStatus[]

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.productId = inputId
    }
    this.availabeProductStatusTypes = ActiveInactiveStatusTypes
    this.callProductDetails()
  }

  ngOnInit(): void {
    this.productForm = this.formBuilder.group({
      productName: ['', Validators.required],
      productCode: '',
      hsnCode: '',
      productBrand: ['', Validators.required],
      productCategory: ['', Validators.required],
      productSubCategory: '',
      maxRetailPrice: ['', Validators.required],
      purchaseRate: ['', Validators.required],
      freeUserPricePercentage: ['', Validators.required],
      freeUserPrice: '',
      premiumOneUserPricePercentage: ['', Validators.required],
      premiumOneUserPrice: '',
      premiumTwoUserPricePercentage: ['', Validators.required],
      premiumTwoUserPrice: '',
      gst: '',
      shippingCharge: '',
      productDetails: ['', Validators.required],
      productSpecification: ['', Validators.required],
      discountPercentage: '',
      productUpdatedStatus: ['', Validators.required],
      isOutOfStock: '',
      isFinishingSoon: ''
    });
  }

  get f() { return this.productForm.controls; }

  updatePrice() {
    this.f.freeUserPrice.setValue(Math.round(((this.f.freeUserPricePercentage.value + 100) / 100) * this.f.purchaseRate.value))
    this.f.premiumOneUserPrice.setValue(Math.round(((this.f.premiumOneUserPricePercentage.value + 100) / 100) * this.f.purchaseRate.value))
    this.f.premiumTwoUserPrice.setValue(Math.round(((this.f.premiumTwoUserPricePercentage.value + 100) / 100) * this.f.purchaseRate.value))
  }

  openDialog(type: string) {
    let itemSelectorDialogModel = { value: type } as ItemSelectorDialogModel

    if (type == "sub-category") {
      itemSelectorDialogModel.filterId = this.selectedCategory?.id
    }

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: itemSelectorDialogModel

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        if (type == "brand") {
          this.f.productBrand.setValue(result.name)
          this.selectedBrand = result
        } else if (type == "sub-category") {
          this.f.productSubCategory.setValue(result.name)
          this.selectedSubCategory = result
        } else {
          this.f.productCategory.setValue(result.name)
          this.selectedCategory = result
          this.resetSubCategory()
        }
      }
    })
  }

  callProductDetails() {
    this.productsService.getProductDetails(this.productId)
      .pipe(first())
      .subscribe(
        data => {
          this.productDetails = data.data
          this.reset()
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }


  update(): void {
    this.productForm.markAllAsTouched()
    if (this.productForm.invalid == true) {
      return;
    }
    this.productsService.updateProduct(this.productId, this.getProductUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            // this.productDetails = data.data;
            // this.resetUpdatedValues();
            this.utilities.showSnackbar(data.message)
            this.callProductDetails();
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset(): void {
    this.fileToUpload = null
    this.selectedBrand = this.productDetails?.brand
    this.selectedCategory = this.productDetails?.category
    this.selectedSubCategory = this.productDetails?.sub_category
    this.resetUpdatedValues();
  }

  resetSubCategory() {
    this.f.productSubCategory.setValue(undefined)
    this.selectedSubCategory = undefined
  }


  private getProductStatusIdFromStatus(status: string): number | undefined {
    return this.availabeProductStatusTypes.find(statusType => statusType.viewValue === status)?.value;
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  private resetUpdatedValues() {
    if (this.productDetails) {
      this.f.productName.setValue(this.productDetails?.name);
      this.f.productCode.setValue(this.productDetails?.code);
      this.f.hsnCode.setValue(this.productDetails.hsn_code)
      this.f.productBrand.setValue(this.productDetails?.brand.name);
      this.f.productCategory.setValue(this.productDetails?.category.name);
      this.f.productSubCategory.setValue(this.productDetails?.sub_category?.name);
      this.f.maxRetailPrice.setValue(this.productDetails?.max_retail_price);
      this.f.purchaseRate.setValue(this.productDetails.purchase_rate ?? 0);
      this.f.freeUserPricePercentage.setValue(parseFloat(this.productDetails.free_price_percentage) ?? 0);
      this.f.freeUserPrice.setValue(parseInt(this.productDetails?.free_user_price));
      this.f.premiumOneUserPricePercentage.setValue(parseFloat(this.productDetails.premium_one_price_percentage) ?? 0);
      this.f.premiumOneUserPrice.setValue(parseInt(this.productDetails?.premium_one_user_price));
      this.f.premiumTwoUserPricePercentage.setValue(parseFloat(this.productDetails.premium_two_price_percentage) ?? 0);
      this.f.premiumTwoUserPrice.setValue(parseInt(this.productDetails?.premium_two_user_price));
      this.f.gst.setValue(this.productDetails?.gst);
      this.f.shippingCharge.setValue(this.productDetails?.shipping_charge);
      this.f.productDetails.setValue(this.productDetails?.details);
      this.f.productSpecification.setValue(this.productDetails?.specifications);
      this.f.discountPercentage.setValue(this.productDetails?.discount_percentage);
      this.f.isOutOfStock.setValue(this.productDetails?.is_out_of_stock)
      this.f.isFinishingSoon.setValue(this.productDetails?.is_finishing_soon);
      this.f.productUpdatedStatus.setValue(this.getProductStatusIdFromStatus(this.productDetails?.status))
    }
  }

  getProductImageUrls(): [string] {
    //Should call only from html component with category details check
    return [this.utilities.getImageUrl(ImageModule.Product, this.productDetails!.image)]
  }


  private getProductUpdateParams(): any {
    let formData: FormData = new FormData();

    if (this.fileToUpload) {
      formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    }
    formData.append('name', this.f.productName.value);
    formData.append('code', this.f.productCode.value);
    if (this.selectedBrand) {
      formData.append('brand', this.selectedBrand.id.toString());
    }
    if (this.selectedCategory) {
      formData.append('category', this.selectedCategory.id.toString());
    }
    if (this.selectedSubCategory) {
      formData.append('sub_category', this.selectedSubCategory.id.toString());
    }
    formData.append('details', this.f.productDetails.value);
    formData.append('purchase_rate', this.f.purchaseRate.value);
    formData.append('free_price_percentage', this.f.freeUserPricePercentage.value);
    formData.append('free_user_price', this.f.freeUserPrice.value);
    formData.append('max_retail_price', this.f.maxRetailPrice.value);
    formData.append('premium_one_price_percentage', this.f.premiumOneUserPricePercentage.value);
    formData.append('premium_one_user_price', this.f.premiumOneUserPrice.value);
    formData.append('premium_two_price_percentage', this.f.premiumTwoUserPricePercentage.value);
    formData.append('premium_two_user_price', this.f.premiumTwoUserPrice.value);
    formData.append('specifications', this.f.productSpecification.value);
    //Temp fix for integer data need to do a proper fix
    formData.append('gst', this.f.gst.value ?? "")
    formData.append('discount_percentage', this.f.discountPercentage.value ?? "");
    formData.append('shipping_charge', this.f.shippingCharge.value ?? "");
    formData.append('status', this.f.productUpdatedStatus.value);
    formData.append('is_out_of_stock', this.f.isOutOfStock.value)
    formData.append('is_finishing_soon', this.f.isFinishingSoon.value)
    return formData;
  }

  getCategoryListParam(): any {
    return {}
  }

  getBrandListParam(): any {
    return {}
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { CategoryCreateResponse } from 'src/app/models/response/category/category-create/category-create-response';
import { CategoryDetailsResponse } from 'src/app/models/response/category/category-details/category-details-response';
import { CategoryListResponse } from 'src/app/models/response/category/category-list/category-list-response';
import { ListItemResponse } from 'src/app/models/response/list-item/list-item-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private baseAPIService:BaseApiService) { }

  createCategory(data:any):Observable<CategoryCreateResponse>{
    return this.baseAPIService.post("/category/", data).pipe(map((response:CategoryCreateResponse)=> {
      return response;
    }))
  }

  getAllCategory(params:any):Observable<CategoryListResponse>{
    return this.baseAPIService.get("/category/", params).pipe(map((response:CategoryListResponse) => {
      return response;
    }))
  }

  updateCategory(id:string,data:any):Observable<CategoryDetailsResponse>{
    return this.baseAPIService.patch("/category/" + id + "/", data).pipe(map((response:CategoryDetailsResponse)=> {
      return response;
    }))
  }

  getCategoryDetails(id:string): Observable<CategoryDetailsResponse>{
    return this.baseAPIService.get("/category/" + id + "/", null).pipe(map((response:CategoryDetailsResponse) => {
      return response;
    }))
  }

  deleteCategory(id:string): Observable<BaseResponse>{
    return this.baseAPIService.delete("/category/" + id + "/").pipe(map((response:BaseResponse) => {
      return response;
    }))
  }

  getMinifiedCategoryList(params:any):Observable<ListItemResponse>{
    return this.baseAPIService.get("/list-category/", params).pipe(map((response:ListItemResponse) => {
      return response;
    }))
  }
}

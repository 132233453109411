<mat-toolbar>
  <span>Select Address</span>
</mat-toolbar>
<mat-dialog-content>
    <table mat-table [dataSource]="dataSource" class="mat=elevation-z8 full-width-table">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Phone</th>
            <td mat-cell *matCellDef="let element">{{element.phone}}</td>
        </ng-container>
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let element">
                <p matLine>
                    <span> {{element.address_line_1}}</span>
                    <span>, {{element.address_line_2}}</span>
                </p>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [mat-dialog-close]="row"></tr>
        <tr class = "mat-row mat-no-data" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                No result found
            </td>
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions>
      <button mat-button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { BrandCreateResponse } from 'src/app/models/response/brand/brand-create/brand-create-response';
import { BrandDetailsResponse } from 'src/app/models/response/brand/brand-details/brand-details-response';
import { BrandListResponse } from 'src/app/models/response/brand/brand-list/brand-list-response';
import { ListItemResponse } from 'src/app/models/response/list-item/list-item-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  constructor(private baseAPIService:BaseApiService) { }

  createBrand(data:any):Observable<BrandCreateResponse>{
    return this.baseAPIService.post("/brand/", data).pipe(map((response:BrandCreateResponse)=> {
      return response;
    }))
  }

  updateBrand(id:string,data:any):Observable<BrandDetailsResponse>{
    return this.baseAPIService.patch("/brand/" + id + "/", data).pipe(map((response:BrandDetailsResponse)=> {
      return response;
    }))
  }

  getAllBrands(params:any):Observable<BrandListResponse>{
    return this.baseAPIService.get("/brand/", params).pipe(map((response:BrandListResponse) => {
      return response;
    }))
  }

  getBrandDetails(id:string): Observable<BrandDetailsResponse>{
    return this.baseAPIService.get("/brand/" + id + "/", null).pipe(map((response:BrandDetailsResponse) => {
      return response;
    }))
  }

  deleteBrand(id:string): Observable<BaseResponse>{
    return this.baseAPIService.delete("/brand/" + id + "/").pipe(map((response:BaseResponse) => {
      return response;
    }))
  }

  
  getMinifiedBrandList(params:any):Observable<ListItemResponse>{
    return this.baseAPIService.get("/list-brand/", params).pipe(map((response:ListItemResponse) => {
      return response;
    }))
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { FeaturedProductsCreateResponse } from 'src/app/models/response/featured-products/featured-products-create/featured-products-create-response';
import { FeaturedProductsDetailsResponse } from 'src/app/models/response/featured-products/featured-products-details/featured-products-details-response';
import { FeaturedProductsListResponse } from 'src/app/models/response/featured-products/featured-products-list/featured-products-list-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class FeaturedProductsService {

  constructor(private baseAPIService:BaseApiService) { }

  createFeaturedProduct(data:any):Observable<FeaturedProductsCreateResponse>{
    return this.baseAPIService.post("/featured-product/", data).pipe(map((response:FeaturedProductsCreateResponse)=> {
      return response;
    }))
  }

  getAllFeaturedProducts(params:any):Observable<FeaturedProductsListResponse>{
    return this.baseAPIService.get("/featured-product/", params).pipe(map((response:FeaturedProductsListResponse) => {
      return response;
    }))
  }

  updateFeaturedProduct(id:string,data:any):Observable<FeaturedProductsDetailsResponse>{
    return this.baseAPIService.patch("/featured-product/" + id + "/", data).pipe(map((response:FeaturedProductsDetailsResponse)=> {
      return response;
    }))
  }

  getFeaturedProductDetails(id:string): Observable<FeaturedProductsDetailsResponse>{
    return this.baseAPIService.get("/featured-product/" + id + "/", null).pipe(map((response:FeaturedProductsDetailsResponse) => {
      return response;
    }))
  }

  deleteFeaturedProduct(id:string): Observable<BaseResponse>{
    return this.baseAPIService.delete("/featured-product/" + id + "/").pipe(map((response:BaseResponse) => {
      return response;
    }))
  }

}

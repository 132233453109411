import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { BrandsService } from 'src/app/services/brands/brands.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-multi-select-brand',
  templateUrl: './multi-select-brand.component.html',
  styleUrls: ['./multi-select-brand.component.scss']
})
export class MultiSelectBrandComponent implements OnInit {

  brandCtrl = new FormControl();

  brands: ListItem[] = []
  @Input() selectedBrands: ListItem[] = []

  @ViewChild('brandInput') brandInput?: ElementRef<HTMLInputElement>;

  constructor(
    private brandsService: BrandsService,
    private utilities: Utilities,
  ) { }

  ngOnInit(): void {
    this.fetchBrandList()
    this.brandCtrl.valueChanges.subscribe(
      (_) => {
        this.fetchBrandList()
      }
    )
  }

  public getSelectedBrands(): ListItem[] {
    return this.selectedBrands;
  }

  private fetchBrandList() {
    this.brandsService.getMinifiedBrandList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            const response = data.data.results;
            const filteredResponse = response.filter((item) => this.selectedBrands.map((selectedItem) => selectedItem.id).indexOf(item.id) < 0)
            this.brands = filteredResponse
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  remove(brand: ListItem): void {
    const index = this.selectedBrands.indexOf(brand);
    if (index >= 0) {
      this.selectedBrands.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedBrands?.push(event.option.value);
    this.resetBrandInput()
  }

  resetAll() {
    this.resetBrandInput()
    this.selectedBrands = []
  }

  private resetBrandInput() {
    if (this.brandInput) {
      this.brandInput.nativeElement.value = '';
      this.brandCtrl.setValue(null);
    }
    this.fetchBrandList()
  }


  private getParams() {
    let params: any = {}
    if (this.brandInput && this.brandInput.nativeElement.value) {
      params["search"] = this.brandInput.nativeElement.value;
    }
    return params;
  }
}

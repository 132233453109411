import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../base-api/base-api.service';
import { LoginResponse } from 'src/app/models/response/login/login-response';
import { Token } from 'src/app/models/interfaces/token';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private currentUserSubject?: BehaviorSubject<Token>;
  public currentUser?: Observable<Token>;

  constructor(private baseAPIService: BaseApiService) {
    if (localStorage.getItem != null) {
      this.updateUserSubject();
    }
  }

  private updateUserSubject() {
    this.currentUserSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Token | undefined {
    return this.currentUserSubject?.value;
  }

  public get isAdminUser(): Boolean {
    const currentUser = this.currentUserValue;
    return currentUser?.role.toLowerCase() == "admin"
  }

  public get isSellerUser(): Boolean {
    const currentUser = this.currentUserValue;
    return currentUser?.role.toLowerCase() == "seller"
  }


  loginUser(username: string, password: string): Observable<LoginResponse> {

    return this.baseAPIService.post("/login/", {
      "username": username,
      "password": password
    }).pipe(map((response: LoginResponse) => {
      if (response.data && response.data.token) {
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        this.updateUserSubject();
      }
      return response;
    }))
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject!.next(null!)
    location.reload()
  }
}

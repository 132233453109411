import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrandCreateComponent } from './components/brand/brand-create/brand-create.component';
import { BrandDetailsComponent } from './components/brand/brand-details/brand-details.component';
import { BrandListComponent } from './components/brand/brand-list/brand-list.component';
import { BulkUpdateComponent } from './components/bulk-update/bulk-update.component';
import { CategoryCreateComponent } from './components/category/category-create/category-create.component';
import { CategoryDetailsComponent } from './components/category/category-details/category-details.component';
import { CategoryListComponent } from './components/category/category-list/category-list.component';
import { CouponCreateComponent } from './components/coupon/coupon-create/coupon-create.component';
import { CouponDetailsComponent } from './components/coupon/coupon-details/coupon-details.component';
import { CouponListComponent } from './components/coupon/coupon-list/coupon-list.component';
import { FeaturedProductsCreateComponent } from './components/featured-products/featured-products-create/featured-products-create.component';
import { FeaturedProductsDetailsComponent } from './components/featured-products/featured-products-details/featured-products-details.component';
import { FeaturedProductsListComponent } from './components/featured-products/featured-products-list/featured-products-list.component';
import { LoginComponent } from './components/login/login.component';
import { NewArrivalsCreateComponent } from './components/new-arrivals/new-arrivals-create/new-arrivals-create.component';
import { NewArrivalsDetailsComponent } from './components/new-arrivals/new-arrivals-details/new-arrivals-details.component';
import { NewArrivalsListComponent } from './components/new-arrivals/new-arrivals-list/new-arrivals-list.component';
import { OrderCreateComponent } from './components/orders/order-create/order-create.component';
import { OrderDetailsComponent } from './components/orders/order-details/order-details.component';
import { OrderListComponent } from './components/orders/order-list/order-list.component';
import { ProductCreateComponent } from './components/products/product-create/product-create.component';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { ProductListComponent } from './components/products/product-list/product-list.component';
import { PushNotificationComponent } from './components/push-notification/push-notification.component';
import { StoreUpdateCreateComponent } from './components/store/store-update-create/store-update-create.component';
import { StoreListComponent } from './components/store/store-list/store-list.component';
import { SubCategoryCreateComponent } from './components/sub-category/sub-category-create/sub-category-create.component';
import { SubCategoryDetailsComponent } from './components/sub-category/sub-category-details/sub-category-details.component';
import { SubCategoryListComponent } from './components/sub-category/sub-category-list/sub-category-list.component';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { AuthGuard } from './guard/auth.guard';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { SellerProductListComponent } from './components/seller/seller-product-list/seller-product-list.component';
import { SellerProductDetailsComponent } from './components/seller/seller-product-details/seller-product-details.component';
import { SellerListComponent } from './components/seller/seller-list/seller-list.component';
import { SellerCreateComponent } from './components/seller/seller-create/seller-create.component';
import { SellerDetailsComponent } from './components/seller/seller-details/seller-details.component';
import { AuthService } from './services/auth/auth.service';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Analytics',
      breadcrumb: [
        {
          label: 'Analytics',
          url: 'analytics'
        }
      ]
    },
  },
  {
    path: 'seller-products',
    component: SellerProductListComponent,
    canActivate: [AuthGuard],
    data: {
      type: "seller",
      title: 'Products',
      breadcrumb: [
        {
          label: 'Products',
          url: 'seller-products'
        }
      ]
    },
  },
  {
    path: 'seller-products/:id',
    component: SellerProductDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      type: "seller",
      title: 'Products/:id',
      breadcrumb: [
        {
          label: 'Products',
          url: 'seller-products'
        },
        {
          label: 'Details: {{id}}',
          url: 'seller-products/:id'
        }
      ]
    }
  },
  {
    path: 'orders',
    component: OrderListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Orders',
      breadcrumb: [
        {
          label: 'Orders',
          url: 'orders'
        }
      ]
    },
  },
  {
    path: 'orders/create',
    component: OrderCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Order',
      breadcrumb: [
        {
          label: 'Orders',
          url: 'orders'
        },
        {
          label: 'Create',
          url: 'orders/create'
        }
      ]
    }
  },
  {
    path: 'orders/:id',
    component: OrderDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Orders/:id',
      breadcrumb: [
        {
          label: 'Orders',
          url: 'orders'
        },
        {
          label: 'Details: {{id}}',
          url: 'Orders/:id'
        }
      ]
    },
  },
  {
    path: 'users',
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Users',
      breadcrumb: [
        {
          label: 'Users',
          url: 'users'
        }
      ]
    }
  },
  {
    path: 'users/:id',
    component: UserDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Users Details',
      breadcrumb: [
        {
          label: 'Users',
          url: 'users'
        },
        {
          label: 'Details: {{id}}',
          url: 'users/:id'
        }
      ]
    }
  },
  {
    path: 'users/:userId/orders',
    component: OrderListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'User Orders',
      breadcrumb: [
        {
          label: 'Users',
          url: 'users'
        },
        {
          label: '{{user}}',
          url: 'users/:userId'
        },
        {
          label: 'Orders',
          url: 'users/:userId/orders'
        }
      ]
    },
  },
  {
    path: 'users/:userId/orders/:id',
    component: OrderDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Orders/:id',
      breadcrumb: [
        {
          label: 'Users',
          url: 'users'
        },
        {
          label: '{{user}}',
          url: 'users/:userId'
        },
        {
          label: 'Orders',
          url: 'orders'
        },
        {
          label: 'Details: {{id}}',
          url: 'Orders/:id'
        }
      ]
    },
  },
  {
    path: 'seller',
    component: SellerListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Seller',
      breadcrumb: [
        {
          label: 'Seller',
          url: 'seller'
        }
      ]
    }
  },
  {
    path: 'seller/create',
    component: SellerCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Seller',
      breadcrumb: [
        {
          label: 'Seller',
          url: 'seller'
        },
        {
          label: 'Create',
          url: 'seller/create'
        }
      ]
    }
  },
  {
    path: 'seller/:id',
    component: SellerDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Seller Details',
      breadcrumb: [
        {
          label: 'Seller',
          url: 'seller'
        },
        {
          label: 'Details: {{id}}',
          url: 'seller/:id'
        }
      ]
    }
  },
  {
    path: 'brands',
    component: BrandListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Brands',
      breadcrumb: [
        {
          label: 'Brands',
          url: 'brands'
        }
      ]
    }
  },
  {
    path: 'brands/create',
    component: BrandCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Brand',
      breadcrumb: [
        {
          label: 'Brands',
          url: 'brands'
        },
        {
          label: 'Create',
          url: 'brands/create'
        }
      ]
    }
  },
  {
    path: 'brands/:id',
    component: BrandDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Brand Details',
      breadcrumb: [
        {
          label: 'Brands',
          url: 'brands'
        },
        {
          label: 'Details: {{id}}',
          url: 'brands/:id'
        }
      ]
    }
  },
  {
    path: 'categories',
    component: CategoryListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Categories',
      breadcrumb: [
        {
          label: 'Categories',
          url: 'categories'
        }
      ]
    }
  },
  {
    path: 'categories/create',
    component: CategoryCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Category',
      breadcrumb: [
        {
          label: 'Categories',
          url: 'categories'
        },
        {
          label: 'Create',
          url: 'categories/create'
        }
      ]
    }
  },
  {
    path: 'categories/:id',
    component: CategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Category Details',
      breadcrumb: [
        {
          label: 'Categories',
          url: 'categories'
        },
        {
          label: 'Details: {{id}}',
          url: 'categories/:id'
        }
      ]
    }
  },
  {
    path: 'sub-categories',
    component: SubCategoryListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Sub Categories',
      breadcrumb: [
        {
          label: 'Sub Categories',
          url: 'sub-categories'
        }
      ]
    }
  },
  {
    path: 'sub-categories/create',
    component: SubCategoryCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Sub Category',
      breadcrumb: [
        {
          label: 'Sub Categories',
          url: 'sub-categories'
        },
        {
          label: 'Create',
          url: 'sub-categories/create'
        }
      ]
    }
  },
  {
    path: 'sub-categories/:id',
    component: SubCategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Category Details',
      breadcrumb: [
        {
          label: 'Sub Categories',
          url: 'sub-categories'
        },
        {
          label: 'Details: {{id}}',
          url: 'sub-categories/:id'
        }
      ]
    }
  },
  {
    path: 'products',
    component: ProductListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Products',
      breadcrumb: [
        {
          label: 'Products',
          url: 'products'
        }
      ]
    }
  },
  {
    path: 'products/create',
    component: ProductCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Product',
      breadcrumb: [
        {
          label: 'Products',
          url: 'products'
        },
        {
          label: 'Create',
          url: 'products/create'
        }
      ]
    }
  },
  {
    path: 'products/:id',
    component: ProductDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Products Details',
      breadcrumb: [
        {
          label: 'Products',
          url: 'products'
        },
        {
          label: 'Details: {{id}}',
          url: 'products/:id'
        }
      ]
    }
  },
  {
    path: 'featured-products',
    component: FeaturedProductsListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Featured Products',
      breadcrumb: [
        {
          label: 'Featured Products',
          url: 'featured-products'
        }
      ]
    }
  },
  {
    path: 'featured-products/create',
    component: FeaturedProductsCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Featured Product',
      breadcrumb: [
        {
          label: 'Featured Products',
          url: 'featured-products'
        },
        {
          label: 'Create',
          url: 'featured-products/create'
        }
      ]
    }
  },
  {
    path: 'featured-products/:id',
    component: FeaturedProductsDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Featured Products Details',
      breadcrumb: [
        {
          label: 'Featured Products',
          url: 'featured-products'
        },
        {
          label: 'Details: {{id}}',
          url: 'featured-products/:id'
        }
      ]
    }
  },
  {
    path: 'new-arrivals',
    component: NewArrivalsListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'New Arrivals',
      breadcrumb: [
        {
          label: 'New Arrivals',
          url: 'new-arrivals'
        }
      ]
    }
  },
  {
    path: 'new-arrivals/create',
    component: NewArrivalsCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create New Arrivals',
      breadcrumb: [
        {
          label: 'New Arrivals',
          url: 'new-arrivals'
        },
        {
          label: 'Create',
          url: 'new-arrivals/create'
        }
      ]
    }
  },
  {
    path: 'new-arrivals/:id',
    component: NewArrivalsDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'New Arrivals Details',
      breadcrumb: [
        {
          label: 'New Arrivals',
          url: 'new-arrivals'
        },
        {
          label: 'Details: {{id}}',
          url: 'new-arrivals/:id'
        }
      ]
    }
  },
  {
    path: 'coupons',
    component: CouponListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Coupons',
      breadcrumb: [
        {
          label: 'Coupons',
          url: 'coupons'
        }
      ]
    }
  },
  {
    path: 'coupons/create',
    component: CouponCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Coupons',
      breadcrumb: [
        {
          label: 'Coupons',
          url: 'coupons'
        },
        {
          label: 'Create',
          url: 'coupons/create'
        }
      ]
    }
  },
  {
    path: 'coupons/:id',
    component: CouponDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Coupon Details',
      breadcrumb: [
        {
          label: 'Coupons',
          url: 'coupons'
        },
        {
          label: 'Details: {{id}}',
          url: 'coupons/:id'
        }
      ]
    }
  },
  {
    path: 'stores',
    component: StoreListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Stores',
      breadcrumb: [
        {
          label: 'Stores',
          url: 'stores'
        }
      ]
    }
  },
  {
    path: 'stores/create',
    component: StoreUpdateCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Create Stores',
      breadcrumb: [
        {
          label: 'Stores',
          url: 'stores'
        },
        {
          label: 'Create',
          url: 'stores/create'
        }
      ]
    }
  },
  {
    path: 'stores/:id',
    component: StoreUpdateCreateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Store Details',
      breadcrumb: [
        {
          label: 'Stores',
          url: 'stores'
        },
        {
          label: 'Details: {{id}}',
          url: 'stores/:id'
        }
      ]
    }
  },
  {
    path: 'bulk-update',
    component: BulkUpdateComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Bulk Update',
      breadcrumb: [
        {
          label: 'Bulk Update',
          url: 'bulk-update'
        }
      ]
    }
  },
  {
    path: 'push-notification',
    component: PushNotificationComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Push Notification',
      breadcrumb: [
        {
          label: 'Push Notification',
          url: 'push-notification'
        }
      ]
    }
  },
  {
    //temp need to design a not found screen and update the logic
    path: '',
    redirectTo: '/orders',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

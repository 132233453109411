import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Brand } from 'src/app/models/interfaces/brand';
import { Category } from 'src/app/models/interfaces/category';
import { SellerProduct } from 'src/app/models/interfaces/seller-product';
import { ActiveInactiveStatus } from 'src/app/models/internal/active-inactive-status';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { ActiveInactiveStatusTypes } from 'src/app/models/objects/active-inactive-status-types';
import { SellerService } from 'src/app/services/seller/seller.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { MultiSelectBrandComponent } from '../../multi-select/multi-select-brand/multi-select-brand.component';
import { MultiSelectCategoryComponent } from '../../multi-select/multi-select-category/multi-select-category.component';

@Component({
  selector: 'app-seller-product-list',
  templateUrl: './seller-product-list.component.html',
  styleUrls: ['./seller-product-list.component.scss']
})
export class SellerProductListComponent implements AfterViewInit {



  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  displayedColumns: string[] = ["name", "category", "brand", "status"];
  dataSource = new MatTableDataSource<SellerProduct>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MultiSelectCategoryComponent) categorySelector!: MultiSelectCategoryComponent;
  @ViewChild(MultiSelectBrandComponent) brandSelector!: MultiSelectBrandComponent;

  availableProductStatusTypes!: ActiveInactiveStatus[]
  availableCategories?: Category[]
  availableBrands?: Brand[]

  constructor(private router: Router,
    public sellerService: SellerService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.availableProductStatusTypes = ActiveInactiveStatusTypes
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
    this.setupPaginatorInitialvalues()
    this.getAllProducts()
  }

  setupPaginatorInitialvalues() {
    //TODO:Check for a proper implementation
    setTimeout(() => {
      this.paginator.pageIndex = this.sellerService.pageIndex;
      this.paginator.pageSize = this.sellerService.pageSize;
    }, 0.5);
  }


  getAllProducts() {
    this.sellerService.getSellerProductList(this.getQueryParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<SellerProduct>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }


  navigateToDetails(id: string) {
    this.router.navigate(['./seller-products/' + id])
  }


  onFilterSubmit() {
    this.sellerService.productFilterCategory = this.categorySelector.getSelectedCategories()
    this.sellerService.productFilterBrand = this.brandSelector.getSelectedBrands()
    this.getAllProducts()
  }

  onFilterClear() {
    this.sellerService.productFilterStatus = undefined;
    this.sellerService.productFilterBrand = undefined;
    this.sellerService.productFilterCategory = undefined;
    this.sellerService.productSearchText = undefined;
    this.getAllProducts()
    this.paginator.pageIndex = this.sellerService.pageIndex
  }

  updatePageIndex() {
    this.sellerService.pageIndex = this.paginator?.pageIndex
    this.sellerService.pageSize = this.paginator.pageSize
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.updatePageIndex()
      this.getAllProducts()
    }
  }

  pageChanged(event: PageEvent) {
    this.updatePageIndex()
    this.getAllProducts()
  }

  private getQueryParams(): any {
    let params: any = {}
    params["limit"] = this.sellerService.pageSize;
    params["offset"] = this.sellerService.pageIndex * params["limit"];
    if (this.sellerService.productSearchText) {
      params["search"] = this.sellerService.productSearchText;
    }
    if (this.sellerService.productFilterStatus != null && this.sellerService.productFilterStatus != undefined) {
      params["status"] = this.sellerService.productFilterStatus
    }
    if (this.sellerService.productFilterBrand) {
      params["brand"] = this.sellerService.productFilterBrand.map(item => item.id)
    }
    if (this.sellerService.productFilterCategory) {
      params["category"] = this.sellerService.productFilterCategory.map(item => item.id)
    }
    return params;
  }


}

<mat-card>
    <mat-card-content>
        <div *ngIf="userDetails">
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>User Id</mat-label>
                <input matInput [(ngModel)]=userDetails.id readonly />
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]=userDetails.name readonly />
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Phone number</mat-label>
                <input matInput [(ngModel)]=userDetails.phone readonly />
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Joined Date</mat-label>
                <input matInput [(ngModel)]=userDetails.joined_date readonly />
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>User Status</mat-label>
                <mat-select [(ngModel)]=userUpdatedStatus>
                    <mat-option *ngFor="let userStatus of availabeUserStatusTypes" [value]="userStatus.value">
                        {{userStatus.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Shipping Charge Percentage</mat-label>
                <input matInput [(ngModel)]="shippingCharge" type="number" placeholder="Shipping Charge Percentage"
                    [max]="99" [min]="0">
            </mat-form-field>
        </div>
        <app-multi-select-brand [selectedBrands]="[]"></app-multi-select-brand>
    </mat-card-content>
    <mat-card-actions class="update-mat-card-actions">
        <button mat-raised-button (click)="reset()" color="basic">Reset</button>
        <button mat-raised-button (click)="update()" color="warn">Update</button>
    </mat-card-actions>
</mat-card>
<mat-card class="example-card" *ngIf="productDetails">
    <mat-card-header>
        <mat-card-title>{{productDetails.name}}</mat-card-title>
    </mat-card-header>
    <mat-grid-list cols="2" rowHeight="1:1">
        <mat-grid-tile>
            <img class="header-image" [src]="getProductImageUrl()" />
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-list>
                <mat-form-field class="full-width-field">
                    <mat-label>Id</mat-label>
                    <input matInput [(ngModel)]='productDetails.id' readonly />
                </mat-form-field>
                <mat-form-field class="full-width-field">
                    <mat-label>Category</mat-label>
                    <input matInput [(ngModel)]='productDetails.category.name' readonly />
                </mat-form-field>
                <mat-form-field class="full-width-field">
                    <mat-label>Brand</mat-label>
                    <input matInput [(ngModel)]='productDetails.brand.name' readonly />
                </mat-form-field>
                <mat-form-field class="full-width-field">
                    <mat-label>MRP</mat-label>
                    <input matInput [(ngModel)]='productDetails.max_retail_price' readonly />
                </mat-form-field>
                <mat-form-field class="full-width-field">
                    <mat-label>Calculated Seller Price</mat-label>
                    <input matInput [(ngModel)]='productDetails.seller_calculated_purchase_rate' readonly />
                </mat-form-field>
                <mat-form-field class="full-width-field" appearance="outline">
                    <mat-label>Purchase Rate</mat-label>
                    <input matInput type="number" placeholder="Purchase Rate" [(ngModel)]='sellerPrice' />
                </mat-form-field>
                <mat-card-actions class="update-mat-card-actions">
                    <button mat-raised-button (click)="reset()" color="basic">Reset</button>
                    <button mat-raised-button (click)="update()" color="warn">Update</button>
                </mat-card-actions>
            </mat-list>

        </mat-grid-tile>
    </mat-grid-list>
</mat-card>
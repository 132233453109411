<mat-card>
    <mat-card-content>
        <form [formGroup]="productForm">
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Product Name</mat-label>
                <input matInput placeholder="Product Name" formControlName="productName"/>
                <mat-error *ngIf="f.productName.hasError('required')">
                    Product Name is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Product Code</mat-label>
                <input matInput placeholder="Product Code" formControlName="productCode"/>
                <mat-error *ngIf="f.productCode.hasError('required')">
                    Product Code is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline"  (click)="openDialog('brand')">
                <mat-label>Product Brand</mat-label>
                <input matInput placeholder="Product Brand" formControlName="productBrand" readonly>
                <mat-error *ngIf="f.productBrand.hasError('required')">
                    Product Brand is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline"  (click)="openDialog('category')">
                <mat-label>Product Category</mat-label>
                <input matInput placeholder="Product Category" formControlName="productCategory" readonly>
                <mat-error *ngIf="f.productCategory.hasError('required')">
                    Product Category is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline"  (click)="openDialog('sub-category')">
                <mat-label>Product Sub Category</mat-label>
                <input matInput placeholder="Product Sub Category" formControlName="productSubCategory" readonly>
                <mat-error *ngIf="f.productSubCategory.hasError('required')">
                    Product Sub Category is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Maximum Retail Price</mat-label>
                <input matInput type="number" placeholder="Maximum Retail Price" formControlName="maxRetailPrice"/>
                <mat-error *ngIf="f.maxRetailPrice.hasError('required')">
                    Maximum Retail Price is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Purchase Rate</mat-label>
                  <input matInput type="number" placeholder="Purchase Rate" formControlName="purchaseRate" (change)="updatePrice()"/>
                  <mat-error *ngIf="f.purchaseRate.hasError('required')">
                    Purchase Rate is Required!
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Free User Price Percentage</mat-label>
                  <input matInput type="number" placeholder="Free User Price Percentage" formControlName="freeUserPricePercentage" (change)="updatePrice()"/>
                  <mat-error *ngIf="f.freeUserPricePercentage.hasError('required')">
                      Free User Price Percentage is Required!
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Free User Price</mat-label>
                  <input matInput type="number" placeholder="Free User Price" formControlName="freeUserPrice" readonly/>
              </mat-form-field>
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Premium One User Price Percentage</mat-label>
                  <input matInput type="number" placeholder="Premium One User Price Percentage" formControlName="premiumOneUserPricePercentage" (change)="updatePrice()"/>
                  <mat-error *ngIf="f.premiumOneUserPricePercentage.hasError('required')">
                    Premium One User Price Percentage is Required!
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Premium One User Price</mat-label>
                  <input matInput type="number" placeholder="Premium One User Price"
                      formControlName="premiumOneUserPrice" readonly/>
              </mat-form-field>
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Premium Two User Price Percentage</mat-label>
                  <input matInput type="number" placeholder="Premium Two User Price Percentage" formControlName="premiumTwoUserPricePercentage" (change)="updatePrice()"/>
                  <mat-error *ngIf="f.premiumTwoUserPricePercentage.hasError('required')">
                    Premium Two User Price Percentage is Required!
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Premium Two User Price</mat-label>
                  <input matInput type="number" placeholder="Premium Two User Price"
                      formControlName="premiumTwoUserPrice" readonly/>
              </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>GST</mat-label>
                <input matInput type="number" placeholder="GST" formControlName="gst"/>
                <mat-error *ngIf="f.gst.hasError('required')">
                    GST is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Shipping Charge</mat-label>
                <input matInput type="number" placeholder="Shipping Charge"
                    formControlName="shippingCharge"/>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Product Details</mat-label>
                <textarea matInput placeholder="Product Details" formControlName="productDetails"></textarea>
            <mat-error *ngIf="f.productDetails.hasError('required')">
                Product Details is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Product Specification</mat-label>
            <textarea matInput placeholder="Product Specification" formControlName="productSpecifications"></textarea>
            <mat-error *ngIf="f.productSpecifications.hasError('required')">
                Product Specification is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Discount Percentage</mat-label>
            <input matInput type="number" placeholder="Discount Percentage" formControlName="discountPercentage" />
            <mat-error *ngIf="f.discountPercentage.hasError('required')">
                Discount Percentage is Required!
            </mat-error>
          </mat-form-field>
          <mat-checkbox formControlName="isOutOfStock" value="true">Out of stock</mat-checkbox>
          <mat-checkbox formControlName="isFinishingSoon">Finishing soon</mat-checkbox>
        </form>
        <button mat-raised-button (click)="fileInput.click()">
            <mat-icon>library_add</mat-icon>
            <span>{{fileToUpload?.name || " Select"}}</span>
            <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
          </button>
          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="submit()" color="warn">Submit</button>
        </mat-card-actions>
</mat-card>
import { Component, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  @Input() imageList:string[] = [];
  @Input() editable:boolean = false;

  @Output() fileInputEvent: EventEmitter<any> = new EventEmitter();

  @Input() selectedImageName?:string

  constructor() { }

  ngOnInit(): void {
  }

  onFileInput(event:any){
    this.fileInputEvent.emit(event)
  }

}

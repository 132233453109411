<div>
  <mat-card *ngIf="productDetails">
    <mat-card-content>
      <form [formGroup]="productForm" ngNativeValidate>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Product Name</mat-label>
          <input matInput placeholder="Product Name" formControlName="productName" />
          <mat-error *ngIf="f.productName.hasError('required')">
            Product Name is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Product Code</mat-label>
          <input matInput placeholder="Product Code" formControlName="productCode" />
          <mat-error *ngIf="f.productCode.hasError('required')">
            Product Code is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Product HSN Code</mat-label>
          <input matInput placeholder="Product HSN Code" formControlName="hsnCode" readonly />
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('brand')">
          <mat-label>Product Brand</mat-label>
          <input matInput placeholder="Product Brand" formControlName="productBrand" readonly>
          <mat-error *ngIf="f.productBrand.hasError('required')">
            Product Brand is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('category')">
          <mat-label>Product Category</mat-label>
          <input matInput placeholder="Product Category" formControlName="productCategory" readonly>
          <mat-error *ngIf="f.productCategory.hasError('required')">
            Product Category is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('sub-category')">
          <mat-label>Product Sub Category</mat-label>
          <input matInput placeholder="Product Sub Category" formControlName="productSubCategory" readonly>
          <mat-error *ngIf="f.productSubCategory.hasError('required')">
            Product SubCategory is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Maximum Retail Price</mat-label>
          <input matInput type="number" placeholder="Maximum Retail Price" formControlName="maxRetailPrice" />
          <mat-error *ngIf="f.maxRetailPrice.hasError('required')">
            Maximum Retail Price is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Purchase Rate</mat-label>
          <input matInput type="number" placeholder="Purchase Rate" formControlName="purchaseRate"
            (change)="updatePrice()" />
          <mat-error *ngIf="f.purchaseRate.hasError('required')">
            Purchase Rate is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Free User Price Percentage</mat-label>
          <input matInput type="number" placeholder="Free User Price Percentage"
            formControlName="freeUserPricePercentage" (change)="updatePrice()" />
          <mat-error *ngIf="f.freeUserPricePercentage.hasError('required')">
            Free User Price Percentage is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Free User Price</mat-label>
          <input matInput type="number" placeholder="Free User Price" formControlName="freeUserPrice" readonly />
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Premium One User Price Percentage</mat-label>
          <input matInput type="number" placeholder="Premium One User Price Percentage"
            formControlName="premiumOneUserPricePercentage" (change)="updatePrice()" />
          <mat-error *ngIf="f.premiumOneUserPricePercentage.hasError('required')">
            Premium One User Price Percentage is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Premium One User Price</mat-label>
          <input matInput type="number" placeholder="Premium One User Price" formControlName="premiumOneUserPrice"
            readonly />
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Premium Two User Price Percentage</mat-label>
          <input matInput type="number" placeholder="Premium Two User Price Percentage"
            formControlName="premiumTwoUserPricePercentage" (change)="updatePrice()" />
          <mat-error *ngIf="f.premiumTwoUserPricePercentage.hasError('required')">
            Premium Two User Price Percentage is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Premium Two User Price</mat-label>
          <input matInput type="number" placeholder="Premium Two User Price" formControlName="premiumTwoUserPrice"
            readonly />
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>GST</mat-label>
          <input matInput type="number" placeholder="GST" formControlName="gst" />
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Shipping Charge</mat-label>
          <input matInput type="number" placeholder="Shipping Charge" formControlName="shippingCharge" />
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Product Details</mat-label>
          <textarea matInput placeholder="Product Details" formControlName="productDetails"></textarea>
          <mat-error *ngIf="f.productDetails.hasError('required')">
            Product Details is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Product Specification</mat-label>
          <textarea matInput placeholder="Product Specification" formControlName="productSpecification"></textarea>
          <mat-error *ngIf="f.productSpecification.hasError('required')">
            Product Specification is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Discount Percentage</mat-label>
          <input matInput type="number" placeholder="Discount Percentage" formControlName="discountPercentage" />
          <mat-error *ngIf="f.discountPercentage.hasError('required')">
            Discount Percentage is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Product Status</mat-label>
          <mat-select formControlName=productUpdatedStatus>
            <mat-option *ngFor="let productStatus of availabeProductStatusTypes" [value]="productStatus.value">
              {{productStatus.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-image-viewer [imageList]=getProductImageUrls() [editable]=true [selectedImageName]=fileToUpload?.name
          (fileInputEvent)="onFileInput($event)"></app-image-viewer>
        <mat-checkbox formControlName="isOutOfStock">Out of stock</mat-checkbox>
        <mat-checkbox formControlName="isFinishingSoon">Finishing soon</mat-checkbox>
      </form>
    </mat-card-content>
    <mat-card-actions class="update-mat-card-actions">
      <button mat-raised-button (click)="reset()" color="basic">Reset</button>
      <button mat-raised-button (click)="update()" color="warn">Update</button>
    </mat-card-actions>
  </mat-card>
</div>
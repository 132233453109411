<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>Login</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="loginForm" class="login-form">
             <mat-form-field class="full-width-input">
                <input matInput placeholder="Username" formControlName="username" >
                <mat-error *ngIf="f.username.hasError('required')">
                    UserName is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <input matInput placeholder="Password" formControlName="password"  type="password" >
                <mat-error *ngIf="f.password.hasError('required')">
                    Password is Required!
                </mat-error>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button (click)="login()" color="primary">Login</button>
    </mat-card-actions>
    <mat-error *ngIf="loginInvalid">
        The username and password were not recognized
    </mat-error>
</mat-card>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { ListItemResponse } from 'src/app/models/response/list-item/list-item-response';
import { SubCategoryCreateResponse } from 'src/app/models/response/sub-category/sub-category-create/sub-category-create-response';
import { SubCategoryDetailsResponse } from 'src/app/models/response/sub-category/sub-category-details/sub-category-details-response';
import { SubCategoryListResponse } from 'src/app/models/response/sub-category/sub-category-list/sub-category-list-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  constructor(private baseAPIService:BaseApiService) { }

  createSubCategory(data:any):Observable<SubCategoryCreateResponse>{
    return this.baseAPIService.post("/sub-category/", data).pipe(map((response:SubCategoryCreateResponse)=> {
      return response;
    }))
  }

  getAllSubCategory(params:any):Observable<SubCategoryListResponse>{
    return this.baseAPIService.get("/sub-category/", params).pipe(map((response:SubCategoryListResponse) => {
      return response;
    }))
  }

  updateSubCategory(id:string,data:any):Observable<SubCategoryDetailsResponse>{
    return this.baseAPIService.patch("/sub-category/" + id + "/", data).pipe(map((response:SubCategoryDetailsResponse)=> {
      return response;
    }))
  }

  getSubCategoryDetails(id:string): Observable<SubCategoryDetailsResponse>{
    return this.baseAPIService.get("/sub-category/" + id + "/", null).pipe(map((response:SubCategoryDetailsResponse) => {
      return response;
    }))
  }

  deleteSubCategory(id:string): Observable<BaseResponse>{
    return this.baseAPIService.delete("/sub-category/" + id + "/").pipe(map((response:BaseResponse) => {
      return response;
    }))
  }

  getMinifiedSubCategoryList(params:any):Observable<ListItemResponse>{
    return this.baseAPIService.get("/list-sub-category/", params).pipe(map((response:ListItemResponse) => {
      return response;
    }))
  }
}

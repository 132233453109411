import { ItemType } from "../internal/item-type";

export const ItemTypes: ItemType[] = [
  { value: 'product', viewValue: 'Product' },
  { value: 'brand', viewValue: 'Brand' },
  { value: 'category', viewValue: 'Category' },
  { value: 'sub-category', viewValue: 'Subcategory' },
  { value: 'combined', viewValue: 'Combined' },

];


import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { User } from 'src/app/models/interfaces/user';
import { ActiveInactiveStatus } from 'src/app/models/internal/active-inactive-status';
import { ActiveInactiveStatusTypes } from 'src/app/models/objects/active-inactive-status-types';
import { UsersService } from 'src/app/services/users/users.service';
import { Utilities } from 'src/app/utilities/utilities';
import { MultiSelectBrandComponent } from '../../multi-select/multi-select-brand/multi-select-brand.component';

@Component({
  selector: 'app-seller-details',
  templateUrl: './seller-details.component.html',
  styleUrls: ['./seller-details.component.scss']
})
export class SellerDetailsComponent implements OnInit {

  @ViewChild(MultiSelectBrandComponent) brandSelector!: MultiSelectBrandComponent;

  userId!: string

  userDetails?: User

  availabeUserStatusTypes!: ActiveInactiveStatus[]
  shippingCharge?: number
  userUpdatedStatus?: number

  constructor(private route: ActivatedRoute,
    private usersService: UsersService,
    private utilities: Utilities) {
    this.availabeUserStatusTypes = ActiveInactiveStatusTypes;
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.userId = inputId
    }
  }

  ngOnInit(): void {
    this.callUserDetails()
  }

  update(): void {
    if (this.brandSelector.selectedBrands.length == 0) {
      this.utilities.showSnackbar("Please select atleast one brand/category/subcategory")
      return
    }
    if ((this.shippingCharge ?? -1) < 0 || (this.shippingCharge ?? -1) > 99) {
      this.utilities.showSnackbar("Shipping charge is not in valid range")
      return
    }
    this.usersService.updateUserSubscription(this.userId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )

  }

  reset(): void {
    this.resetUpdatedValues();
  }

  callUserDetails(): void {
    this.usersService.getUserDetails(this.userId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.userDetails = data.data;
            this.resetUpdatedValues();
            console.log(this.userDetails.address);
          }
          else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  private resetUpdatedValues() {
    if (this.userDetails) {
      this.userUpdatedStatus = this.getUserStatusIdFromStatus(this.userDetails.status)
      this.brandSelector.selectedBrands = this.userDetails.brand
      this.shippingCharge = this.userDetails.shipping_charge
    }
  }

  private getUserStatusIdFromStatus(status: string): number | undefined {
    return this.availabeUserStatusTypes.find(statusType => statusType.viewValue === status)?.value;
  }

  private getUserUpdateParams(): any {
    let brands = this.brandSelector.getSelectedBrands().map(function (object) {
      return object.id
    })
    return {
      "status": this.userUpdatedStatus,
      "brand": brands,
      "shipping_charge": this.shippingCharge
    }
  }

}

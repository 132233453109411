<mat-card class="component-container-card">
    <mat-form-field class="minimal-mat-form-field" appearance="fill">
        <mat-label>Product Status</mat-label>
        <mat-select [(ngModel)]=sellerService.productFilterStatus>
            <mat-option *ngFor="let productStatus of availableProductStatusTypes" [value]="productStatus.value">
                {{productStatus.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <app-multi-select-category
        [selectedCategories]="sellerService.productFilterCategory ?? []"></app-multi-select-category>
    <app-multi-select-brand [selectedBrands]="sellerService.productFilterBrand ?? []"></app-multi-select-brand>
    <mat-form-field class="minimal-mat-form-field" appearance="fill">
        <mat-label>Search Query</mat-label>
        <input matInput [(ngModel)]=sellerService.productSearchText />
    </mat-form-field>
    <div class="filter-button-container">
        <button mat-mini-fab color="primary" (click)="onFilterSubmit()">
            <mat-icon>check</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="onFilterClear()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</mat-card>
<table mat-table [dataSource]="dataSource" class="mat=elevation-z8 full-width-table">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let element">{{element.category}}</td>
    </ng-container>
    <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef>Brand</th>
        <td mat-cell *matCellDef="let element">{{element.brand}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{element.status}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row.id)"></tr>
    <tr class="mat-row mat-no-data" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            No result found
        </td>
    </tr>
</table>
<mat-paginator #paginator [length]="totalItemsCount" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 50, 100]"
    (page)="pageChanged($event)">
</mat-paginator>
<mat-card>
  <mat-card-content>
    <form [formGroup]="orderCreateForm">
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('user')">
          <mat-label>Select User</mat-label>
          <input matInput placeholder="User" formControlName="user" readonly>
          <mat-error *ngIf="f.user.hasError('required')">
            Please select a user!
          </mat-error>
        </mat-form-field>
        
        <div *ngIf="selectedUserId">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Remarks</mat-label>
            <input matInput placeholder="Remarks" formControlName="remarks" >
          </mat-form-field>
          <app-order-product-list [orderValue]="orderValueResponse" [userId]="selectedUserId" (cartUpdateEvent)="cartUpdateEvent($event)" ></app-order-product-list>
          <div class="delivery-selection">
            <label>Order Type: </label>
            <mat-radio-group [formControl]="orderTypeControl" (change)="addressTypeSelected()">
            <mat-radio-button value="address">Home Delivery</mat-radio-button>
            <mat-radio-button value="store">Store Pickup</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
    </form>
    <mat-card *ngIf="selectedUserId">
            <div class="address-header"> 
              {{orderTypeHeading}}
            <button mat-icon color="primary" *ngIf="selectedRetailOutlet || selectedAddress" mat-button (click)="removeAddress()">
              <mat-icon>cancel</mat-icon>
            </button>
            <button mat-icon color="primary" *ngIf="selectedRetailOutlet == null && selectedAddress == null" mat-button (click)="openDialog('address')">
              <mat-icon>add_circle</mat-icon>
            </button>
            </div>
      <mat-card-content *ngIf="selectedAddress">
            <h3 matLine> {{selectedAddress?.name}} </h3>
            <h5 matLine> {{selectedAddress?.email}} </h5>
            <h5 matLine> {{selectedAddress?.phone}} </h5>
            <p matLine>
              <span> {{selectedAddress?.address_line_1}}</span>
              <span>, {{selectedAddress?.address_line_2}}</span>
           </p>
          <p matLine>
          <span> {{selectedAddress?.city}}</span>
          <span>, {{selectedAddress?.state}}</span>
          </p>
          <p matLine>
            <span> {{selectedAddress?.pincode}}</span>
          </p>
      </mat-card-content>
          <mat-card-content *ngIf="selectedRetailOutlet">
          <p matLine>
          <span> {{selectedRetailOutlet?.address}}</span>
          </p>
          <p matLine>
            <span> {{selectedRetailOutlet?.pincode}}</span>
          </p>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
  <mat-card-actions class="update-mat-card-actions">
    <button mat-raised-button (click)="reset()" color="basic">Reset</button>
    <button mat-raised-button (click)="submit()" color="warn">Submit</button>
  </mat-card-actions> 
</mat-card>
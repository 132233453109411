import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { CouponCreateResponse } from 'src/app/models/response/coupon/coupon-create/coupon-create-response';
import { CouponDetailsResponse } from 'src/app/models/response/coupon/coupon-details/coupon-details-response';
import { CoupenListResponse } from 'src/app/models/response/coupon/coupon-list/coupon-list-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private baseAPIService:BaseApiService) { }

  createCoupon(data:any):Observable<CouponCreateResponse>{
    return this.baseAPIService.post("/coupon/", data).pipe(map((response:CouponCreateResponse)=> {
      return response;
    }))
  }

  getAllCoupon(params:any):Observable<CoupenListResponse>{
    return this.baseAPIService.get("/coupon/", params).pipe(map((response:CoupenListResponse) => {
      return response;
    }))
  }

  updateCoupon(id:string,data:any):Observable<CouponDetailsResponse>{
    return this.baseAPIService.patch("/coupon/" + id + "/", data).pipe(map((response:CouponDetailsResponse)=> {
      return response;
    }))
  }

  getCouponDetails(id:string): Observable<CouponDetailsResponse>{
    return this.baseAPIService.get("/coupon/" + id + "/", null).pipe(map((response:CouponDetailsResponse) => {
      return response;
    }))
  }

  deleteCoupon(id:string): Observable<BaseResponse>{
    return this.baseAPIService.delete("/coupon/" + id + "/").pipe(map((response:BaseResponse) => {
      return response;
    }))
  }

    getApplicableCoupon(params:any):Observable<CoupenListResponse>{
    return this.baseAPIService.get("/coupon-list/", params).pipe(map((response:CoupenListResponse) => {
      return response;
    }))
  }
}

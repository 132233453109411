<div *ngIf="userDetails">
    <mat-card>
    <mat-card-content>
        <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>User Id</mat-label>
            <input matInput  [(ngModel)]=userDetails.id readonly/>
          </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput  [(ngModel)]=userDetails.name readonly/>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Phone number</mat-label>
            <input matInput  [(ngModel)]=userDetails.phone readonly/>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Joined Date</mat-label>
            <input matInput  [(ngModel)]=userDetails.joined_date readonly/>
          </mat-form-field>
          <app-user-address [addressList]="userDetails.address"></app-user-address>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>User Status</mat-label>
            <mat-select [(ngModel)]=userUpdatedStatus>
                <mat-option *ngFor="let userStatus of availabeUserStatusTypes" [value]="userStatus.value">
                  {{userStatus.viewValue}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field class="subscription-form-field" appearance="outline">
            <mat-label>Subscription Type</mat-label>
            <mat-select [(ngModel)]=userUpdatedSubscription.type>
            <mat-option *ngFor="let userSubscriptionType of availableUserSubscriptionTypes" [value]="userSubscriptionType.viewValue">
              {{userSubscriptionType.viewValue}}
            </mat-option>
          </mat-select>
          </mat-form-field>
          <mat-form-field class="subscription-date-form-field" appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="start_date_picker"  [max]=userUpdatedSubscription.end_date [(ngModel)]=userUpdatedSubscription.start_date readonly>
            <mat-datepicker-toggle matSuffix [for]="start_date_picker"></mat-datepicker-toggle>
            <mat-datepicker #start_date_picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="subscription-date-form-field" appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="end_date_picker"  [min]=userUpdatedSubscription.start_date [(ngModel)]=userUpdatedSubscription.end_date readonly>
            <mat-datepicker-toggle matSuffix [for]="end_date_picker"></mat-datepicker-toggle>
            <mat-datepicker #end_date_picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Credit Limit</mat-label>
            <input type="number" matInput placeholder="Credit Limit" [(ngModel)]="userCreditLimit" >
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Credit Days</mat-label>
            <input type="number" matInput placeholder="Credit Days" [(ngModel)]="userCreditDays" >
          </mat-form-field>
          <div class="eligible-to-order">
            <mat-checkbox [(ngModel)]=userDetails.is_eligible_to_order>Eligible To Order</mat-checkbox>
          </div>
        </mat-card-content>
        <mat-card-actions class="update-mat-card-actions">
          <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="update()" color="warn">Update</button>
        </mat-card-actions>
    </mat-card>
</div>
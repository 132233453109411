import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const pathType = route.data.type ?? 'admin'
    if (pathType == 'admin') {
      if (this.authService.isAdminUser) {
        return true;
      }
      else if (this.authService.isSellerUser) {
        this.router.navigate(['/seller-products'], {})
        return false
      }
    }
    if (this.authService.isSellerUser) {
      if (pathType == 'seller') {
        return true;
      }
    }
    this.router.navigate(['/login'], {});
    return false;
  }
}


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class BulkUpdateService {

  constructor(private baseAPIService: BaseApiService) { }

  updatePrice(data: any): Observable<BaseResponse> {
    return this.baseAPIService.post("/update-product-price", data).pipe(map((response: BaseResponse) => {
      return response;
    }))
  }

  updateDiscount(params: any): Observable<BaseResponse> {
    return this.baseAPIService.post("/update-product-discount", params).pipe(map((response: BaseResponse) => {
      return response;
    }))
  }

  updateProductAvailability(params: any): Observable<BaseResponse> {
    return this.baseAPIService.post("/update-product-availability", params).pipe(map((response: BaseResponse) => {
      return response;
    }))
  }

  updateHSNCode(params: any): Observable<BaseResponse> {
    return this.baseAPIService.post("/update-product-hsncode", params).pipe(map((response: BaseResponse) => {
      return response;
    }))
  }
}

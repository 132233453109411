<mat-card *ngIf="storesDetails || storesId == null">
    <mat-card-content>
        <form [formGroup]="storesForm">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Store Address</mat-label>
            <input matInput placeholder="Store Address" formControlName="storeAddress" >
            <mat-error *ngIf="f.storeAddress.hasError('required')">
                Store Address is Required!
            </mat-error>
          </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('state')">
          <mat-label>State</mat-label>
          <input matInput placeholder="State" formControlName="state" readonly>
          <mat-error *ngIf="f.state.hasError('required')">
            State is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('district')">
          <mat-label>District</mat-label>
          <input matInput placeholder="District" formControlName="district" readonly>
          <mat-error *ngIf="f.district.hasError('required')">
            District is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Pincode</mat-label>
            <input matInput placeholder="Pincode" formControlName="pincode" >
            <mat-error *ngIf="f.pincode.hasError('required')">
                Pincode is Required!
            </mat-error>
          </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Store Status</mat-label>
            <mat-select formControlName=storeStatus>
                <mat-option *ngFor="let status of availabeStatusTypes" [value]="status.value">
                  {{status.viewValue}}
                </mat-option>
              </mat-select>
          </mat-form-field>
        </form>
          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button *ngIf="storesId != null" mat-raised-button (click)="update()" color="warn">Update</button>
            <button *ngIf="storesId == null" mat-raised-button (click)="create()" color="warn">Submit</button>
        </mat-card-actions>
</mat-card>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { OrderDetailsResponse } from 'src/app/models/response/orders/order-details/order-details-response';
import { OrderListResponse } from 'src/app/models/response/orders/order-list/order-list-response';
import { OrderValueDetailsResponse } from 'src/app/models/response/orders/order-value/order-value-details-response';
import { OrderValueResponse } from 'src/app/models/response/orders/order-value/order-value-response';
import { Utilities } from 'src/app/utilities/utilities';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersServiceService {

  constructor(private baseAPIService: BaseApiService) { }

  getAllOrders(params: any): Observable<OrderListResponse> {
    return this.baseAPIService.get("/order", params).pipe(map((response: OrderListResponse) => {
      return response;
    }))
  }

  getOrderDetails(id: string): Observable<OrderDetailsResponse> {
    return this.baseAPIService.get("/order/" + id, null).pipe(map((response: OrderDetailsResponse) => {
      return response;
    }))
  }

  getOrderValue(params: any): Observable<OrderValueResponse> {
    return this.baseAPIService.post("/get-order-value/", params).pipe(map((response: OrderValueResponse) => {
      return response;
    }))
  }

  getOrderValueDetails(params: any): Observable<OrderValueDetailsResponse> {
    return this.baseAPIService.post("/get-detailed-order-value/", params).pipe(map((response: OrderValueDetailsResponse) => {
      return response;
    }))
  }

  updateOrderDetails(id: string, data: any): Observable<BaseResponse> {
    return this.baseAPIService.patch("/order/" + id + "/", data).pipe(map((response: BaseResponse) => {
      return response
    }))
  }

  placeOrder(params: any) {
    return this.baseAPIService.post("/confirm-order/", params).pipe(map((response: BaseResponse) => {
      return response
    }))
  }

  generateInvoice(orderId: String) {
    return this.baseAPIService.get("/generate-order-invoice?order_id=" + orderId, null).pipe(map((response: BaseResponse) => {
      return response
    }))
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { User } from 'src/app/models/interfaces/user';
import { ActiveInactiveStatus } from 'src/app/models/internal/active-inactive-status';
import { Subscription } from 'src/app/models/internal/subscription';
import { ActiveInactiveStatusTypes } from 'src/app/models/objects/active-inactive-status-types';
import { SubscriptionTypes } from 'src/app/models/objects/subscription-types';
import { UsersService } from 'src/app/services/users/users.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-seller-list',
  templateUrl: './seller-list.component.html',
  styleUrls: ['./seller-list.component.scss']
})
export class SellerListComponent implements OnInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource = new MatTableDataSource<User>();
  displayedColumns: string[] = ["name", "phone", "status", "joined_date"];
  availableSubscriptionTypes!: Subscription[]
  availabeUserStatusTypes!: ActiveInactiveStatus[]

  //Filter
  userFilterJoinedStartDate?: Date
  userFilterJoinedEndDate?: Date
  userFilterStatus?: number
  userSearchText?: string

  constructor(private usersService: UsersService,
    private datePipe: DatePipe,
    private router: Router,
    private utilities: Utilities,
    private activatedRoute: ActivatedRoute) {
    this.getAllUsers();
    this.availableSubscriptionTypes = SubscriptionTypes
    this.availabeUserStatusTypes = ActiveInactiveStatusTypes
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {

  }

  getAllUsers() {
    this.usersService.getAllUsers(this.getQueryParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<User>(data.data.results);
            return
          }
          this.utilities.showSnackbar(data.message)
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  onFilterSubmit() {
    this.getAllUsers()
  }

  onFilterClear() {
    this.userFilterJoinedStartDate = undefined;
    this.userFilterJoinedEndDate = undefined;
    this.userFilterStatus = undefined;
    this.userSearchText = undefined;
    this.getAllUsers()
  }

  navigateToDetails(id: String) {
    this.router.navigate(['./seller/' + id])
  }

  onAddClick() {
    this.router.navigate(['./seller/create'])
  }

  pageChanged(event: PageEvent) {
    this.getAllUsers()
  }

  private getQueryParams(): any {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    params["role"] = "seller"
    if (this.userFilterJoinedStartDate) {
      params["start_date"] = this.utilities.getFormattedDateDDMMYYYY(this.userFilterJoinedStartDate);
    }
    if (this.userFilterJoinedEndDate) {
      params["end_date"] = this.utilities.getFormattedDateDDMMYYYY(this.userFilterJoinedEndDate);
    }
    if (this.userFilterStatus != null && this.userFilterStatus != undefined) {
      params["status"] = this.userFilterStatus;
    }
    if (this.userSearchText) {
      params["search"] = this.userSearchText;
    }
    return params;
  }

}

<mat-card>
    <mat-card-content>
        <form [formGroup]="sellerForm">
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput placeholder="First Name" formControlName="firstName">
                <mat-error *ngIf="f.firstName.hasError('required')">
                    First Name is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" formControlName="lastName">
                <mat-error *ngIf="f.lastName.hasError('required')">
                    Last Name is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email">
                <mat-error *ngIf="f.lastName.hasError('required')">
                    Email is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput placeholder="Phone" formControlName="phone">
                <mat-error *ngIf="f.phone.hasError('required')">
                    Phone Number is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password">
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="f.password.hasError('required')">
                    Password is Required!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Shipping Charge Percentage</mat-label>
                <input matInput type="number" placeholder="Shipping Charge Percentage" formControlName="shippingCharge"
                    [max]="99" [min]="0">
                <mat-error *ngIf="f.shippingCharge.hasError('required')">
                    Shipping Charge is Required!
                </mat-error>
                <mat-error *ngIf="f.shippingCharge.hasError('max') || f.shippingCharge.hasError('min')">
                    Please enter valid shipping charge percentage
                </mat-error>
            </mat-form-field>
        </form>
        <app-multi-select-brand [selectedBrands]="[]"></app-multi-select-brand>
        <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="submit()" color="warn">Submit</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
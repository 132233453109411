<mat-card>
    <mat-card-content *ngIf="couponDetails">
        <form [formGroup]="couponForm">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Coupon Name</mat-label>
            <input matInput placeholder="Coupon Name" formControlName="couponName" >
            <mat-error *ngIf="f.couponName.hasError('required')">
                Coupon Name is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Coupon Code</mat-label>
            <input matInput placeholder="Coupon Code" formControlName="couponCode" >
            <mat-error *ngIf="f.couponCode.hasError('required')">
                Coupon Code is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="end_date_picker" formControlName="couponEndDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="end_date_picker"></mat-datepicker-toggle>
            <mat-datepicker #end_date_picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Discount Percentage</mat-label>
            <input type="number" matInput placeholder="Discount Percentage" formControlName="couponDiscountPercentage" >
            <mat-error *ngIf="f.couponDiscountPercentage.hasError('required') || f.couponDiscountPercentage.hasError('max(100)')">
                Valid Discount Percentage is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Min Cart Value Required</mat-label>
            <input type="number" matInput placeholder="Min Cart Value" formControlName="couponMinCartValue" >
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Max Discount Cap</mat-label>
            <input type="number" matInput placeholder="Max Discount Cap" formControlName="couponMaxLimit" >
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Max Usage Limit</mat-label>
            <input type="number" matInput placeholder="Max Usage Limit" formControlName="couponMaxUsageLimit" >
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Coupon Type</mat-label>
            <mat-select formControlName="couponType" (selectionChange)="resetSelectedItem()">
                <mat-option *ngFor="let type of availableCouponTypes" [value]="type.value">
                    {{type.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="f.couponType.hasError('required')">
                Coupon Type is Required!
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline"  (click)="openDialog('user')" *ngIf="showUserSpecific">
          <mat-label>Coupon User</mat-label>
          <input matInput placeholder="Coupon User" formControlName="couponUser">
          <mat-error *ngIf="f.couponUser.hasError('required')">
            Coupon User is Required!
          </mat-error>
      </mat-form-field>
      <mat-form-field  class="full-width-field" appearance="outline" *ngIf="showUserPlan">
        <mat-label>Coupon User Plan</mat-label>
        <mat-select formControlName="couponUserSubscripion">
        <mat-option *ngFor="let userSubscriptionType of availableUserSubscriptionTypes" [value]="userSubscriptionType.value">
          {{userSubscriptionType.viewValue}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.couponUserSubscripion.hasError('required')">
        User Plan is Required!
      </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('brand')" *ngIf="showBrand">
        <mat-label>Coupon Brand</mat-label>
        <input matInput placeholder="Select Brand" formControlName="couponBrand" readonly>
        <mat-error *ngIf="f.couponBrand.hasError('required')">
          Coupon Brand is Required!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('product')" *ngIf="showProduct">
        <mat-label>Coupon Product</mat-label>
        <input matInput placeholder="Select Product" formControlName="couponProduct" readonly>
        <mat-error *ngIf="f.couponProduct.hasError('required')">
          Coupon Product is Required!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('category')" *ngIf="showCategory">
        <mat-label>Coupon Category</mat-label>
        <input matInput placeholder="Select Category" formControlName="couponCategory" readonly>
        <mat-error *ngIf="f.couponCategory.hasError('required')">
          Coupon Category is Required!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('sub-category')" *ngIf="showSubcategory">
        <mat-label>Coupon Subcategory</mat-label>
        <input matInput placeholder="Select Subcategory" formControlName="couponSubcategory" readonly>
        <mat-error *ngIf="f.couponSubcategory.hasError('required')">
          Coupon Subcategory is Required!
        </mat-error>
      </mat-form-field>
        </form>
          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="submit()" color="warn">Update</button>
        </mat-card-actions>
</mat-card>

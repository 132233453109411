import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { OrdersServiceService } from 'src/app/services/orders/orders-service.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { Location } from '@angular/common';
import { UserSelectorDialogComponent } from '../../dialogs/user-selector-dialog/user-selector-dialog.component';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { AddressSelectorDialogComponent } from '../../dialogs/address-selector-dialog/address-selector-dialog.component';
import { Address } from 'src/app/models/interfaces/address';
import { OrderProductItem } from 'src/app/models/interfaces/order-product-item';
import { first } from 'rxjs/operators';
import { OrderValueResponseData } from 'src/app/models/response/orders/order-value/order-value-response-data';
import { ItemType } from 'src/app/models/internal/item-type';
import { StoreList } from 'src/app/models/interfaces/store-list';
import { FloatLabelType } from '@angular/material/form-field';
import { StoreSelectorDialogComponent } from '../../dialogs/store-selector-dialog/store-selector-dialog.component';

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit {

  @Input() orderCreateForm!: FormGroup;
  selectedUserId?: number
  selectedAddress?: Address
  selectedRetailOutlet?: StoreList
  orderProducts?: [OrderProductItem]
  orderValueResponse?: OrderValueResponseData

  orderTypeControl = new FormControl('address' as FloatLabelType);
  orderTypeHeading: string = "Delivery Address"

  constructor(private formBuilder: FormBuilder,
    private orderService: OrdersServiceService,
    private dialog: MatDialog,
    private utilities: Utilities,
    private location: Location,) {
  }

  ngOnInit(): void {
    this.orderCreateForm = this.formBuilder.group({
      user: ['', Validators.required],
      remarks: [''],
    });
  }

  get f() { return this.orderCreateForm.controls; }

  openDialog(type: string) {


    if (type == "user") {
      if (this.selectedUserId != null) {
        this.utilities.showSnackbar("You need to reset to change user")
        return
      }
      const dialogRef = this.dialog.open(UserSelectorDialogComponent, {
        width: '40%',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result != undefined && result != "") {
          this.updateSelectedItem(type, result)
        }
      })
    } else if (type == "address") {
      if (this.orderTypeControl.value == "address") {
        let itemSelectorDialogModel = { value: type, filterId: this.selectedUserId } as ItemSelectorDialogModel
        const dialogRef = this.dialog.open(AddressSelectorDialogComponent, {
          width: '40%',
          data: itemSelectorDialogModel
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != null && result != undefined && result != "") {
            this.updateSelectedAddress(result)
          }
        })
      } else {
        const dialogRef = this.dialog.open(StoreSelectorDialogComponent, {
          width: '40%',
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != null && result != undefined && result != "") {
            this.updateSelectedStore(result)
          }
        })
      }
    }
  }

  cartUpdateEvent(products: [OrderProductItem]) {
    this.orderProducts = products
    this.updateOrderValue()
  }

  addressTypeSelected(): void {
    if (this.orderTypeControl.value == "store") {
      this.orderTypeHeading = "Retail Store"
    } else {
      this.orderTypeHeading = "Delivery Address"
    }
    this.removeAddress()
  }

  removeAddress(): void {
    this.selectedAddress = undefined
    this.selectedRetailOutlet = undefined
  }


  reset(): void {
    this.selectedUserId = undefined
    this.selectedAddress = undefined
    this.orderProducts = undefined
    this.orderValueResponse = undefined
    this.orderCreateForm.reset()
  }

  submit(): void {

    this.orderCreateForm.markAllAsTouched()
    if (this.orderCreateForm.invalid == true) {
      return;
    }
    if ((this.orderProducts?.length ?? 0) == 0) {
      this.utilities.showSnackbar("Add atleast one product")
      return
    }
    if (this.selectedAddress == undefined && this.orderTypeControl.value == "address") {
      this.utilities.showSnackbar("Select an address")
      return
    }

    if (this.selectedRetailOutlet == undefined && this.orderTypeControl.value == "store") {
      this.utilities.showSnackbar("Select a retail outlet")
      return
    }

    this.orderService.placeOrder(this.getOrderPlaceParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.status == 1) {
            this.location.back();
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  updateSelectedAddress(value: Address) {
    this.selectedAddress = value as Address
  }

  updateSelectedStore(value: StoreList): void {
    this.selectedRetailOutlet = value
  }

  updateOrderValue() {
    this.orderService.getOrderValue(this.getOrderValueParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.orderValueResponse = data.data
            console.log(this.orderValueResponse.total_cost)
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  updateSelectedItem(type: string, value: ListItem) {

    switch (type) {
      case "user": {
        this.f.user.setValue(value.name)
        this.selectedUserId = value.id
        break;
      }
    }
  }

  private getOrderPlaceParams(): any {
    let params: any = {}
    params["user_id"] = this.selectedUserId;
    params["total_cost"] = this.orderValueResponse?.total_cost;
    params["discounted_cost"] = this.orderValueResponse?.discounted_cost;
    params["delivery_address_id"] = this.selectedAddress?.id
    params["outlet_address_id"] = this.selectedRetailOutlet?.id
    params["is_outlet_opted"] = this.selectedRetailOutlet?.id != undefined
    params["remarks"] = this.f.remarks.value;

    let item_details: any = []
    this.orderProducts?.forEach(element => {
      item_details.push({
        "product_id": element.product.id,
        "quantity": element.quantity,
        "coupon_id": element.coupon?.id
      })
    });
    params["item_details"] = item_details;
    return params;
  }

  private getOrderValueParams(): any {
    let params: any = {}
    params["user_id"] = this.selectedUserId;
    let item_details: any = []
    this.orderProducts?.forEach(element => {
      item_details.push({
        "product_id": element.product.id,
        "quantity": element.quantity,
        "coupon_id": element.coupon?.id
      })
    });
    params["item_details"] = item_details;
    return params;
  }
}

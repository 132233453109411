import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SubCategoryService } from 'src/app/services/sub-category/sub-category.service';
import { Utilities } from 'src/app/utilities/utilities';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';


@Component({
  selector: 'app-sub-category-create',
  templateUrl: './sub-category-create.component.html',
  styleUrls: ['./sub-category-create.component.scss']
})
export class SubCategoryCreateComponent implements OnInit {

  @Input() subCategoryForm!: FormGroup;

  fileToUpload: File | null = null;
  selectedCategory?: ListItem;

  constructor(private formBuilder: FormBuilder, 
    private subCategoryService:SubCategoryService, 
    private router:Router, 
    private location:Location,
    private utilities:Utilities,
    private dialog:MatDialog) { }

  ngOnInit(): void {
    this.subCategoryForm = this.formBuilder.group({
      subCategoryName: ['', Validators.required],
      subCategoryRank: ['',Validators.required],
      category: ['',Validators.required]
  });
  }

  get f() { return this.subCategoryForm.controls; }

  openDialog(){
    
    const dialogRef = this.dialog.open(ItemSelectorDialogComponent,{
      width: '40%',
      data:  {value: "category"} as ItemSelectorDialogModel
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != ""){
        this.f.category.setValue(result.name)
        this.selectedCategory = result
      }
    })
    this.f.featuredProductItem.markAsUntouched()
  }

  reset(){
    this.fileToUpload = null
    this.subCategoryForm.reset()
  }

  submit(){
    this.subCategoryForm.markAllAsTouched()
    if (this.subCategoryForm.invalid == true) {
      return;
    }
    if (this.fileToUpload == null) {
      this.utilities.showSnackbar("Add image to upload")
      return
    }
    this.subCategoryService.createSubCategory(this.getParams())
    .pipe(first())
    .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
           if (data.data){
             this.location.back()
           }
        },
        error => {
          this.utilities.showSnackbar(error)
        });
  }

  onFileInput(event:any){
    if (event.target?.files?.length > 0){
      this.fileToUpload = event.target?.files[0];      
    }
  }

  getParams():any{
    let formData:FormData = new FormData();
    formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    formData.append('name',this.f.subCategoryName.value);
    formData.append('rank',this.f.subCategoryRank.value);
    if (this.selectedCategory){
      formData.append('category',this.selectedCategory.id.toString());
    }
    return formData;
  }

}

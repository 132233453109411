import { Component, Input, OnInit } from '@angular/core';
import { Address } from 'src/app/models/interfaces/address';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss']
})
export class UserAddressComponent implements OnInit {

  @Input() addressList: Address[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}

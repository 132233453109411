<mat-card class="component-container-card">
    <mat-form-field class="minimal-mat-form-field" appearance="fill">
        <mat-label>Subscription Type</mat-label>
        <mat-select [(ngModel)]=userFilterSubscription>
            <mat-option *ngFor="let subscription of availableSubscriptionTypes" [value]="subscription.value">
              {{subscription.viewValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <!-- <div> -->
        <mat-form-field class="minimal-mat-form-field" appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="start_date_picker"  [max]=userFilterJoinedEndDate  [(ngModel)]=userFilterJoinedStartDate readonly>
            <mat-datepicker-toggle matSuffix [for]="start_date_picker"></mat-datepicker-toggle>
            <mat-datepicker #start_date_picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="minimal-mat-form-field" appearance="fill">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="end_date_picker" [min]=userFilterJoinedStartDate [(ngModel)]=userFilterJoinedEndDate readonly>
            <mat-datepicker-toggle matSuffix [for]="end_date_picker"></mat-datepicker-toggle>
            <mat-datepicker #end_date_picker></mat-datepicker>
          </mat-form-field>
      <!-- </div> -->
      <mat-form-field class="minimal-mat-form-field" appearance="fill">
        <mat-label>User Status</mat-label>
        <mat-select [(ngModel)]=userFilterStatus>
            <mat-option *ngFor="let userType of availabeUserStatusTypes" [value]="userType.value">
              {{userType.viewValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field class="minimal-mat-form-field" appearance="fill">
        <mat-label>Search Query</mat-label>
        <input matInput  [(ngModel)]=userSearchText/>
      </mat-form-field>
      <div class="filter-button-container">
        <button mat-mini-fab color="primary" (click)="OnFilterSubmit()">
            <mat-icon>check</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="OnFilterClear()">
            <mat-icon>clear</mat-icon>
        </button>
      </div>
</mat-card>
<table mat-table [dataSource]="dataSource" class="mat=elevation-z8 full-width-table">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>
    <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let element">{{element.phone}}</td>
    </ng-container>
    <ng-container matColumnDef="subscription_type">
        <th mat-header-cell *matHeaderCellDef>Subscription</th>
        <td mat-cell *matCellDef="let element">{{element.subscription_plan.type}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{element.status}}</td>
    </ng-container>
    <ng-container matColumnDef="joined_date">
        <th mat-header-cell *matHeaderCellDef>Date of Joining</th>
        <td mat-cell *matCellDef="let element">{{element.joined_date}}</td>
    </ng-container>
    <ng-container matColumnDef="orders">
      <th mat-header-cell *matHeaderCellDef>Orders</th>
      <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <button mat-icon-button (click)="onClickOrders(element)">
           <mat-icon>assignment</mat-icon>
          </button>
      </td>
  </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row.id)"></tr>
    <tr class = "mat-row mat-no-data" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No result found
      </td>
  </tr>
</table>
<mat-paginator #paginator [length]="totalItemsCount" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 50, 100]" (page)="pageChanged($event)">
</mat-paginator>
<div>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Ordered Items ({{dataSource.data.length}})
      </mat-panel-title>
      <mat-panel-description>
        Order Cost: RS {{orderValue?.discounted_cost ?? 0.0}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-action-row *ngIf="clearCoupon == false">
      <button mat-raised-button (click)="onClearCoupon()" color="primary">Clear Coupon</button>
    </mat-action-row>
    <table mat-table [dataSource]="dataSource" class="mat=elevation-z8 full-width-table">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{element.product_id}}</td>
      </ng-container>
      <ng-container matColumnDef="product_name">
        <th mat-header-cell *matHeaderCellDef>Product Name</th>
        <td mat-cell *matCellDef="let element">{{element.product_name}}</td>
      </ng-container>
      <ng-container matColumnDef="product_price">
        <th mat-header-cell *matHeaderCellDef>Item Price</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field>
            <input matInput [(ngModel)]=element.sold_cost (change)="refreshCart()">
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="total_price">
        <th mat-header-cell *matHeaderCellDef>Total Cost</th>
        <td mat-cell *matCellDef="let element">{{element.total_cost}}</td>
      </ng-container>
      <ng-container matColumnDef="discounted_price">
        <th mat-header-cell *matHeaderCellDef>Discounted Cost</th>
        <td mat-cell *matCellDef="let element">{{element.discounted_cost}}</td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element"><mat-form-field>
            <input matInput maxlength="3" [(ngModel)]=element.quantity (change)="refreshCart()">
          </mat-form-field></td>
      </ng-container>
      <ng-container matColumnDef="coupon">
        <th mat-header-cell *matHeaderCellDef>Coupon</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.coupon_details && clearCoupon == false">
            <mat-chip-list>
              <mat-chip>
                {{element.coupon_details?.code}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-expansion-panel>
</div>
<mat-card *ngIf="featuredProductsDetails">
    <mat-card-content>
        <form [formGroup]="featuredProductsForm">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Featured Product Name</mat-label>
            <input matInput placeholder="Featured Product Name" formControlName="featuredProductName" >
            <mat-error *ngIf="f.featuredProductName.hasError('required')">
              Featured Product Name is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Featured Product Rank</mat-label>
            <input matInput type="number" placeholder="Featured Product Rank" formControlName="featuredProductRank" >
            <mat-error *ngIf="f.featuredProductRank.hasError('required')">
                Featured Product Rank is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Featured Product Content Type</mat-label>
            <mat-select formControlName="featuredProductContentType" (selectionChange)="resetSelectedItem(true)">
                <mat-option *ngFor="let contentType of availableItemTypes" [value]="contentType.value">
                    {{contentType.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="f.featuredProductContentType.hasError('required')">
                 Featured Product Content Type is Required!
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('brand')" *ngIf="showBrand">
          <mat-label>Featured Product Brand</mat-label>
          <input matInput placeholder="Select Brand" formControlName="featuredProductBrand" readonly>
          <mat-error *ngIf="f.featuredProductBrand.hasError('required')">
            Featured Product Brand is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('product')" *ngIf="showProduct">
          <mat-label>Featured Product Product</mat-label>
          <input matInput placeholder="Select Product" formControlName="featuredProductProduct" readonly>
          <mat-error *ngIf="f.featuredProductProduct.hasError('required')">
            Featured Product Product is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('category')" *ngIf="showCategory">
          <mat-label>Featured Product Category</mat-label>
          <input matInput placeholder="Select Category" formControlName="featuredProductCategory" readonly>
          <mat-error *ngIf="f.featuredProductCategory.hasError('required')">
            Featured Product Category is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('sub-category')" *ngIf="showSubcategory">
          <mat-label>Featured Product Subcategory</mat-label>
          <input matInput placeholder="Select Subcategory" formControlName="featuredProductSubcategory" readonly>
          <mat-error *ngIf="f.featuredProductSubcategory.hasError('required')">
            Featured Product Subcategory is Required!
          </mat-error>
        </mat-form-field>
        </form>

        <app-image-viewer [imageList]=getImageUrls() [editable]=true [selectedImageName]=fileToUpload?.name (fileInputEvent)="onFileInput($event)"></app-image-viewer>

          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="update()" color="warn">Update</button>
        </mat-card-actions>
</mat-card>

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { StoreList } from 'src/app/models/interfaces/store-list';
import { ActiveInactiveStatus } from 'src/app/models/internal/active-inactive-status';
import { ActiveInactiveStatusTypes } from 'src/app/models/objects/active-inactive-status-types';
import { StoreService } from 'src/app/services/store/store.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ConfirmationDialogComponent } from '../../alert/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements AfterViewInit {

  storeFilterStatus?: number
  availableStatusTypes!: ActiveInactiveStatus[]

  totalItemsCount: number = 0;
  pageSize: number = 20;
  pageIndex: number = 0;

  displayedColumns: string[] = ["address", "status", "pincode", "delete"];
  dataSource = new MatTableDataSource<StoreList>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private router: Router, public storeService: StoreService, private dialog: MatDialog, private utilities: Utilities,) {
    this.availableStatusTypes = ActiveInactiveStatusTypes
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
    this.setupPaginatorInitialvalues()
    this.getAllStores()
  }

  setupPaginatorInitialvalues() {
    //TODO:Check for a proper implementation
    setTimeout(() => {
      this.paginator.pageIndex = this.pageIndex;
      this.paginator.pageSize = this.pageSize;
    }, 0.5);
  }

  onFilterSubmit() {
    this.getAllStores()
  }

  onFilterClear() {
    this.storeFilterStatus = undefined;
    this.getAllStores()
    this.paginator.pageIndex = this.pageIndex
  }

  deleteAfterConfirmation(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Do you really want to delete this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteStore(id);
      }
    });
  }

  getAllStores() {
    console.log(this.getQueryParams());

    this.storeService.getStoreList(this.getQueryParams())
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          if (data.data?.results) {
            console.log(data.data?.results)
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<StoreList>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  deleteStore(id: string) {
    this.storeService.deleteStore(id)
      .pipe(first())
      .subscribe(
        data => {
          this.getAllStores()
          this.utilities.showSnackbar(data.message)
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  updatePageIndex() {
    this.pageIndex = this.paginator?.pageIndex
    this.pageSize = this.paginator.pageSize
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.updatePageIndex()
      this.getAllStores()
    }
  }

  pageChanged(event: PageEvent) {
    this.updatePageIndex()
    this.getAllStores()
  }

  navigateToDetails(id: string) {
    this.router.navigate(['./stores/' + id])
  }

  onAddClick() {
    this.router.navigate(['./stores/create'])
  }

  private getQueryParams(): any {
    let params: any = {}
    params["limit"] = this.pageSize;
    params["offset"] = this.pageIndex * params["limit"];
    if (this.storeFilterStatus != undefined) {
      params["status"] = this.storeFilterStatus.toString();
    }
    return params;
  }

}

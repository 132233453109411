<mat-card>
    <mat-card-content>
        <form [formGroup]="categoryForm">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Category Name</mat-label>
            <input matInput placeholder="Category Name" formControlName="categoryName" >
            <mat-error *ngIf="f.categoryName.hasError('required')">
                Category Name is Required!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Category Rank</mat-label>
            <input matInput type="number" placeholder="Category Rank" formControlName="categoryRank" >
            <mat-error *ngIf="f.categoryRank.hasError('required')">
                Category Rank is Required!
            </mat-error>
          </mat-form-field>
        </form>

        <button mat-raised-button (click)="fileInput.click()">
            <mat-icon>library_add</mat-icon>
            <span>{{fileToUpload?.name || " Select"}}</span>
            <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
          </button>
          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="submit()" color="warn">Submit</button>
        </mat-card-actions>
</mat-card>

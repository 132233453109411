<mat-card>
  <mat-card-content>
    <app-multi-select-category [selectedCategories]="[]"></app-multi-select-category>
    <app-multi-select-brand [selectedBrands]="[]"></app-multi-select-brand>
    <app-multi-select-sub-category [selectedSubCategories]="[]"></app-multi-select-sub-category>
  </mat-card-content>
</mat-card>

<mat-card class="update-mat-card">
  <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedUpdateType" (change)="radioButtonChange($event)">
    <mat-radio-button [value]="availableUpdateTypes[0]">Product Price Update </mat-radio-button>
    <mat-radio-button [value]="availableUpdateTypes[1]">Product Discount Update </mat-radio-button>
    <mat-radio-button [value]="availableUpdateTypes[2]">Product Availability Update </mat-radio-button>
    <mat-radio-button [value]="availableUpdateTypes[3]">HSN Code Update </mat-radio-button>
  </mat-radio-group>
  <form [formGroup]="updateForm">
    <mat-form-field class="full-width-field" appearance="outline" *ngIf="showPercentageField">
      <mat-label>Update Percentage</mat-label>
      <input matInput type="number" placeholder="Percentage" formControlName="percentage" />
      <mat-error *ngIf="f.percentage.hasError('required')">
        Update Percentage is Required!
      </mat-error>
      <mat-error *ngIf="f.percentage.hasError('max') || f.percentage.hasError('min')">
        Please enter valid percentage
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width-field" appearance="outline" *ngIf="showHSNCodeField">
      <mat-label>Update HSNCode</mat-label>
      <input matInput placeholder="HSNCode" formControlName="hsncode" />
      <mat-error *ngIf="f.hsncode.hasError('required')">
        HSN Code is Required!
      </mat-error>
    </mat-form-field>
  </form>
  <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedProductAvailableStatus"
    *ngIf="showStockAvailabilityUpdateField">
    <mat-radio-button [value]="productAvailableStatus[0]">In Stock </mat-radio-button>
    <mat-radio-button [value]="productAvailableStatus[1]">Out of Stock </mat-radio-button>
  </mat-radio-group>
  <mat-card-actions class="update-mat-card-actions">
    <button mat-raised-button (click)="reset()" color="basic">Reset</button>
    <button mat-raised-button (click)="submit()" color="warn">Submit</button>
  </mat-card-actions>
</mat-card>
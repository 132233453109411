import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Order } from 'src/app/models/interfaces/order';
import { OrderStatus } from 'src/app/models/internal/order-status';
import { OrderStatusTypes } from 'src/app/models/objects/order-status-types';
import { OrdersServiceService } from 'src/app/services/orders/orders-service.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  dataSource = new MatTableDataSource<Order>();
  displayedColumns: string[] = ["order_id", "phone", "status", "cost", "order_date"];
  availableOrderStatusTypes!: OrderStatus[]

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  //Filter
  orderFilterStatus?: string
  orderFilterStartDate?: Date
  orderFilterEndDate?: Date
  orderSearchText?: string

  userId?: string
  userName?: string

  constructor(private ordersService: OrdersServiceService,
    private router: Router,
    private utilities: Utilities,
    private route: ActivatedRoute) {
    this.availableOrderStatusTypes = OrderStatusTypes;
    let inputId = route.snapshot.paramMap.get("userId")
    if (inputId) {
      this.userId = inputId
    }
    let inputName = route.snapshot.paramMap.get("user")
    if (inputName) {
      this.userName = inputName
    }
    this.getAllOrders()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  onAddClick() {
    this.router.navigate(['./orders/create'])
  }

  getAllOrders() {
    this.ordersService.getAllOrders(this.getQueryParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<Order>(data.data.results);
            if (data.data.results.length < 1 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  OnFilterSubmit() {
    this.getAllOrders()
  }

  OnFilterClear() {
    this.orderFilterStatus = undefined;
    this.orderFilterStartDate = undefined;
    this.orderFilterEndDate = undefined;
    this.orderSearchText = undefined;
    this.getAllOrders()
  }

  navigateToDetails(id: String) {

    //Temp fix need to check for better approach
    if (this.userId) {
      let currentUrlComponents = this.router.url.split(';')
      this.router.navigate([currentUrlComponents[0] + "/" + id, { user: this.userName }])
    } else {
      this.router.navigate(['./orders/' + id])
    }

  }

  pageChanged(event: PageEvent) {
    this.getAllOrders()
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.getAllOrders()
    }
  }

  private getQueryParams(): any {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    if (this.orderFilterStatus) {
      params["status"] = this.orderFilterStatus;
    }
    if (this.orderFilterStartDate) {
      params["start_date"] = this.utilities.getFormattedDateDDMMYYYY(this.orderFilterStartDate);
    }
    if (this.orderFilterEndDate) {
      params["end_date"] = this.utilities.getFormattedDateDDMMYYYY(this.orderFilterEndDate);
    }
    if (this.orderSearchText) {
      params["search"] = this.orderSearchText;
    }
    if (this.userId) {
      params["user"] = this.userId
    }
    return params;
  }


}

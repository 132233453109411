import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { BaseResponse } from 'src/app/models/response/base-response';
import { SellerProductDetailsResponse } from 'src/app/models/response/seller/product-details/seller-product-details-reponse';
import { ProductListResponse } from 'src/app/models/response/seller/product-list/seller-product-list-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  //Check if the implementation if correct or not.
  productFilterStatus?: number
  productFilterBrand?: ListItem[]
  productFilterCategory?: ListItem[]
  productSearchText?: string

  pageIndex = 0
  pageSize = 20

  constructor(private baseAPIService: BaseApiService) { }

  createSeller(data: any): Observable<BaseResponse> {
    return this.baseAPIService.post("/user/create", data).pipe(map((response: BaseResponse) => {
      return response;
    }))
  }

  getSellerProductList(params: any): Observable<ProductListResponse> {
    return this.baseAPIService.get("/seller-product/", params).pipe(map((response: ProductListResponse) => {
      return response;
    }))
  }

  getSellerProductDetails(productId: String): Observable<SellerProductDetailsResponse> {
    return this.baseAPIService.get("/seller-product/" + productId + "/", null).pipe(map((response: SellerProductDetailsResponse) => {
      return response;
    }))
  }

  updateProductPrice(productId: String, data: any): Observable<BaseResponse> {
    return this.baseAPIService.patch("/seller-product/" + productId + "/", data).pipe(map((response: BaseResponse) => {
      return response;
    }))
  }
}

<div *ngIf="orderDetails">
  <mat-card>
    <button class="invoice-btn" mat-raised-button>
      <span *ngIf="orderDetails.invoice_path == null" (click)="callGenerateInvoice()">Generate Invoice</span>
      <a style="text-decoration:none" *ngIf="orderDetails.invoice_path" target="_blank"
        [href]="orderDetails.invoice_path" [download]="getInvoicename()">Download Invoice</a>
    </button>
    <mat-card-content>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>Order Id</mat-label>
        <input matInput [(ngModel)]=orderDetails.order_id readonly />
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>Phone number</mat-label>
        <input matInput [(ngModel)]=orderDetails.phone readonly />
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>Total Cost</mat-label>
        <input matInput [(ngModel)]=orderDetails.total_cost readonly />
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>Discounted Cost</mat-label>
        <input matInput [(ngModel)]=orderDetails.discounted_cost readonly />
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>Ordered Date</mat-label>
        <input matInput [(ngModel)]=orderDetails.order_date readonly />
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput [(ngModel)]=orderDetails.user_name readonly />
      </mat-form-field>
      <app-order-items [orderValue]="orderValueResponse" [clearCoupon]="clearCoupon"
        (cartUpdateEvent)="cartUpdateEvent($event)"></app-order-items>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]=orderUpdatedStatus>
          <mat-option *ngFor="let userStatus of availableOrderStatusTypes" [value]="userStatus.value">
            {{userStatus.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>Remarks</mat-label>
        <input matInput [(ngModel)]=orderDetails.remarks readonly />
      </mat-form-field>
      <mat-card>
        <div class="address-header">
          <u>
            <h4 *ngIf="orderDetails.delivery_address"> Delivery Address </h4>
            <h4 *ngIf="orderDetails.outlet_address"> Store Address </h4>
          </u>
        </div>
        <mat-card-content *ngIf="orderDetails.delivery_address">
          <h3 matLine> {{orderDetails.delivery_address?.name}} </h3>
          <h5 matLine> {{orderDetails.delivery_address?.email}} </h5>
          <h5 matLine> {{orderDetails.delivery_address?.phone}} </h5>
          <p matLine>
            <span> {{orderDetails.delivery_address?.address_line_1}}</span>
            <span>, {{orderDetails.delivery_address?.address_line_2}}</span>
          </p>
          <p matLine>
            <span> {{orderDetails.delivery_address?.city}}</span>
            <span>, {{orderDetails.delivery_address?.state}}</span>
          </p>
          <p matLine>
            <span> {{orderDetails.delivery_address?.pincode}}</span>
          </p>
        </mat-card-content>
        <mat-card-content *ngIf="orderDetails.outlet_address">
          <p matLine>
            <span> {{orderDetails.outlet_address?.address}}</span>
          </p>
          <p matLine>
            <span> {{orderDetails.outlet_address?.pincode}}</span>
          </p>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
    <mat-card-actions class="update-mat-card-actions">
      <button mat-raised-button (click)="reset()" color="basic">Reset</button>
      <button mat-raised-button (click)="update()" color="warn">Update</button>
    </mat-card-actions>
  </mat-card>
</div>
<mat-card class="component-container-card">
  <mat-form-field class="minimal-mat-form-field" appearance="fill">
    <mat-label>Order Status</mat-label>
    <mat-select [(ngModel)]=orderFilterStatus>
      <mat-option *ngFor="let orderStatus of availableOrderStatusTypes" [value]="orderStatus.value">
        {{orderStatus.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="minimal-mat-form-field" appearance="fill">
    <mat-label>Start Date</mat-label>
    <input matInput [matDatepicker]="start_date_picker" [max]=orderFilterEndDate [(ngModel)]=orderFilterStartDate
      readonly>
    <mat-datepicker-toggle matSuffix [for]="start_date_picker"></mat-datepicker-toggle>
    <mat-datepicker #start_date_picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="minimal-mat-form-field" appearance="fill">
    <mat-label>End Date</mat-label>
    <input matInput [matDatepicker]="end_date_picker" [min]=orderFilterStartDate [(ngModel)]=orderFilterEndDate
      readonly>
    <mat-datepicker-toggle matSuffix [for]="end_date_picker"></mat-datepicker-toggle>
    <mat-datepicker #end_date_picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="minimal-mat-form-field" appearance="fill">
    <mat-label>Search Query</mat-label>
    <input matInput [(ngModel)]=orderSearchText />
  </mat-form-field>
  <div class="filter-button-container">
    <button mat-mini-fab color="primary" (click)="OnFilterSubmit()">
      <mat-icon>check</mat-icon>
    </button>
    <button mat-mini-fab color="warn" (click)="OnFilterClear()">
      <mat-icon>clear</mat-icon>
    </button>
    <mat-divider [vertical]="true"></mat-divider>
    <button class="add-button" mat-mini-fab (click)="onAddClick()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</mat-card>
<table mat-table [dataSource]="dataSource" class="mat=elevation-z8 full-width-table">
  <ng-container matColumnDef="order_id">
    <th mat-header-cell *matHeaderCellDef>Order Id</th>
    <td mat-cell *matCellDef="let element">{{element.order_id}}</td>
  </ng-container>
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef>Phone</th>
    <td mat-cell *matCellDef="let element">{{element.phone}}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{element.status}}</td>
  </ng-container>
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef>Cost</th>
    <td mat-cell *matCellDef="let element">{{element.cost}}</td>
  </ng-container>
  <ng-container matColumnDef="order_date">
    <th mat-header-cell *matHeaderCellDef>Date of Order</th>
    <td mat-cell *matCellDef="let element">{{element.order_date}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row.id)"></tr>
  <tr class="mat-row mat-no-data" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
      No result found
    </td>
  </tr>
</table>
<mat-paginator #paginator [length]="totalItemsCount" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 50, 100]"
  (page)="pageChanged($event)">
</mat-paginator>
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { User } from 'src/app/models/interfaces/user';
import { ItemType } from 'src/app/models/internal/item-type';
import { Subscription } from 'src/app/models/internal/subscription';
import { NotificationTypes } from 'src/app/models/objects/notification-types';
import { SubscriptionTypes } from 'src/app/models/objects/subscription-types';
import { PushNotificationService } from 'src/app/services/push-notification/push-notification.service';
import { Utilities } from 'src/app/utilities/utilities';
import { UserSelectorDialogComponent } from '../dialogs/user-selector-dialog/user-selector-dialog.component';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {

  @Input() pushNotificationForm!: FormGroup;
  pushNotificationTypes!: ItemType[]
  pushNotificationUser?: User
  availableSubscriptionTypes!: Subscription[]

  constructor(private formBuilder: FormBuilder,
    private pushNotificationService: PushNotificationService,
    private utilities: Utilities,
    private dialog: MatDialog,) {
    this.pushNotificationTypes = NotificationTypes
    this.availableSubscriptionTypes = SubscriptionTypes
  }

  ngOnInit(): void {
    this.pushNotificationForm = this.formBuilder.group({
      notificationTitle: ['', Validators.required],
      notificationBody: ['', Validators.required],
      pushNotificationType: ['', Validators.required],
      notificationUser: ['', Validators.required],
      pushNotificationSubscriptionType: ['', Validators.required],
    });
  }

  get f() { return this.pushNotificationForm.controls; }

  get showSubscriptionTypes() { return this.f.pushNotificationType.value && this.f.pushNotificationType.value == "user_specific" }

  get showUserSpecific() { return this.f.pushNotificationType.value && this.f.pushNotificationType.value == "filtered_user" }

  reset() {
    this.pushNotificationForm.reset()
    this.pushNotificationUser = undefined
    this.f.notificationUser.setValue(undefined)
  }

  pushNotificationTypeChanged() {
    this.pushNotificationUser = undefined
    this.f.pushNotificationSubscriptionType.setValue(undefined)
    this.f.notificationUser.setValue(undefined)
    switch (this.f.pushNotificationType.value) {
      case "filtered_user": {
        this.f.pushNotificationSubscriptionType.clearValidators()
        this.f.notificationUser.setValidators(Validators.required)
        break;
      }
      case "user_specific": {
        this.f.pushNotificationSubscriptionType.setValidators(Validators.required)
        this.f.notificationUser.clearValidators()
        break;
      }
      default: {
        break;
      }
    }
    this.f.notificationUser.updateValueAndValidity()
    this.f.pushNotificationSubscriptionType.updateValueAndValidity()
    this.pushNotificationForm.markAsUntouched()
  }

  submit() {
    this.pushNotificationForm.markAllAsTouched()
    if (this.pushNotificationForm.invalid == true) {
      return;
    }
    this.pushNotificationService.sentPushNotification(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          this.reset()
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  openDialog(type: string) {

    if (type == "user") {
      const dialogRef = this.dialog.open(UserSelectorDialogComponent, {
        width: '40%',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result != undefined && result != "") {
          this.f.notificationUser.setValue(result.name)
          this.pushNotificationUser = result
        }
      })
    }
    this.f.notificationUser.markAsUntouched()
  }

  getParams(): any {
    let formData: FormData = new FormData();
    formData.append('title', this.f.notificationTitle.value);
    formData.append('body', this.f.notificationBody.value);
    switch (this.f.pushNotificationType?.value) {
      case "user_specific":
        formData.append('notification_type', this.f.pushNotificationType.value);
        formData.append('plan_type', this.f.pushNotificationSubscriptionType.value.toString() ?? "");
        break
      case "filtered_user":
        formData.append('notification_type', this.f.pushNotificationType.value);
        formData.append('user_id', this.pushNotificationUser?.id.toString() ?? "");
        break
    }
    return formData;
  }

}

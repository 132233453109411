import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  constructor(private http:HttpClient, private injector: Injector) { }

  get(url:string, params:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}` + url, {params:  params})
  }

  post(url: string, data: any): Observable<any>{
    return this.http.post(`${environment.apiUrl}` + url, data)
  }

  patch(url: string, data: any): Observable<any>{    
    return this.http.patch(`${environment.apiUrl}` + url, data)
  }

  delete(url:string): Observable<any>{
    return this.http.delete(`${environment.apiUrl}` + url)
  }

  // /**
  //  * Handles the error, prints log, handles http errors, send analytics for errors and send the callback
  //  * @param action request's action passed from request function
  //  * @param error error passed from request function
  //  * @param callback callback passed from request function
  //  */
  //  private handleError(action: string, error: HttpErrorResponse, callback: ICallback) {
  //   // TODO: dismiss loader.
  //   //Printing logs as table format
  //   console.table([{ action: action, status: error.status, message: error.message }])
  //   //Handling custom http errors
  //   switch (error.status) {
  //     case 404:
  //       alert("OOPS, Request Not Found! :( ")
  //       break;
  //     case 401:
  //       // TODO: Unauthorized request, perform login call again.
  //       break;
  //   }
   
  // }
}

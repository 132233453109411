import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ImageModule } from 'src/app/models/enums/image-module.enum';
import { SellerProduct } from 'src/app/models/interfaces/seller-product';
import { SellerService } from 'src/app/services/seller/seller.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-seller-product-details',
  templateUrl: './seller-product-details.component.html',
  styleUrls: ['./seller-product-details.component.scss']
})
export class SellerProductDetailsComponent implements OnInit {

  @Input() productForm!: FormGroup;

  fileToUpload: File | null = null;

  productId!: string;
  productDetails?: SellerProduct;

  sellerPrice = "";

  constructor(private route: ActivatedRoute,
    private sellerService: SellerService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.productId = inputId
    }
    this.callProductDetails()
  }

  ngOnInit(): void {
  }

  callProductDetails() {
    this.sellerService.getSellerProductDetails(this.productId)
      .pipe(first())
      .subscribe(
        data => {
          this.productDetails = data.data
          this.reset()
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  getProductImageUrl(): [string] {
    //Should call only from html component with category details check
    return [this.utilities.getImageUrl(ImageModule.Product, this.productDetails!.image)]
  }

  reset() {
    this.sellerPrice = this.productDetails?.seller_purchase_rate ?? ""
  }

  update() {
    this.sellerService.updateProductPrice(this.productId, this.getProductUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          this.callProductDetails()
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  private getProductUpdateParams(): any {
    let params: any = {}
    params["seller_purchase_rate"] = this.sellerPrice.toString();
    return params
  }

}

<div>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Address
      </mat-panel-title>
      <mat-panel-description>
       {{addressList.length}} Available
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-grid-list cols="4" rowHeight="2:1">
      <mat-grid-tile *ngFor="let address of addressList">
      <mat-card>
      
    <h3 matLine> {{address.name}} </h3>
    <h5 matLine> {{address.email}} </h5>
    <h5 matLine> {{address.phone}} </h5>
    <p matLine>
      <span> {{address.address_line_1}}</span>
      <span>, {{address.address_line_2}}</span>
    </p>
    <p matLine>
      <span> {{address.city}}</span>
      <span>, {{address.state}}</span>
    </p>
    <p matLine>
      <span> {{address.pincode}}</span>
    </p>
  </mat-card>
  </mat-grid-tile>
</mat-grid-list>
  </mat-expansion-panel>
 
</div>
<mat-form-field class="minimal-mat-form-field" appearance="fill" ng-if="categories">
    <mat-label>Select Categories</mat-label>
    <mat-chip-list #chipList aria-label="Select Categories">
      <mat-chip
        class="minimal-mat-chip"
        *ngFor="let category of selectedCategories"
        (removed)="remove(category)">
        {{category.name}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        placeholder="Select Category..."
        #categoryInput
        [formControl]="categoryCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        >
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option class="minimal-mat-option" *ngFor="let category of categories" [value]="category">
        {{category.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  
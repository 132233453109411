<div *ngIf="categoryDetails">
    <mat-card>
    <mat-card-content>
      <form [formGroup]="categoryForm">
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Category Name</mat-label>
          <input matInput placeholder="Category Name" formControlName="categoryName" >
          <mat-error *ngIf="f.categoryName.hasError('required')">
              Category Name is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Category Rank</mat-label>
          <input matInput type="number" placeholder="Category Rank" formControlName="categoryRank" >
          <mat-error *ngIf="f.categoryRank.hasError('required')">
              Category Rank is Required!
          </mat-error>
        </mat-form-field>
      </form>
      <app-image-viewer [imageList]=getImageUrls() [editable]=true [selectedImageName]=fileToUpload?.name (fileInputEvent)="onFileInput($event)"></app-image-viewer>
          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="update()" color="warn">Update</button>
        </mat-card-actions>
    </mat-card>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ItemType } from 'src/app/models/internal/item-type';
import { ItemTypes } from 'src/app/models/objects/item-types';
import { FeaturedProductsService } from 'src/app/services/featured-products/featured-products.service';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { Location } from '@angular/common';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';

@Component({
  selector: 'app-featured-products-create',
  templateUrl: './featured-products-create.component.html',
  styleUrls: ['./featured-products-create.component.scss']
})
export class FeaturedProductsCreateComponent implements OnInit {

  availableItemTypes!: ItemType[]

  selectedBrand?: ListItem
  selectedProduct?: ListItem
  selectedCategory?: ListItem
  selectedSubcategory?: ListItem

  @Input() featuredProductsForm!: FormGroup;

  fileToUpload: File | null = null;

  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private featuredProductService: FeaturedProductsService,
    private router: Router,
    private location: Location,
    private utilities: Utilities) {
    this.availableItemTypes = ItemTypes
  }

  ngOnInit(): void {
    this.featuredProductsForm = this.formBuilder.group({
      featuredProductName: ['', Validators.required],
      featuredProductRank: ['', Validators.required],
      featuredProductContentType: ['', Validators.required],
      featuredProductBrand: [''],
      featuredProductProduct: [''],
      featuredProductCategory: [''],
      featuredProductSubcategory: [''],
    });
  }

  openDialog(type: string) {

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: { value: type } as ItemSelectorDialogModel
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.updateSelectedItem(type, result)
      }
    })
    this.f.featuredProductBrand.markAsUntouched()
    this.f.featuredProductCategory.markAsUntouched()
    this.f.featuredProductSubcategory.markAsUntouched()
    this.f.featuredProductProduct.markAsUntouched()
  }

  get f() { return this.featuredProductsForm.controls; }

  get showBrand() { return this.f.featuredProductContentType.value && (this.f.featuredProductContentType.value == "brand" || this.f.featuredProductContentType.value == "combined") }

  get showProduct() { return this.f.featuredProductContentType.value && (this.f.featuredProductContentType.value == "product") }

  get showCategory() { return this.f.featuredProductContentType.value && (this.f.featuredProductContentType.value == "category" || this.f.featuredProductContentType.value == "combined") }

  get showSubcategory() { return this.f.featuredProductContentType.value && (this.f.featuredProductContentType.value == "sub-category" || this.f.featuredProductContentType.value == "combined") }

  updateSelectedItem(type: string, value: ListItem) {

    switch (type) {
      case "brand": {
        this.f.featuredProductBrand.setValue(value.name)
        this.selectedBrand = value
        break;
      }
      case "category": {
        this.f.featuredProductCategory.setValue(value.name)
        this.selectedCategory = value
        break;
      }
      case "sub-category": {
        this.f.featuredProductSubcategory.setValue(value.name)
        this.selectedSubcategory = value
        break;
      }
      case "product": {
        this.f.featuredProductProduct.setValue(value.name)
        this.selectedProduct = value
        break;
      }
    }
  }


  reset() {
    this.featuredProductsForm.reset()
    this.resetSelectedItem()
  }

  submit() {
    this.featuredProductsForm.markAllAsTouched()
    if (this.featuredProductsForm.invalid == true) {
      return;
    }
    if (this.selectedBrand == null && this.selectedCategory == null && this.selectedSubcategory == null && this.selectedProduct == null) {
      this.utilities.showSnackbar("Select atleast one item (Brand/Category/Subcategory/Product)")
      return;
    }
    if (this.fileToUpload == null) {
      this.utilities.showSnackbar("Add image to upload")
      return
    }
    this.featuredProductService.createFeaturedProduct(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.data) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  resetSelectedItem() {
    this.selectedBrand = undefined;
    this.selectedCategory = undefined;
    this.selectedSubcategory = undefined;
    this.selectedProduct = undefined;
    this.f.featuredProductBrand.setValue(undefined)
    this.f.featuredProductCategory.setValue(undefined)
    this.f.featuredProductSubcategory.setValue(undefined)
    this.f.featuredProductProduct.setValue(undefined)
    this.updateValidators()
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  getParams(): any {
    let formData: FormData = new FormData();
    formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    formData.append('name', this.f.featuredProductName.value);
    formData.append('rank', this.f.featuredProductRank.value);
    formData.append('content_type', this.f.featuredProductContentType.value);
    if (this.selectedBrand) {
      formData.append("brand", this.selectedBrand!.id.toString());
    }
    if (this.selectedProduct) {
      formData.append("product", this.selectedProduct!.id.toString());
    }
    if (this.selectedCategory) {
      formData.append("category", this.selectedCategory!.id.toString());
    }
    if (this.selectedSubcategory) {
      formData.append("sub_category", this.selectedSubcategory!.id.toString());
    }
    return formData;
  }

  updateValidators() {

    this.f.featuredProductBrand.removeValidators(Validators.required)
    this.f.featuredProductCategory.removeValidators(Validators.required)
    this.f.featuredProductSubcategory.removeValidators(Validators.required)
    this.f.featuredProductProduct.removeValidators(Validators.required)

    switch (this.f.featuredProductContentType.value) {
      case "brand": {
        this.f.featuredProductBrand.setValidators(Validators.required)
        break;
      }
      case "category": {
        this.f.featuredProductCategory.setValidators(Validators.required)
        break;
      }
      case "sub-category": {
        this.f.featuredProductSubcategory.setValidators(Validators.required)
        break;
      }
      case "product": {
        this.f.featuredProductProduct.setValidators(Validators.required)
        break;
      }
      default: {
        break;
      }
    }
    this.f.featuredProductBrand.updateValueAndValidity()
    this.f.featuredProductCategory.updateValueAndValidity()
    this.f.featuredProductSubcategory.updateValueAndValidity()
    this.f.featuredProductProduct.updateValueAndValidity()
    this.featuredProductsForm.markAsUntouched()
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { BaseResponse } from 'src/app/models/response/base-response';
import { ListItemResponse } from 'src/app/models/response/list-item/list-item-response';
import { ProductCreateResponse } from 'src/app/models/response/product/product-create/product-create-response';
import { ProductDetailsResponse } from 'src/app/models/response/product/product-details/product-details-response';
import { ProductListResponse } from 'src/app/models/response/product/product-list/product-list-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

   //Check if the implementation if correct or not.
   productFilterStatus?: number
   productFilterBrand?: ListItem[]
   productFilterCategory?: ListItem[]
   productSearchText?:string

   pageIndex = 0
   pageSize = 20

  constructor(private baseAPIService:BaseApiService) { }

  createProduct(data:any):Observable<ProductCreateResponse>{
    return this.baseAPIService.post("/product/", data).pipe(map((response:ProductCreateResponse)=> {
      return response;
    }))
  }

  getAllProducts(params:any):Observable<ProductListResponse>{
    return this.baseAPIService.get("/product/", params).pipe(map((response:ProductListResponse) => {
      return response;
    }))
  }

  updateProduct(id:string,data:any):Observable<ProductDetailsResponse>{
    return this.baseAPIService.patch("/product/" + id + "/", data).pipe(map((response:ProductDetailsResponse)=> {
      return response;
    }))
  }

  getProductDetails(id:string): Observable<ProductDetailsResponse>{
    return this.baseAPIService.get("/product/" + id + "/", null).pipe(map((response:ProductDetailsResponse) => {
      return response;
    }))
  }

  deleteProduct(id:string): Observable<BaseResponse>{
    return this.baseAPIService.delete("/product/" + id + "/").pipe(map((response:BaseResponse) => {
      return response;
    }))
  }

  getMinifiedProductsList(params:any):Observable<ListItemResponse>{
    return this.baseAPIService.get("/list-product/", params).pipe(map((response:ListItemResponse) => {
      return response;
    }))
  }
}

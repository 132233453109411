import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsResponse } from 'src/app/models/response/analytics/analytics-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private baseAPIService:BaseApiService) { 

  }

  getUserAnalytics(userId:String):Observable<AnalyticsResponse>{
    return this.baseAPIService.get("/user-analytics/" + userId, null).pipe(map((response:AnalyticsResponse) => {
      return response;
    }))
  }
}

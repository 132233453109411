import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ImageModule } from 'src/app/models/enums/image-module.enum';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { SubCategory } from 'src/app/models/interfaces/sub-category';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { SubCategoryService } from 'src/app/services/sub-category/sub-category.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';

@Component({
  selector: 'app-sub-category-details',
  templateUrl: './sub-category-details.component.html',
  styleUrls: ['./sub-category-details.component.scss']
})
export class SubCategoryDetailsComponent implements OnInit {

  @Input() subCategoryForm!: FormGroup;

  selectedCategory?: ListItem;

  subCategoryId!: string
  subCategoryDetails?: SubCategory

  fileToUpload: File | null = null;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private subCategoryService: SubCategoryService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.subCategoryId = inputId
    }
    this.callSubCategoryDetails()
  }

  ngOnInit(): void {
    this.subCategoryForm = this.formBuilder.group({
      subCategoryName: ['', Validators.required],
      subCategoryRank: ['', Validators.required],
      category: ['', Validators.required]
    });
  }

  get f() { return this.subCategoryForm.controls; }

  openDialog() {

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: { value: "category" } as ItemSelectorDialogModel
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.f.category.setValue(result.name)
        this.selectedCategory = result
      }
    })
    this.f.category.markAsUntouched()
  }

  update(): void {
    this.subCategoryForm.markAllAsTouched()
    if (this.subCategoryForm.invalid == true) {
      return;
    }
    this.subCategoryService.updateSubCategory(this.subCategoryId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            // this.subCategoryDetails = data.data
            this.utilities.showSnackbar(data.message)
            this.callSubCategoryDetails()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset(): void {
    this.resetUpdatedValues();
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  private callSubCategoryDetails() {
    this.subCategoryService.getSubCategoryDetails(this.subCategoryId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.subCategoryDetails = data.data;
            this.resetUpdatedValues();
          }
        }
      )
  }

  getImageUrls(): [string] {
    return [this.utilities.getImageUrl(ImageModule.SubCategory, this.subCategoryDetails!.image)]
  }

  private getUserUpdateParams(): any {

    let formData: FormData = new FormData();
    if (this.fileToUpload) {
      formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    }
    formData.append('name', this.f.subCategoryName.value);
    formData.append('rank', this.f.subCategoryRank.value);
    if (this.selectedCategory) {
      formData.append('category', this.selectedCategory.id.toString());
    }
    return formData;
  }

  private resetUpdatedValues() {
    if (this.subCategoryDetails) {
      this.f.subCategoryName.setValue(this.subCategoryDetails.name)
      this.f.subCategoryRank.setValue(this.subCategoryDetails.rank)
      this.f.category.setValue(this.subCategoryDetails.category.name)
      this.selectedCategory = this.subCategoryDetails.category
    }
    this.fileToUpload = null
  }

}

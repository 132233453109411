<mat-form-field class="minimal-mat-form-field" appearance="fill" ng-if="brands">
    <mat-label>Select Brands</mat-label>
    <mat-chip-list #chipList aria-label="Select Brands">
      <mat-chip
        class="minimal-mat-chip"
        *ngFor="let brand of selectedBrands"
        (removed)="remove(brand)">
        {{brand.name}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        placeholder="Select Brand..."
        #brandInput
        [formControl]="brandCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        >
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option class="minimal-mat-option" *ngFor="let brand of brands" [value]="brand">
        {{brand.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { ListItemResponse } from 'src/app/models/response/list-item/list-item-response';
import { StoreDetailsResponse } from 'src/app/models/response/store/store-details/store-details-response';
import { StoreListResponse } from 'src/app/models/response/store/store-list/store-list-response';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private baseAPIService:BaseApiService) { }

  createProduct(data:any):Observable<StoreDetailsResponse>{
    return this.baseAPIService.post("/retail-outlet/", data).pipe(map((response:StoreDetailsResponse)=> {
      return response;
    }))
  }

  getStoreList(params:any):Observable<StoreListResponse>{
    return this.baseAPIService.get("/retail-outlet/", params).pipe(map((response:StoreListResponse) => {
      return response;
    }))
  }

  getStoreDetails(id:string): Observable<StoreDetailsResponse>{
    return this.baseAPIService.get("/retail-outlet/" + id + "/", null).pipe(map((response:StoreDetailsResponse) => {
      return response;
    }))
  }

  updateProduct(id:string,data:any):Observable<StoreDetailsResponse>{
    return this.baseAPIService.patch("/retail-outlet/" + id + "/", data).pipe(map((response:StoreDetailsResponse)=> {
      return response;
    }))
  }

  deleteStore(id:string): Observable<BaseResponse>{
    return this.baseAPIService.delete("/retail-outlet/" + id + "/").pipe(map((response:BaseResponse) => {
      return response;
    }))
  }

    getStateList(params:any):Observable<ListItemResponse>{
    return this.baseAPIService.get("/state-list/", params).pipe(map((response:ListItemResponse) => {
      return response;
    }))
  }

  getDistrictList(params:any):Observable<ListItemResponse>{
    return this.baseAPIService.get("/district-list/", params).pipe(map((response:ListItemResponse) => {
      return response;
    }))
  }

}

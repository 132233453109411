import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { Address } from 'src/app/models/interfaces/address';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { UsersService } from 'src/app/services/users/users.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-address-selector-dialog',
  templateUrl: './address-selector-dialog.component.html',
  styleUrls: ['./address-selector-dialog.component.scss']
})
export class AddressSelectorDialogComponent {

  dataSource = new MatTableDataSource<Address>();
  displayedColumns: string[] = ["name", "email", "phone", "address"];
  user_id?: number;

  constructor(public dialogRef: MatDialogRef<AddressSelectorDialogComponent>,
    private usersService: UsersService,
    private utilities: Utilities,
    @Inject(MAT_DIALOG_DATA) public itemSelectorDialogModel: ItemSelectorDialogModel) {
    this.user_id = this.itemSelectorDialogModel.filterId
    this.fetchAddressList()
  }


  itemSelected(id: string) {

  }

  applyFilter() {
    this.fetchAddressList()
  }


  fetchAddressList() {
    this.usersService.getAddressList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.dataSource = new MatTableDataSource<Address>(data.data);
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  getParams() {
    let params: any = {}
    params["user_id"] = this.user_id;
    return params;
  }


}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { first } from 'rxjs/operators';
import { BulkUpdateService } from 'src/app/services/bulk-update/bulk-update.service';
import { Utilities } from 'src/app/utilities/utilities';
import { MultiSelectBrandComponent } from '../multi-select/multi-select-brand/multi-select-brand.component';
import { MultiSelectCategoryComponent } from '../multi-select/multi-select-category/multi-select-category.component';
import { MultiSelectSubCategoryComponent } from '../multi-select/multi-select-sub-category/multi-select-sub-category.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bulk-update',
  templateUrl: './bulk-update.component.html',
  styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent implements OnInit {

  availableUpdateTypes = [
    "ProductPriceUpdate",
    "ProductDiscountUpdate",
    "ProductAvailabilityUpdate",
    "HSNCodeUpdate",
  ]

  productAvailableStatus = [
    "InStock",
    "OutOfStock",
  ]

  @Input() updateForm!: FormGroup;

  @ViewChild(MultiSelectBrandComponent) brandSelector!: MultiSelectBrandComponent;
  @ViewChild(MultiSelectCategoryComponent) categorySelector!: MultiSelectCategoryComponent;
  @ViewChild(MultiSelectSubCategoryComponent) subcategorySelector!: MultiSelectSubCategoryComponent;

  selectedUpdateType: String;
  selectedProductAvailableStatus: String;

  constructor(private formBuilder: FormBuilder, private bulkUpdateService: BulkUpdateService, private location: Location,
    private utilities: Utilities) {
    this.selectedUpdateType = this.availableUpdateTypes[0];
    this.selectedProductAvailableStatus = this.productAvailableStatus[0];
  }

  ngOnInit(): void {
    this.updateForm = this.formBuilder.group({
      percentage: ['', Validators.required],
      hsncode: ['', Validators.required]
    });
    this.updateValidatorForCurrentSelection()
  }

  get f() { return this.updateForm.controls; }

  get showPercentageField() { return this.selectedUpdateType && (this.selectedUpdateType == this.availableUpdateTypes[0] || this.selectedUpdateType == this.availableUpdateTypes[1]) }
  get showStockAvailabilityUpdateField() { return this.selectedUpdateType && this.selectedUpdateType == this.availableUpdateTypes[2] }
  get showHSNCodeField() { return this.selectedUpdateType && this.selectedUpdateType == this.availableUpdateTypes[3] }

  radioButtonChange(event: MatRadioChange) {
    this.updateForm.reset()
    this.updateValidatorForCurrentSelection()
  }

  updateValidatorForCurrentSelection() {
    if (this.selectedUpdateType == this.availableUpdateTypes[0]) {
      this.updateValidatorForPriceUpdate()
    } else if (this.selectedUpdateType == this.availableUpdateTypes[1]) {
      this.updateValidatorForDiscountUpdate()
    } else if (this.selectedUpdateType == this.availableUpdateTypes[2]) {
      this.updateValidatorForProductAvailability()
    } else if (this.selectedUpdateType == this.availableUpdateTypes[3]) {
      this.updateValidatorForHSNCodeUpdate()
    }
  }

  updateValidatorForPriceUpdate() {
    this.f.percentage.setValidators([Validators.required, Validators.min(-99), Validators.max(99)]);
    this.f.hsncode.clearValidators()
    this.f.hsncode.updateValueAndValidity()
  }

  updateValidatorForDiscountUpdate() {
    this.f.percentage.setValidators([Validators.required, Validators.min(1), Validators.max(99)]);
    this.f.hsncode.clearValidators()
    this.f.hsncode.updateValueAndValidity()
  }

  updateValidatorForProductAvailability() {
    this.f.percentage.clearValidators()
    this.f.percentage.updateValueAndValidity()
    this.f.hsncode.clearValidators()
    this.f.hsncode.updateValueAndValidity()
  }

  updateValidatorForHSNCodeUpdate() {
    this.f.percentage.clearValidators()
    this.f.percentage.updateValueAndValidity()
    this.f.hsncode.setValidators([Validators.required]);
    this.f.hsncode.updateValueAndValidity()
  }

  reset() {
    this.updateForm.reset()
    this.selectedUpdateType = this.availableUpdateTypes[0];
    this.selectedProductAvailableStatus = this.productAvailableStatus[0];
  }

  submit() {
    this.updateForm.markAllAsTouched()
    if (this.updateForm.invalid == true) {
      return;
    }
    if (this.brandSelector.selectedBrands.length == 0 &&
      this.categorySelector.selectedCategories.length == 0 &&
      this.subcategorySelector.selectedSubCategories.length == 0) {
      this.utilities.showSnackbar("Please select atleast one brand/category/subcategory")
    }
    switch (this.selectedUpdateType) {
      case this.availableUpdateTypes[0]:
        this.bulkUpdatePrice();
        break;
      case this.availableUpdateTypes[1]:
        this.bulkUpdateDiscount();
        break;
      case this.availableUpdateTypes[2]:
        this.bulkUpdateProductAvailability();
        break;
      case this.availableUpdateTypes[3]:
        this.bulkUpdateHSNCode();
    }
  }

  bulkUpdatePrice() {
    this.bulkUpdateService.updatePrice(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.status) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  bulkUpdateDiscount() {
    this.bulkUpdateService.updateDiscount(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.status) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  bulkUpdateProductAvailability() {
    this.bulkUpdateService.updateProductAvailability(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.status) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  bulkUpdateHSNCode() {
    this.bulkUpdateService.updateHSNCode(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          this.utilities.showSnackbar(data.message)
          if (data.status) {
            this.location.back()
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  getParams(): any {
    let formData: FormData = new FormData();
    let brands = this.brandSelector.getSelectedBrands().map(function (object) {
      return object.id
    })
    let categories = this.categorySelector.getSelectedCategories().map(function (object) {
      return object.id
    })
    let subcategories = this.subcategorySelector.getSelectedSubCategory().map(function (object) {
      return object.id
    })
    formData.append('brands', JSON.stringify(brands));
    formData.append('categories', JSON.stringify(categories));
    formData.append('subcategories', JSON.stringify(subcategories));
    let percentage = this.f.percentage.value
    switch (this.selectedUpdateType) {
      case this.availableUpdateTypes[0]:
        let isIncrement = (percentage > 0)
        formData.append('percentage', percentage);
        formData.append('is_increment', isIncrement.toString());
        break;
      case this.availableUpdateTypes[1]:
        formData.append('discount_percentage', percentage);
        break;
      case this.availableUpdateTypes[2]:
        formData.append('is_out_of_stock', (this.selectedProductAvailableStatus == this.productAvailableStatus[1]) ? "True" : "False");
        break;
      case this.availableUpdateTypes[3]:
        formData.append('hsn_code', this.f.hsncode.value);
        break;
    }
    return formData;
  }
}

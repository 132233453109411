import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { CategoryService } from 'src/app/services/category/category.service';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { Utilities } from 'src/app/utilities/utilities';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-multi-select-category',
  templateUrl: './multi-select-category.component.html',
  styleUrls: ['./multi-select-category.component.scss']
})
export class MultiSelectCategoryComponent implements OnInit {

  categoryCtrl = new FormControl();

  categories: ListItem[] = []
  @Input() selectedCategories: ListItem[] = []

  @ViewChild('categoryInput') categoryInput?: ElementRef<HTMLInputElement>;

  constructor(
    private categoryService: CategoryService,
    private utilities: Utilities,
  ) {

  }
  ngOnInit(): void {
    this.fetchCategoryList()
    this.categoryCtrl.valueChanges.subscribe(
      (_) => {
        this.fetchCategoryList()
      }
    )
  }

  public getSelectedCategories(): ListItem[] {
    return this.selectedCategories;
  }

  private fetchCategoryList() {
    this.categoryService.getMinifiedCategoryList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            const response = data.data.results;
            const filteredResponse = response.filter((item) => this.selectedCategories.map((selectedItem) => selectedItem.id).indexOf(item.id) < 0)
            this.categories = filteredResponse
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  remove(category: ListItem): void {
    const index = this.selectedCategories.indexOf(category);
    if (index >= 0) {
      this.selectedCategories.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedCategories?.push(event.option.value);
    this.resetCategoryInput()
  }

  private resetCategoryInput() {
    if (this.categoryInput) {
      this.categoryInput.nativeElement.value = '';
      this.categoryCtrl.setValue(null);
    }
    this.fetchCategoryList()
  }


  private getParams() {
    let params: any = {}
    if (this.categoryInput && this.categoryInput.nativeElement.value) {
      params["search"] = this.categoryInput.nativeElement.value;
    }
    return params;
  }
}

<mat-card class="component-container-card">
    <mat-form-field class="minimal-mat-form-field" appearance="fill">
      <mat-label>Search Query</mat-label>
      <input matInput  [(ngModel)]=categorySearchText/>
    </mat-form-field>
    <div class="filter-button-container">
      <button mat-mini-fab color="primary" (click)="onFilterSubmit()">
          <mat-icon>check</mat-icon>
      </button>
      <button mat-mini-fab color="warn" (click)="onFilterClear()">
          <mat-icon>clear</mat-icon>
      </button>
    </div>
    <button class="add-button" mat-mini-fab (click)="onAddClick()">
      <mat-icon>add</mat-icon>
  </button>
</mat-card>
<table mat-table [dataSource]="dataSource" class="mat=elevation-z8 full-width-table">
  <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{element.name}}</td>
  </ng-container>
  <ng-container matColumnDef="rank">
      <th mat-header-cell *matHeaderCellDef>Rank</th>
      <td mat-cell *matCellDef="let element">{{element.rank}}</td>
  </ng-container>
  <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Delete</th>
      <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <button mat-icon-button (click)="deleteAfterConfirmation(element.id)">
           <mat-icon>delete</mat-icon>
          </button>
      </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row.id)"></tr>
  <tr class = "mat-row mat-no-data" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No result found
    </td>
</tr>
</table>
<mat-paginator #paginator [length]="totalItemsCount" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 50, 100]" (page)="pageChanged($event)">
</mat-paginator>
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Coupon } from 'src/app/models/interfaces/coupon';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { CouponType } from 'src/app/models/internal/coupon-type';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { CouponTypes } from 'src/app/models/objects/coupon-types';
import { SubscriptionTypes } from 'src/app/models/objects/subscription-types';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import { Utilities } from 'src/app/utilities/utilities';
import { Subscription } from 'src/app/models/internal/subscription';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { UserSelectorDialogComponent } from '../../dialogs/user-selector-dialog/user-selector-dialog.component';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.component.html',
  styleUrls: ['./coupon-details.component.scss']
})
export class CouponDetailsComponent implements OnInit {

  couponId!: string

  availableCouponTypes!: CouponType[]
  availableUserSubscriptionTypes!: Subscription[]

  selectedType?: CouponType

  selectedUserId?: number
  selectedBrandId?: number
  selectedProductId?: number
  selectedCategoryId?: number
  selectedSubcategoryId?: number
  selectedUserSubscriptionId?: number

  couponDetails?: Coupon
  @Input() couponForm!: FormGroup;

  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private couponService: CouponService,
    private route: ActivatedRoute,
    private utilities: Utilities) {
    this.availableCouponTypes = CouponTypes
    this.availableUserSubscriptionTypes = SubscriptionTypes

    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.couponId = inputId
    }
    this.callCouponDetails()
  }

  ngOnInit(): void {
    this.couponForm = this.formBuilder.group({
      couponName: ['', Validators.required],
      couponCode: ['', Validators.required],
      couponEndDate: ['', Validators.required],
      couponDiscountPercentage: ['', [Validators.required, Validators.max(100)]],
      couponMinCartValue: ['',],
      couponMaxLimit: ['',],
      couponMaxUsageLimit: ['',],
      couponType: ['', Validators.required],
      couponUser: [''],
      couponBrand: [''],
      couponProduct: [''],
      couponCategory: [''],
      couponSubcategory: [''],
      couponUserSubscripion: [''],
    });
  }

  get showUserSpecific() { return this.f.couponType.value && this.f.couponType.value == "user_specific" }

  get showUserPlan() { return this.f.couponType.value && this.f.couponType.value == "plan_based" }

  get showBrand() { return this.f.couponType.value && this.f.couponType.value == "combined" }

  get showProduct() { return this.f.couponType.value && (this.f.couponType.value == "product_specific") }

  get showCategory() { return this.f.couponType.value && this.f.couponType.value == "combined" }

  get showSubcategory() { return this.f.couponType.value && this.f.couponType.value == "combined" }


  openDialog(type: string) {

    if (type == "user") {
      const dialogRef = this.dialog.open(UserSelectorDialogComponent, {
        width: '40%',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result != undefined && result != "") {
          this.updateSelectedItem(type, result)
        }
      })
    } else {
      const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
        width: '40%',
        data: { value: type } as ItemSelectorDialogModel
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result != undefined && result != "") {
          this.updateSelectedItem(type, result)
        }
      })
    }
    this.f.couponUser.markAsUntouched()
  }

  get f() { return this.couponForm.controls; }

  updateSelectedItem(type: string, value: ListItem) {

    switch (type) {
      case "brand": {
        this.f.couponBrand.setValue(value.name)
        this.selectedBrandId = value.id
        break;
      }
      case "category": {
        this.f.couponCategory.setValue(value.name)
        this.selectedCategoryId = value.id
        break;
      }
      case "sub-category": {
        this.f.couponSubcategory.setValue(value.name)
        this.selectedSubcategoryId = value.id
        break;
      }
      case "product": {
        this.f.couponProduct.setValue(value.name)
        this.selectedProductId = value.id
        break;
      }
      case "user": {
        this.f.couponUser.setValue(value.name)
        this.selectedUserId = value.id
        break;
      }
    }
  }


  resetSelectedItem() {
    this.selectedBrandId = undefined;
    this.selectedCategoryId = undefined;
    this.selectedSubcategoryId = undefined;
    this.selectedProductId = undefined;
    this.selectedUserId = undefined
    this.selectedUserSubscriptionId = undefined;
    this.resetUpdatedValues(false)
    this.updateValidators()
  }

  resetUpdatedValues(resetToDefault: boolean) {
    if (this.couponDetails) {
      if (resetToDefault) {
        this.couponForm.reset()
        this.f.couponType.setValue(this.couponDetails.type)
      }
      this.f.couponName.setValue(this.couponDetails.name)
      this.f.couponCode.setValue(this.couponDetails.code)
      this.f.couponEndDate.setValue(this.utilities.getDateFromStringDDMMYYYY(this.couponDetails.end_date))
      this.f.couponDiscountPercentage.setValue(this.couponDetails.discount_percentage)
      this.f.couponMaxLimit.setValue(this.couponDetails.max_value)
      this.f.couponMinCartValue.setValue(this.couponDetails.min_value)
      this.f.couponMaxUsageLimit.setValue(this.couponDetails.usage_limit)
      if (this.couponDetails.brand) {
        this.f.couponBrand.setValue(this.couponDetails.brand.name)
        this.selectedBrandId = this.couponDetails.brand.id
      }
      if (this.couponDetails.category) {
        this.f.couponCategory.setValue(this.couponDetails.category.name)
        this.selectedCategoryId = this.couponDetails.category.id
      }
      if (this.couponDetails.sub_category) {
        this.f.couponSubcategory.setValue(this.couponDetails.sub_category.name)
        this.selectedSubcategoryId = this.couponDetails.sub_category.id
      }
      if (this.couponDetails.product) {
        this.f.couponProduct.setValue(this.couponDetails.product.name)
        this.selectedProductId = this.couponDetails.product.id
      }
      if (this.couponDetails.user) {
        this.f.couponUser.setValue(this.couponDetails.user.name)
        this.selectedUserId = this.couponDetails.user.id
      }
      if (this.couponDetails.plan) {
        let selectedUserSubscription = this.availableUserSubscriptionTypes.find(type => type.value == this.couponDetails?.plan.name)
        this.f.couponUserSubscripion.setValue(selectedUserSubscription?.value)
        this.selectedUserSubscriptionId = this.couponDetails?.plan.id
      }
    }
  }

  submit() {
    this.couponForm.markAllAsTouched()
    if (this.couponForm.invalid == true) {
      return;
    }
    this.couponService.updateCoupon(this.couponId, this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            // this.couponDetails = data.data
            this.callCouponDetails()
            this.resetUpdatedValues(true)
          }
          this.utilities.showSnackbar(data.message)
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset(): void {
    this.resetUpdatedValues(true);
  }

  private callCouponDetails() {
    this.couponService.getCouponDetails(this.couponId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.couponDetails = data.data;
            this.resetUpdatedValues(true);
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  getParams(): any {
    let formData: FormData = new FormData();
    formData.append('name', this.f.couponName.value);
    formData.append('code', this.f.couponCode.value);
    let endDate = this.utilities.getFormattedDateDDMMYYYY(this.f.couponEndDate.value)
    if (endDate) {
      formData.append('end_date', endDate);
    }
    formData.append('type', this.f.couponType.value);
    formData.append('discount_percentage', this.f.couponDiscountPercentage.value);

    formData.append('min_value', this.f.couponMinCartValue.value ?? 0);
    formData.append('max_value', this.f.couponMaxLimit.value ?? 0);
    formData.append('usage_limit', this.f.couponMaxUsageLimit.value ?? 0);

    if (this.selectedUserId) {
      formData.append('user', this.selectedUserId!.toString());
    }
    if (this.selectedBrandId) {
      formData.append("brand", this.selectedBrandId!.toString());
    }
    if (this.selectedProductId) {
      formData.append("product", this.selectedProductId!.toString());
    }
    if (this.selectedCategoryId) {
      formData.append("category", this.selectedCategoryId!.toString());
    }
    if (this.selectedSubcategoryId) {
      formData.append("sub_category", this.selectedSubcategoryId!.toString());
    }
    if (this.f.couponType.value == "plan_based") {
      formData.append("plan", this.f.couponUserSubscripion.value.toString())
    }
    return formData;
  }

  updateValidators() {

    this.f.couponUser.removeValidators(Validators.required)
    this.f.couponProduct.removeValidators(Validators.required)
    this.f.couponUserSubscripion.removeValidators(Validators.required)
    switch (this.f.couponType.value) {
      case "user_specific": {
        this.f.couponUser.setValidators(Validators.required)
        break;
      }
      case "product_specific": {
        this.f.couponProduct.setValidators(Validators.required)
        break;
      }
      case "plan_based": {
        this.f.couponUserSubscripion.setValidators(Validators.required)
        break;
      }
      default: {
        break;
      }
    }
    this.f.couponUser.updateValueAndValidity()
    this.f.couponProduct.updateValueAndValidity()
    this.f.couponUserSubscripion.updateValueAndValidity()
    this.couponForm.markAsUntouched()
  }


}

import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { ItemType } from 'src/app/models/internal/item-type';
import { DialogItemTypes } from 'src/app/models/objects/dialog-item-types';
import { BrandsService } from 'src/app/services/brands/brands.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { StoreService } from 'src/app/services/store/store.service';
import { SubCategoryService } from 'src/app/services/sub-category/sub-category.service';
import { UsersService } from 'src/app/services/users/users.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-item-selector-dialog',
  templateUrl: './item-selector-dialog.component.html',
  styleUrls: ['./item-selector-dialog.component.scss']
})
export class ItemSelectorDialogComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  selectedType?: ItemType
  dataSource = new MatTableDataSource<ListItem>();
  displayedColumns: string[] = ["name"];
  searchText?: string

  parentId?: number

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(public dialogRef: MatDialogRef<ItemSelectorDialogComponent>,
    private productsService: ProductsService,
    private brandsService: BrandsService,
    private categoryService: CategoryService,
    private subCategoryService: SubCategoryService,
    private usersService: UsersService,
    private storeService: StoreService,
    private couponService: CouponService,
    @Inject(MAT_DIALOG_DATA) public itemSelectorDialogModel: ItemSelectorDialogModel,
    private utilities: Utilities) {
    this.selectedType = DialogItemTypes.find(x => x.value == this.itemSelectorDialogModel.value)
    this.parentId = this.itemSelectorDialogModel.filterId;
    this.fetchItemList()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  itemSelected(id: string) {

  }

  applyFilter() {
    this.fetchItemList()
  }

  fetchItemList() {

    switch (this.selectedType?.value) {
      case "brand": {
        this.fetchBrandsList();
        break;
      }
      case "category": {
        this.fetchCategoryList();
        break;
      }
      case "sub-category": {
        this.fetchSubCategoryList();
        break;
      }
      case "product": {
        this.fetchProductsList();
        break;
      }
      case "user": {
        this.fetchUsersList();
        break;
      }
      case "district": {
        this.fetchDistrictList();
        break;
      }
      case "state": {
        this.fetchStateList();
        break;
      }
      case "coupon": {
        break;
      }
    }
  }

  fetchDistrictList() {
    this.storeService.getDistrictList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<ListItem>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  fetchStateList() {
    this.storeService.getStateList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<ListItem>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  fetchUsersList() {
    this.usersService.getMinifiedUserList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<ListItem>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  fetchProductsList() {
    this.productsService.getMinifiedProductsList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<ListItem>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  fetchCategoryList() {
    this.categoryService.getMinifiedCategoryList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<ListItem>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  fetchSubCategoryList() {
    this.subCategoryService.getMinifiedSubCategoryList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<ListItem>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  fetchBrandsList() {
    this.brandsService.getMinifiedBrandList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<ListItem>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.fetchItemList()
    }
  }

  pageChanged(event: PageEvent) {
    this.fetchItemList()
  }

  getParams() {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    if (this.searchText) {
      params["search"] = this.searchText;
    }
    if (this.selectedType?.value == "sub-category" && this.parentId) {
      params["category"] = this.parentId;
    }
    return params;
  }

}

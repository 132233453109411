<div *ngIf="subCategoryDetails">
    <mat-card>
    <mat-card-content>
      <form [formGroup]="subCategoryForm">
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Sub Category Name</mat-label>
          <input matInput placeholder="Sub Category Name" formControlName="subCategoryName" >
          <mat-error *ngIf="f.subCategoryName.hasError('required')">
              Sub Category Name is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Sub Category Rank</mat-label>
          <input matInput type="number" placeholder="Sub Category Rank" formControlName="subCategoryRank" >
          <mat-error *ngIf="f.subCategoryRank.hasError('required')">
              Sub Category Rank is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline"  (click)="openDialog()">
            <mat-label>Category</mat-label>
            <input matInput placeholder="Category" formControlName="category" readonly>
            <mat-error *ngIf="f.category.hasError('required')">
                Category is Required!
            </mat-error>
        </mat-form-field>
      </form>
      <app-image-viewer [imageList]=getImageUrls() [editable]=true [selectedImageName]=fileToUpload?.name (fileInputEvent)="onFileInput($event)"></app-image-viewer>
          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="update()" color="warn">Update</button>
        </mat-card-actions>
    </mat-card>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ImageModule } from 'src/app/models/enums/image-module.enum';
import { FeaturedProduct } from 'src/app/models/interfaces/featured-product';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { ItemType } from 'src/app/models/internal/item-type';
import { ItemTypes } from 'src/app/models/objects/item-types';
import { FeaturedProductsService } from 'src/app/services/featured-products/featured-products.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';

@Component({
  selector: 'app-featured-products-details',
  templateUrl: './featured-products-details.component.html',
  styleUrls: ['./featured-products-details.component.scss']
})
export class FeaturedProductsDetailsComponent implements OnInit {

  availableItemTypes!: ItemType[]

  @Input() featuredProductsForm!: FormGroup;

  featuredProductsId!: string
  featuredProductsDetails?: FeaturedProduct

  selectedBrand?: ListItem
  selectedProduct?: ListItem
  selectedCategory?: ListItem
  selectedSubcategory?: ListItem

  fileToUpload: File | null = null;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private featuredProductsService: FeaturedProductsService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.availableItemTypes = ItemTypes
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.featuredProductsId = inputId
    }
    this.callFeaturedProductsDetails()
  }

  ngOnInit(): void {
    this.featuredProductsForm = this.formBuilder.group({
      featuredProductName: ['', Validators.required],
      featuredProductRank: ['', Validators.required],
      featuredProductContentType: ['', Validators.required],
      featuredProductBrand: [''],
      featuredProductProduct: [''],
      featuredProductCategory: [''],
      featuredProductSubcategory: [''],
    });
  }

  get f() { return this.featuredProductsForm.controls; }

  get showBrand() { return this.f.featuredProductContentType.value && (this.f.featuredProductContentType.value.toLowerCase() == "brand" || this.f.featuredProductContentType.value.toLowerCase() == "combined") }

  get showProduct() { return this.f.featuredProductContentType.value && (this.f.featuredProductContentType.value.toLowerCase() == "product") }

  get showCategory() { return this.f.featuredProductContentType.value && (this.f.featuredProductContentType.value.toLowerCase() == "category" || this.f.featuredProductContentType.value.toLowerCase() == "combined") }

  get showSubcategory() { return this.f.featuredProductContentType.value && (this.f.featuredProductContentType.value.toLowerCase() == "sub-category" || this.f.featuredProductContentType.value.toLowerCase() == "combined") }

  updateSelectedItem(type: string, value: ListItem) {

    switch (type) {
      case "brand": {
        this.f.featuredProductBrand.setValue(value.name)
        this.selectedBrand = value
        break;
      }
      case "category": {
        this.f.featuredProductCategory.setValue(value.name)
        this.selectedCategory = value
        break;
      }
      case "sub-category": {
        this.f.featuredProductSubcategory.setValue(value.name)
        this.selectedSubcategory = value
        break;
      }
      case "product": {
        this.f.featuredProductProduct.setValue(value.name)
        this.selectedProduct = value
        break;
      }
    }
  }

  openDialog(type: string) {

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: { value: type } as ItemSelectorDialogModel
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.updateSelectedItem(type, result)
      }
    })
    this.f.featuredProductBrand.markAsUntouched()
    this.f.featuredProductCategory.markAsUntouched()
    this.f.featuredProductSubcategory.markAsUntouched()
    this.f.featuredProductProduct.markAsUntouched()
  }

  update(): void {
    this.featuredProductsForm.markAllAsTouched()
    if (this.featuredProductsForm.invalid == true) {
      return;
    }
    if (this.featuredProductsDetails?.content_type.toLowerCase() == "combined" && this.selectedBrand == null && this.selectedCategory == null && this.selectedSubcategory == null && this.selectedProduct == null) {
      this.utilities.showSnackbar("Select atleast one item (Brand/Category/Subcategory/Product)")
      return;
    }
    this.featuredProductsService.updateFeaturedProduct(this.featuredProductsId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          // this.featuredProductsDetails = data.data
          this.utilities.showSnackbar(data.message)
          // this.resetUpdatedValues()
          this.callFeaturedProductsDetails()
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset(): void {
    this.resetUpdatedValues();
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  callFeaturedProductsDetails() {
    this.featuredProductsService.getFeaturedProductDetails(this.featuredProductsId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.featuredProductsDetails = data.data
            this.resetUpdatedValues()
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  resetUpdatedValues() {
    if (this.featuredProductsDetails) {
      this.f.featuredProductName.setValue(this.featuredProductsDetails.name)
      this.f.featuredProductRank.setValue(this.featuredProductsDetails.rank)
      this.f.featuredProductContentType.setValue(this.getContentTypeId(this.featuredProductsDetails.content_type))
      this.resetSelectedItem()
    }
    this.fileToUpload = null
  }


  resetSelectedItem(isContentTypeChanged: boolean = false) {
    if (isContentTypeChanged) {
      this.selectedBrand = undefined;
      this.selectedCategory = undefined;
      this.selectedSubcategory = undefined;
      this.selectedProduct = undefined;
      this.f.featuredProductBrand.setValue(undefined)
      this.f.featuredProductCategory.setValue(undefined)
      this.f.featuredProductSubcategory.setValue(undefined)
      this.f.featuredProductProduct.setValue(undefined)
      this.updateValidators()
    } else if (this.featuredProductsDetails) {
      this.setupSelectedItemsFromDetails()
    }
  }

  setupSelectedItemsFromDetails() {
    switch (this.featuredProductsDetails?.content_type.toLowerCase()) {
      case "brand": {
        this.f.featuredProductBrand.setValue(this.featuredProductsDetails.item_details.name)
        this.selectedBrand = { id: this.featuredProductsDetails.item_details.id, name: this.featuredProductsDetails.item_details.name }
        break;
      }
      case "category": {
        this.f.featuredProductCategory.setValue(this.featuredProductsDetails.item_details.name)
        this.selectedCategory = { id: this.featuredProductsDetails.item_details.id, name: this.featuredProductsDetails.item_details.name }
        break;
      }
      case "sub-category": {
        this.f.featuredProductSubcategory.setValue(this.featuredProductsDetails.item_details.name)
        this.selectedSubcategory = { id: this.featuredProductsDetails.item_details.id, name: this.featuredProductsDetails.item_details.name }
        break;
      }
      case "product": {
        this.f.featuredProductProduct.setValue(this.featuredProductsDetails.item_details.name)
        this.selectedProduct = { id: this.featuredProductsDetails.item_details.id, name: this.featuredProductsDetails.item_details.name }
        break;
      }
      case "combined": {
        this.f.featuredProductBrand.setValue(this.featuredProductsDetails.item_details.brand_name)
        this.f.featuredProductCategory.setValue(this.featuredProductsDetails.item_details.category_name)
        this.f.featuredProductSubcategory.setValue(this.featuredProductsDetails.item_details.sub_category_name)
        this.f.featuredProductProduct.setValue(this.featuredProductsDetails.item_details.product_name)
        if (this.featuredProductsDetails.item_details.brand_id) {
          this.selectedBrand = { id: this.featuredProductsDetails.item_details.brand_id, name: this.featuredProductsDetails.item_details.brand_name }
        }
        if (this.featuredProductsDetails.item_details.category_id) {
          this.selectedCategory = { id: this.featuredProductsDetails.item_details.category_id, name: this.featuredProductsDetails.item_details.category_name }
        }
        if (this.featuredProductsDetails.item_details.sub_category_id) {
          this.selectedSubcategory = { id: this.featuredProductsDetails.item_details.sub_category_id, name: this.featuredProductsDetails.item_details.sub_category_name }
        }
        if (this.featuredProductsDetails.item_details.product_id) {
          this.selectedProduct = { id: this.featuredProductsDetails.item_details.product_id, name: this.featuredProductsDetails.item_details.product_name }
        }
        break;
      }
    }
  }

  getContentTypeId(contentType: String) {
    return this.availableItemTypes.find(type => type.viewValue == contentType)?.value
  }

  getImageUrls(): [string] {
    return [this.utilities.getImageUrl(ImageModule.FeaturedProduct, this.featuredProductsDetails!.image)]
  }

  getUserUpdateParams(): any {
    let formData: FormData = new FormData();
    if (this.fileToUpload) {
      formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    }
    formData.append('name', this.f.featuredProductName.value);
    formData.append('rank', this.f.featuredProductRank.value);
    formData.append('content_type', this.f.featuredProductContentType.value);
    if (this.selectedBrand) {
      formData.append("brand", this.selectedBrand!.id.toString());
    }
    if (this.selectedProduct) {
      formData.append("product", this.selectedProduct!.id.toString());
    }
    if (this.selectedCategory) {
      formData.append("category", this.selectedCategory!.id.toString());
    }
    if (this.selectedSubcategory) {
      formData.append("sub_category", this.selectedSubcategory!.id.toString());
    }
    return formData;
  }

  updateValidators() {

    this.f.featuredProductBrand.removeValidators(Validators.required)
    this.f.featuredProductCategory.removeValidators(Validators.required)
    this.f.featuredProductSubcategory.removeValidators(Validators.required)
    this.f.featuredProductProduct.removeValidators(Validators.required)

    switch (this.f.featuredProductContentType.value) {
      case "brand": {
        this.f.featuredProductBrand.setValidators(Validators.required)
        break;
      }
      case "category": {
        this.f.featuredProductCategory.setValidators(Validators.required)
        break;
      }
      case "sub-category": {
        this.f.featuredProductSubcategory.setValidators(Validators.required)
        break;
      }
      case "product": {
        this.f.featuredProductProduct.setValidators(Validators.required)
        break;
      }
      default: {
        break;
      }
    }
    this.f.featuredProductBrand.updateValueAndValidity()
    this.f.featuredProductCategory.updateValueAndValidity()
    this.f.featuredProductSubcategory.updateValueAndValidity()
    this.f.featuredProductProduct.updateValueAndValidity()
    this.featuredProductsForm.markAsUntouched()
  }

}

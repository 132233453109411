import { ItemType } from "../internal/item-type";

export const DialogItemTypes: ItemType[] = [
  { value: 'product', viewValue: 'Product' },
  { value: 'brand', viewValue: 'Brand' },
  { value: 'category', viewValue: 'Category' },
  { value: 'sub-category', viewValue: 'Subcategory' },
  { value: 'combined', viewValue: 'Combined' },
  { value: 'district', viewValue: 'District' },
  { value: 'state', viewValue: 'State' },
];


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/models/response/base-response';
import { ListItemResponse } from 'src/app/models/response/list-item/list-item-response';
import { UserAddressListResponse } from 'src/app/models/response/user/user-address-list/user-address-list-response';
import { UserDetailsResponse } from 'src/app/models/response/user/user-details/user-details-response';
import { UserListResponse } from 'src/app/models/response/user/user-list/user-list-response';
import { Utilities } from 'src/app/utilities/utilities';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private baseAPIService:BaseApiService,private utilities:Utilities) { }

  getAllUsers(params:any): Observable<UserListResponse>{
    return this.baseAPIService.get("/user", params).pipe(map((response: UserListResponse) => {
        return response;
    }))
  }

  getUserDetails(id:string): Observable<UserDetailsResponse>{
    return this.baseAPIService.get("/user/" + id, null).pipe(map((response: UserDetailsResponse) => {
      let startDate = response.data?.subscription_plan.start_date;
      let endDate = response.data?.subscription_plan.end_date;

      if (startDate){
        response.data!.subscription_plan.start_date = this.utilities.getDateFromStringDDMMYYYY(startDate.toString());
      }
      if (endDate){
        response.data!.subscription_plan.end_date = this.utilities.getDateFromStringDDMMYYYY(endDate.toString());
      }
      return response;
    }))
  }

  updateUserSubscription(id: string, data:any){
    return this.baseAPIService.patch("/user/" + id + "/", data).pipe(map((response:BaseResponse) => {
      return response;
    }))
  }

  getMinifiedUserList(params:any):Observable<ListItemResponse>{
    return this.baseAPIService.get("/list-user/", params).pipe(map((response:ListItemResponse) => {
      return response;
    }))
  }

  getAddressList(params:any):Observable<UserAddressListResponse>{
    return this.baseAPIService.get("/list-user-address/", params).pipe(map((response:UserAddressListResponse) => {
      return response;
    }))
  }

}


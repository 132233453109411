import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatCardModule } from '@angular/material/card'
import { MatToolbarModule } from '@angular/material/toolbar'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { PushNotificationComponent } from './components/push-notification/push-notification.component';
import { OrderListComponent } from './components/orders/order-list/order-list.component';
import { OrderDetailsComponent } from './components/orders/order-details/order-details.component';

import { DatePipe } from '@angular/common';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { UserAddressComponent } from './components/user/user-address/user-address.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { ConfirmationDialogComponent } from './components/alert/confirmation-dialog/confirmation-dialog.component';
import { InfoDialogComponent } from './components/alert/info-dialog/info-dialog.component';
import { OrderItemsComponent } from './components/orders/order-items/order-items.component';
import { BrandListComponent } from './components/brand/brand-list/brand-list.component';
import { BrandDetailsComponent } from './components/brand/brand-details/brand-details.component';
import { BrandCreateComponent } from './components/brand/brand-create/brand-create.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { CategoryCreateComponent } from './components/category/category-create/category-create.component';
import { CategoryDetailsComponent } from './components/category/category-details/category-details.component';
import { CategoryListComponent } from './components/category/category-list/category-list.component';
import { ProductListComponent } from './components/products/product-list/product-list.component';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { ProductCreateComponent } from './components/products/product-create/product-create.component';
import { FeaturedProductsListComponent } from './components/featured-products/featured-products-list/featured-products-list.component';
import { FeaturedProductsDetailsComponent } from './components/featured-products/featured-products-details/featured-products-details.component';
import { FeaturedProductsCreateComponent } from './components/featured-products/featured-products-create/featured-products-create.component';
import { NewArrivalsCreateComponent } from './components/new-arrivals/new-arrivals-create/new-arrivals-create.component';
import { NewArrivalsDetailsComponent } from './components/new-arrivals/new-arrivals-details/new-arrivals-details.component';
import { NewArrivalsListComponent } from './components/new-arrivals/new-arrivals-list/new-arrivals-list.component';
import { ItemSelectorDialogComponent } from './components/dialogs/item-selector-dialog/item-selector-dialog.component';
import { CouponListComponent } from './components/coupon/coupon-list/coupon-list.component';
import { CouponDetailsComponent } from './components/coupon/coupon-details/coupon-details.component';
import { CouponCreateComponent } from './components/coupon/coupon-create/coupon-create.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { NavigationComponent } from './navigation/navigation.component';
import { UserSelectorDialogComponent } from './components/dialogs/user-selector-dialog/user-selector-dialog.component';
import { SubCategoryCreateComponent } from './components/sub-category/sub-category-create/sub-category-create.component';
import { SubCategoryDetailsComponent } from './components/sub-category/sub-category-details/sub-category-details.component';
import { SubCategoryListComponent } from './components/sub-category/sub-category-list/sub-category-list.component';
import { BulkUpdateComponent } from './components/bulk-update/bulk-update.component';
import { MultiSelectCategoryComponent } from './components/multi-select/multi-select-category/multi-select-category.component';
import { MultiSelectBrandComponent } from './components/multi-select/multi-select-brand/multi-select-brand.component';
import { MultiSelectSubCategoryComponent } from './components/multi-select/multi-select-sub-category/multi-select-sub-category.component';
import { OrderCreateComponent } from './components/orders/order-create/order-create.component';
import { StoreListComponent } from './components/store/store-list/store-list.component';
import { StoreUpdateCreateComponent } from './components/store/store-update-create/store-update-create.component';
import { AddressSelectorDialogComponent } from './components/dialogs/address-selector-dialog/address-selector-dialog.component';
import { OrderProductListComponent } from './components/orders/order-product-list/order-product-list.component';
import { CouponSelectorDialogComponent } from './components/dialogs/coupon-selector-dialog/coupon-selector-dialog.component';
import { StoreSelectorDialogComponent } from './components/dialogs/store-selector-dialog/store-selector-dialog.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { SellerProductListComponent } from './components/seller/seller-product-list/seller-product-list.component';
import { SellerProductDetailsComponent } from './components/seller/seller-product-details/seller-product-details.component';
import { SellerCreateComponent } from './components/seller/seller-create/seller-create.component';
import { SellerListComponent } from './components/seller/seller-list/seller-list.component';
import { SellerDetailsComponent } from './components/seller/seller-details/seller-details.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationComponent,
    PushNotificationComponent,
    OrderListComponent,
    OrderDetailsComponent,
    UserDetailsComponent,
    UserAddressComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    OrderItemsComponent,
    BrandListComponent,
    BrandDetailsComponent,
    BrandCreateComponent,
    ImageViewerComponent,
    CategoryCreateComponent,
    CategoryDetailsComponent,
    CategoryListComponent,
    ProductListComponent,
    ProductDetailsComponent,
    ProductCreateComponent,
    FeaturedProductsListComponent,
    FeaturedProductsDetailsComponent,
    FeaturedProductsCreateComponent,
    NewArrivalsCreateComponent,
    NewArrivalsDetailsComponent,
    NewArrivalsListComponent,
    ItemSelectorDialogComponent,
    CouponListComponent,
    CouponDetailsComponent,
    CouponCreateComponent,
    UserListComponent,
    UserSelectorDialogComponent,
    SubCategoryCreateComponent,
    SubCategoryDetailsComponent,
    SubCategoryListComponent,
    BulkUpdateComponent,
    MultiSelectCategoryComponent,
    MultiSelectBrandComponent,
    MultiSelectSubCategoryComponent,
    OrderCreateComponent,
    StoreListComponent,
    StoreUpdateCreateComponent,
    AddressSelectorDialogComponent,
    OrderProductListComponent,
    CouponSelectorDialogComponent,
    StoreSelectorDialogComponent,
    AnalyticsComponent,
    SellerProductListComponent,
    SellerProductDetailsComponent,
    SellerCreateComponent,
    SellerListComponent,
    SellerDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatToolbarModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    NgDynamicBreadcrumbModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatRadioModule
  ],
  providers: [DatePipe, { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }

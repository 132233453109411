<mat-card>
  <mat-card-content>
    <form [formGroup]="newArrivalsForm">
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>New Arrivals Name</mat-label>
        <input matInput placeholder="New Arrivals Name" formControlName="newArrivalsName">
        <mat-error *ngIf="f.newArrivalsName.hasError('required')">
          New Arrivals Name is Required!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>New Arrivals Rank</mat-label>
        <input matInput type="number" placeholder="New Arrivals Rank" formControlName="newArrivalsRank">
        <mat-error *ngIf="f.newArrivalsRank.hasError('required')">
          New Arrivals Rank is Required!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline">
        <mat-label>New Arrivals Type</mat-label>
        <mat-select formControlName="newArrivalsContentType" (selectionChange)="resetSelectedItem()">
            <mat-option *ngFor="let contentType of availableItemTypes" [value]="contentType.value">
                {{contentType.viewValue}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="f.newArrivalsContentType.hasError('required')">
          New Arrivals Content Type is Required!
        </mat-error>
    </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('brand')" *ngIf="showBrand">
        <mat-label>New Arrivals Brand</mat-label>
        <input matInput placeholder="Select Brand" formControlName="newArrivalsBrand" readonly>
        <mat-error *ngIf="f.newArrivalsBrand.hasError('required')">
          New Arrivals Brand is Required!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('product')" *ngIf="showProduct">
        <mat-label>New Arrivals Product</mat-label>
        <input matInput placeholder="Select Product" formControlName="newArrivalsProduct" readonly>
        <mat-error *ngIf="f.newArrivalsProduct.hasError('required')">
          New Arrivals Product is Required!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('category')" *ngIf="showCategory">
        <mat-label>New Arrivals Category</mat-label>
        <input matInput placeholder="Select Category" formControlName="newArrivalsCategory" readonly>
        <mat-error *ngIf="f.newArrivalsCategory.hasError('required')">
          New Arrivals Category is Required!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog('sub-category')" *ngIf="showSubcategory">
        <mat-label>New Arrivals Subcategory</mat-label>
        <input matInput placeholder="Select Subcategory" formControlName="newArrivalsSubcategory" readonly>
        <mat-error *ngIf="f.newArrivalsSubcategory.hasError('required')">
          New Arrivals Subcategory is Required!
        </mat-error>
      </mat-form-field>
    </form>

    <button mat-raised-button (click)="fileInput.click()">
      <mat-icon>library_add</mat-icon>
      <span>{{fileToUpload?.name || " Select"}}</span>
      <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
    </button>
  </mat-card-content>
  <mat-card-actions class="update-mat-card-actions">
    <button mat-raised-button (click)="reset()" color="basic">Reset</button>
    <button mat-raised-button (click)="submit()" color="warn">Submit</button>
  </mat-card-actions>
</mat-card>
<div *ngIf="brandDetails">
    <mat-card>
    <mat-card-content>
      <form [formGroup]="brandForm">
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Brand Name</mat-label>
          <input matInput placeholder="Brand Name" formControlName="brandName" >
          <mat-error *ngIf="f.brandName.hasError('required')">
              Brand Name is Required!
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Brand Rank</mat-label>
          <input matInput type="number" placeholder="Brand Rank" formControlName="brandRank" >
          <mat-error *ngIf="f.brandRank.hasError('required')">
              Brand Rank is Required!
          </mat-error>
        </mat-form-field>
      </form>
      <app-image-viewer [imageList]=getBrandImageUrls() [editable]=true [selectedImageName]=fileToUpload?.name (fileInputEvent)="onFileInput($event)"></app-image-viewer>
          </mat-card-content>
          <mat-card-actions class="update-mat-card-actions">
            <button mat-raised-button (click)="reset()" color="basic">Reset</button>
            <button mat-raised-button (click)="update()" color="warn">Update</button>
        </mat-card-actions>
    </mat-card>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service'
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Utilities } from 'src/app/utilities/utilities';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() loginForm!: FormGroup;
  loginInvalid = false;

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private utilities: Utilities) {

  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  login(): void {
    this.loginForm.markAllAsTouched()
    if (this.loginForm.invalid == true) {
      return;
    }
    this.authService.loginUser(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.status == 1) {
            setTimeout(() => {
              //temp fix for breadcrumb loading
              this.router.navigate([""]);
            },
              50);
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.loginInvalid = true
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ImageModule } from 'src/app/models/enums/image-module.enum';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { NewArrival } from 'src/app/models/interfaces/new-arrival';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { ItemType } from 'src/app/models/internal/item-type';
import { ItemTypes } from 'src/app/models/objects/item-types';
import { NewArrivalsService } from 'src/app/services/new-arrivals/new-arrivals.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';

@Component({
  selector: 'app-new-arrivals-details',
  templateUrl: './new-arrivals-details.component.html',
  styleUrls: ['./new-arrivals-details.component.scss']
})
export class NewArrivalsDetailsComponent implements OnInit {

  availableItemTypes!: ItemType[]

  @Input() newArrivalsForm!: FormGroup;

  newArrivalsId!: string
  newArrivalsDetails?: NewArrival

  selectedBrand?: ListItem
  selectedProduct?: ListItem
  selectedCategory?: ListItem
  selectedSubcategory?: ListItem

  fileToUpload: File | null = null;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private newArrivalsService: NewArrivalsService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.availableItemTypes = ItemTypes
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.newArrivalsId = inputId
    }
    this.callnewArrivalsDetails()
  }

  ngOnInit(): void {
    this.newArrivalsForm = this.formBuilder.group({
      newArrivalsName: ['', Validators.required],
      newArrivalsRank: ['', Validators.required],
      newArrivalsContentType: ['', Validators.required],
      newArrivalsBrand: [''],
      newArrivalsProduct: [''],
      newArrivalsCategory: [''],
      newArrivalsSubcategory: [''],
    });
  }

  get f() { return this.newArrivalsForm.controls; }

  get showBrand() { return this.f.newArrivalsContentType.value && (this.f.newArrivalsContentType.value.toLowerCase() == "brand" || this.f.newArrivalsContentType.value.toLowerCase() == "combined") }

  get showProduct() { return this.f.newArrivalsContentType.value && (this.f.newArrivalsContentType.value.toLowerCase() == "product") }

  get showCategory() { return this.f.newArrivalsContentType.value && (this.f.newArrivalsContentType.value.toLowerCase() == "category" || this.f.newArrivalsContentType.value.toLowerCase() == "combined") }

  get showSubcategory() { return this.f.newArrivalsContentType.value && (this.f.newArrivalsContentType.value.toLowerCase() == "sub-category" || this.f.newArrivalsContentType.value.toLowerCase() == "combined") }

  updateSelectedItem(type: string, value: ListItem) {

    switch (type) {
      case "brand": {
        this.f.newArrivalsBrand.setValue(value.name)
        this.selectedBrand = value
        break;
      }
      case "category": {
        this.f.newArrivalsCategory.setValue(value.name)
        this.selectedCategory = value
        break;
      }
      case "sub-category": {
        this.f.newArrivalsSubcategory.setValue(value.name)
        this.selectedSubcategory = value
        break;
      }
      case "product": {
        this.f.newArrivalsProduct.setValue(value.name)
        this.selectedProduct = value
        break;
      }
    }
  }

  openDialog(type: string) {

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: { value: this.f.newArrivalsContentType.value } as ItemSelectorDialogModel


    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.updateSelectedItem(type, result)
      }
    })
    this.f.newArrivalsBrand.markAsUntouched()
    this.f.newArrivalsCategory.markAsUntouched()
    this.f.newArrivalsSubcategory.markAsUntouched()
    this.f.newArrivalsProduct.markAsUntouched()
  }

  update(): void {
    this.newArrivalsForm.markAllAsTouched()
    if (this.newArrivalsForm.invalid == true) {
      return;
    }
    this.newArrivalsService.updateNewArrivals(this.newArrivalsId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          this.callnewArrivalsDetails()
          // this.newArrivalsDetails = data.data
          this.utilities.showSnackbar(data.message)
          // this.resetUpdatedValues()
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset(): void {
    this.resetUpdatedValues();
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  callnewArrivalsDetails() {
    this.newArrivalsService.getNewArrivalsDetails(this.newArrivalsId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.newArrivalsDetails = data.data
            this.resetUpdatedValues()
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  resetUpdatedValues() {
    if (this.newArrivalsDetails) {
      this.f.newArrivalsName.setValue(this.newArrivalsDetails.name)
      this.f.newArrivalsRank.setValue(this.newArrivalsDetails.rank)
      this.f.newArrivalsContentType.setValue(this.getContentTypeId(this.newArrivalsDetails.content_type))
      this.resetSelectedItem()
    }
    this.fileToUpload = null
  }


  resetSelectedItem(isContentTypeChanged: boolean = false) {
    if (isContentTypeChanged) {
      this.selectedBrand = undefined;
      this.selectedCategory = undefined;
      this.selectedSubcategory = undefined;
      this.selectedProduct = undefined;
      this.f.newArrivalsBrand.setValue(undefined)
      this.f.newArrivalsCategory.setValue(undefined)
      this.f.newArrivalsSubcategory.setValue(undefined)
      this.f.newArrivalsProduct.setValue(undefined)
    }
    else if (this.newArrivalsDetails) {
      this.setupSelectedItemsFromDetails()
    }
  }

  setupSelectedItemsFromDetails() {
    switch (this.newArrivalsDetails?.content_type.toLowerCase()) {
      case "brand": {
        this.f.newArrivalsBrand.setValue(this.newArrivalsDetails.item_details.name)
        this.selectedBrand = { id: this.newArrivalsDetails.item_details.id, name: this.newArrivalsDetails.item_details.name }
        break;
      }
      case "category": {
        this.f.newArrivalsCategory.setValue(this.newArrivalsDetails.item_details.name)
        this.selectedCategory = { id: this.newArrivalsDetails.item_details.id, name: this.newArrivalsDetails.item_details.name }
        break;
      }
      case "sub-category": {
        this.f.newArrivalsubcategory.setValue(this.newArrivalsDetails.item_details.name)
        this.selectedSubcategory = { id: this.newArrivalsDetails.item_details.id, name: this.newArrivalsDetails.item_details.name }
        break;
      }
      case "product": {
        this.f.newArrivalsProduct.setValue(this.newArrivalsDetails.item_details.name)
        this.selectedProduct = { id: this.newArrivalsDetails.item_details.id, name: this.newArrivalsDetails.item_details.name }
        break;
      }
      case "combined": {
        this.f.newArrivalsBrand.setValue(this.newArrivalsDetails.item_details.brand_name)
        this.f.newArrivalsCategory.setValue(this.newArrivalsDetails.item_details.category_name)
        this.f.newArrivalsubcategory.setValue(this.newArrivalsDetails.item_details.sub_category_name)
        this.f.newArrivalsProduct.setValue(this.newArrivalsDetails.item_details.product_name)
        if (this.newArrivalsDetails.item_details.brand_id) {
          this.selectedBrand = { id: this.newArrivalsDetails.item_details.brand_id, name: this.newArrivalsDetails.item_details.brand_name }
        }
        if (this.newArrivalsDetails.item_details.category_id) {
          this.selectedCategory = { id: this.newArrivalsDetails.item_details.category_id, name: this.newArrivalsDetails.item_details.category_name }
        }
        if (this.newArrivalsDetails.item_details.sub_category_id) {
          this.selectedSubcategory = { id: this.newArrivalsDetails.item_details.sub_category_id, name: this.newArrivalsDetails.item_details.sub_category_name }
        }
        if (this.newArrivalsDetails.item_details.product_id) {
          this.selectedProduct = { id: this.newArrivalsDetails.item_details.product_id, name: this.newArrivalsDetails.item_details.product_name }
        }
        break;
      }
    }
  }

  getContentTypeId(contentType: String) {
    return this.availableItemTypes.find(type => type.viewValue == contentType)?.value
  }

  getImageUrls(): [string] {
    return [this.utilities.getImageUrl(ImageModule.NewArrival, this.newArrivalsDetails!.image)]
  }

  getUserUpdateParams(): any {
    let formData: FormData = new FormData();
    if (this.fileToUpload) {
      formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    }
    formData.append('name', this.f.newArrivalsName.value);
    formData.append('rank', this.f.newArrivalsRank.value);
    formData.append('content_type', this.f.newArrivalsContentType.value);
    if (this.selectedBrand) {
      formData.append("brand", this.selectedBrand!.id.toString());
    }
    if (this.selectedProduct) {
      formData.append("product", this.selectedProduct!.id.toString());
    }
    if (this.selectedCategory) {
      formData.append("category", this.selectedCategory!.id.toString());
    }
    if (this.selectedSubcategory) {
      formData.append("sub_category", this.selectedSubcategory!.id.toString());
    }
    return formData;
  }

  updateValidators() {

    this.f.newArrivalsBrand.removeValidators(Validators.required)
    this.f.newArrivalsCategory.removeValidators(Validators.required)
    this.f.newArrivalsSubcategory.removeValidators(Validators.required)
    this.f.newArrivalsProduct.removeValidators(Validators.required)

    switch (this.f.newArrivalsContentType.value) {
      case "brand": {
        this.f.newArrivalsBrand.setValidators(Validators.required)
        break;
      }
      case "category": {
        this.f.newArrivalsCategory.setValidators(Validators.required)
        break;
      }
      case "sub-category": {
        this.f.newArrivalsSubcategory.setValidators(Validators.required)
        break;
      }
      case "product": {
        this.f.newArrivalsProduct.setValidators(Validators.required)
        break;
      }
      default: {
        break;
      }
    }
    this.f.newArrivalsBrand.updateValueAndValidity()
    this.f.newArrivalsCategory.updateValueAndValidity()
    this.f.newArrivalsSubcategory.updateValueAndValidity()
    this.f.newArrivalsProduct.updateValueAndValidity()
    this.newArrivalsForm.markAsUntouched()
  }


}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { Coupon } from 'src/app/models/interfaces/coupon';
import { CouponSelectorDialogModel } from 'src/app/models/internal/coupon-selector-dialog-model';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-coupon-selector-dialog',
  templateUrl: './coupon-selector-dialog.component.html',
  styleUrls: ['./coupon-selector-dialog.component.scss']
})
export class CouponSelectorDialogComponent {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  dataSource = new MatTableDataSource<Coupon>();
  displayedColumns: string[] = ["name", "code", "type", "expiry"];

  productId: number;
  userId: number;
  cartValue: number;

  constructor(public dialogRef: MatDialogRef<CouponSelectorDialogComponent>,
    private couponService: CouponService,
    private utilities: Utilities,
    @Inject(MAT_DIALOG_DATA) public dialogModel: CouponSelectorDialogModel) {
    this.productId = this.dialogModel.productId
    this.cartValue = this.dialogModel.cartValue
    this.userId = this.dialogModel.userId
    this.fetchCouponList()
  }


  fetchCouponList() {
    this.couponService.getApplicableCoupon(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<Coupon>(data.data.results);
            if (data.data?.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.fetchCouponList()
    }
  }

  pageChanged(event: PageEvent) {
    this.fetchCouponList()
  }

  getParams() {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    params["cart_value"] = this.cartValue;
    params["user_id"] = this.userId;
    params["product_id"] = this.productId;
    return params;
  }

}

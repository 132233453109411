import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Brand } from 'src/app/models/interfaces/brand';
import { Category } from 'src/app/models/interfaces/category';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { Product } from 'src/app/models/interfaces/product';
import { ActiveInactiveStatus } from 'src/app/models/internal/active-inactive-status';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { ActiveInactiveStatusTypes } from 'src/app/models/objects/active-inactive-status-types';
import { BrandsService } from 'src/app/services/brands/brands.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ConfirmationDialogComponent } from '../../alert/confirmation-dialog/confirmation-dialog.component';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { MultiSelectBrandComponent } from '../../multi-select/multi-select-brand/multi-select-brand.component';
import { MultiSelectCategoryComponent } from '../../multi-select/multi-select-category/multi-select-category.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  displayedColumns: string[] = ["name", "category", "brand", "status", "delete"];
  dataSource = new MatTableDataSource<Product>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MultiSelectCategoryComponent) categorySelector!: MultiSelectCategoryComponent;
  @ViewChild(MultiSelectBrandComponent) brandSelector!: MultiSelectBrandComponent;

  availableProductStatusTypes!: ActiveInactiveStatus[]
  availableCategories?: Category[]
  availableBrands?: Brand[]

  constructor(private router: Router,
    public productsService: ProductsService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.availableProductStatusTypes = ActiveInactiveStatusTypes
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
    this.setupPaginatorInitialvalues()
    this.getAllProducts()
  }

  setupPaginatorInitialvalues() {
    //TODO:Check for a proper implementation
    setTimeout(() => {
      this.paginator.pageIndex = this.productsService.pageIndex;
      this.paginator.pageSize = this.productsService.pageSize;
    }, 0.5);
  }

  openDialog(type: string) {

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: { value: type } as ItemSelectorDialogModel
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        if (type == "brand") {
          this.productsService.productFilterBrand = result
        } else {
          this.productsService.productFilterCategory = result
        }
      }
    })
  }


  getAllProducts() {
    this.productsService.getAllProducts(this.getQueryParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<Product>(data.data.results);
            if (data.data.results.length == 0 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }


  navigateToDetails(id: string) {
    this.router.navigate(['./products/' + id])
  }

  onAddClick() {
    this.router.navigate(['./products/create'])
  }

  deleteProduct(id: string) {
    this.productsService.deleteProduct(id)
      .pipe(first())
      .subscribe(
        data => {
          this.getAllProducts()
          this.utilities.showSnackbar(data.message)
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  deleteAfterConfirmation(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Do you really want to delete this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteProduct(id);
      }
    });
  }

  OnFilterSubmit() {
    this.productsService.productFilterCategory = this.categorySelector.getSelectedCategories()
    this.productsService.productFilterBrand = this.brandSelector.getSelectedBrands()
    this.getAllProducts()
  }

  OnFilterClear() {
    this.productsService.productFilterStatus = undefined;
    this.productsService.productFilterBrand = undefined;
    this.productsService.productFilterCategory = undefined;
    this.productsService.productSearchText = undefined;
    this.getAllProducts()
    this.paginator.pageIndex = this.productsService.pageIndex
  }

  updatePageIndex() {
    this.productsService.pageIndex = this.paginator?.pageIndex
    this.productsService.pageSize = this.paginator.pageSize
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.updatePageIndex()
      this.getAllProducts()
    }
  }

  pageChanged(event: PageEvent) {
    this.updatePageIndex()
    this.getAllProducts()
  }

  private getQueryParams(): any {
    let params: any = {}
    params["limit"] = this.productsService.pageSize;
    params["offset"] = this.productsService.pageIndex * params["limit"];
    if (this.productsService.productSearchText) {
      params["search"] = this.productsService.productSearchText;
    }
    if (this.productsService.productFilterStatus != null && this.productsService.productFilterStatus != undefined) {
      params["status"] = this.productsService.productFilterStatus
    }
    if (this.productsService.productFilterBrand) {
      params["brand"] = this.productsService.productFilterBrand.map(item => item.id)
    }
    if (this.productsService.productFilterCategory) {
      params["category"] = this.productsService.productFilterCategory.map(item => item.id)
    }
    return params;
  }


}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {

  constructor(public loaderService: LoaderService, public authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);

    const user = this.authService.currentUserValue?.token;
    const isLoggedIn = user != undefined;
    if (isLoggedIn) {
      req = req.clone({
        setHeaders: {
          Authorization: `JWT ${user}`
        }
      });
    }

    return next.handle(req).pipe(catchError(err => {
      if (err.status === 401) {
        this.authService.logout()
      }
      return throwError(err);
    }),
      finalize(
        () => {
          this.loaderService.isLoading.next(false);
        }
      )
    );
  }
}

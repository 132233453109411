import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { SubCategoryService } from 'src/app/services/sub-category/sub-category.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-multi-select-sub-category',
  templateUrl: './multi-select-sub-category.component.html',
  styleUrls: ['./multi-select-sub-category.component.scss']
})
export class MultiSelectSubCategoryComponent implements OnInit {

  subCategoryCtrl = new FormControl();

  subCategories: ListItem[] = []
  @Input() selectedSubCategories: ListItem[] = []

  @ViewChild('subCategoryInput') subCategoryInput?: ElementRef<HTMLInputElement>;

  constructor(
    private subCategoryService: SubCategoryService,
    private utilities: Utilities,
  ) { }

  ngOnInit(): void {
    this.fetchSubcategoryList()
    this.subCategoryCtrl.valueChanges.subscribe(
      (_) => {
        this.fetchSubcategoryList()
      }
    )
  }

  public getSelectedSubCategory(): ListItem[] {
    return this.selectedSubCategories;
  }

  private fetchSubcategoryList() {
    this.subCategoryService.getMinifiedSubCategoryList(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            const response = data.data.results;
            const filteredResponse = response.filter((item) => this.selectedSubCategories.map((selectedItem) => selectedItem.id).indexOf(item.id) < 0)
            this.subCategories = filteredResponse
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  remove(subCategory: ListItem): void {
    const index = this.selectedSubCategories.indexOf(subCategory);
    if (index >= 0) {
      this.selectedSubCategories.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedSubCategories?.push(event.option.value);
    this.resetSubcategoryInput()
  }

  private resetSubcategoryInput() {
    if (this.subCategoryInput) {
      this.subCategoryInput.nativeElement.value = '';
      this.subCategoryCtrl.setValue(null);
    }
    this.fetchSubcategoryList()
  }


  private getParams() {
    let params: any = {}
    if (this.subCategoryInput && this.subCategoryInput.nativeElement.value) {
      params["search"] = this.subCategoryInput.nativeElement.value;
    }
    return params;
  }

}

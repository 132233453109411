import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ImageModule } from 'src/app/models/enums/image-module.enum';
import { Brand } from 'src/app/models/interfaces/brand';
import { BrandsService } from 'src/app/services/brands/brands.service';
import { Utilities } from 'src/app/utilities/utilities';

@Component({
  selector: 'app-brand-details',
  templateUrl: './brand-details.component.html',
  styleUrls: ['./brand-details.component.scss']
})
export class BrandDetailsComponent implements OnInit {

  brandId!: string
  brandDetails?: Brand

  @Input() brandForm!: FormGroup;

  fileToUpload: File | null = null;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private brandsService: BrandsService,
    private utilities: Utilities) {
    let inputId = route.snapshot.paramMap.get("id")
    if (inputId) {
      this.brandId = inputId
    }
    this.callBrandDetails()
  }

  ngOnInit(): void {
    this.brandForm = this.formBuilder.group({
      brandName: ['', Validators.required],
      brandRank: ['', Validators.required]
    });
  }

  get f() { return this.brandForm.controls; }

  update(): void {
    this.brandForm.markAllAsTouched()
    if (this.brandForm.invalid == true) {
      return;
    }
    this.brandsService.updateBrand(this.brandId, this.getUserUpdateParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.brandDetails = data.data
            this.resetUpdatedValues()
          }
          this.utilities.showSnackbar(data.message)
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset(): void {
    this.resetUpdatedValues();
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  getBrandImageUrls(): [string] {
    //Should call only from html component with brand details check
    return [this.utilities.getImageUrl(ImageModule.Brand, this.brandDetails!.image)]
  }

  private callBrandDetails() {
    this.brandsService.getBrandDetails(this.brandId)
      .pipe(first())
      .subscribe(
        data => {
          if (data.data) {
            this.brandDetails = data.data;
            this.resetUpdatedValues();
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  private getUserUpdateParams(): any {

    let formData: FormData = new FormData();
    if (this.fileToUpload) {
      formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    }
    formData.append('name', this.f.brandName.value);
    formData.append('rank', this.f.brandRank.value);
    return formData;
  }

  private resetUpdatedValues() {
    if (this.brandDetails) {
      this.f.brandName.setValue(this.brandDetails.name)
      this.f.brandRank.setValue(this.brandDetails.rank)
    }
    this.fileToUpload = null
  }

}

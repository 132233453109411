import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { BrandsService } from 'src/app/services/brands/brands.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { Location } from '@angular/common';
import { Utilities } from 'src/app/utilities/utilities';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit {

  @Input() productForm!: FormGroup;

  fileToUpload: File | null = null;
  availableCategories?: ListItem[];
  availableBrands?: ListItem[];

  selectedBrand?: ListItem
  selectedCategory?: ListItem
  selectedSubCategory?: ListItem

  constructor(private formBuilder: FormBuilder,
    private productsService: ProductsService,
    private location: Location,
    private utilities: Utilities,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.productForm = this.formBuilder.group({
      productName: ['', Validators.required],
      productCode: '',
      productBrand: ['', Validators.required],
      productCategory: ['', Validators.required],
      productSubCategory: '',
      maxRetailPrice: ['', Validators.required],
      purchaseRate: ['', Validators.required],
      freeUserPricePercentage: ['', Validators.required],
      freeUserPrice: '',
      premiumOneUserPricePercentage: ['', Validators.required],
      premiumOneUserPrice: '',
      premiumTwoUserPricePercentage: ['', Validators.required],
      premiumTwoUserPrice: '',
      gst: '',
      shippingCharge: '',
      productDetails: ['', Validators.required],
      productSpecifications: ['', Validators.required],
      discountPercentage: '',
      isOutOfStock: '',
      isFinishingSoon: ''
    });
  }

  get f() { return this.productForm.controls; }

  updatePrice() {
    this.f.freeUserPrice.setValue(Math.round(((this.f.freeUserPricePercentage.value + 100) / 100) * this.f.purchaseRate.value))
    this.f.premiumOneUserPrice.setValue(Math.round(((this.f.premiumOneUserPricePercentage.value + 100) / 100) * this.f.purchaseRate.value))
    this.f.premiumTwoUserPrice.setValue(Math.round(((this.f.premiumTwoUserPricePercentage.value + 100) / 100) * this.f.purchaseRate.value))
  }

  openDialog(type: string) {
    let itemSelectorDialogModel = { value: type } as ItemSelectorDialogModel
    if (type == "sub-category") {
      itemSelectorDialogModel.filterId = this.selectedCategory?.id
    }

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: itemSelectorDialogModel

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        if (type == "brand") {
          this.f.productBrand.setValue(result.name)
          this.selectedBrand = result
        } else if (type == "sub-category") {
          this.f.productSubCategory.setValue(result.name)
          this.selectedSubCategory = result
        } else {
          this.f.productCategory.setValue(result.name)
          this.selectedCategory = result
          this.resetSubCategory()
        }
      }
    })
    if (type == "brand") {
      this.f.productBrand.markAsUntouched()
    } else if (type == "sub-category") {
      this.f.productSubCategory.markAsUntouched()
    } else if (type == "category") {
      this.f.productCategory.markAsUntouched()
    }
  }


  submit() {
    this.productForm.markAllAsTouched()
    if (this.productForm.invalid == true) {
      return;
    }
    if (this.fileToUpload == null) {
      this.utilities.showSnackbar("Add image to upload")
      return
    }
    this.productsService.createProduct(this.getProductCreateParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.status == 1) {
            this.location.back()
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  reset() {
    this.fileToUpload = null
    this.productForm.reset()
  }

  resetSubCategory() {
    this.f.productSubCategory.setValue(undefined)
    this.selectedSubCategory = undefined
  }

  onFileInput(event: any) {
    if (event.target?.files?.length > 0) {
      this.fileToUpload = event.target?.files[0];
    }
  }

  getProductCreateParams(): any {
    let formData: FormData = new FormData();

    formData.append('image', this.fileToUpload!, this.fileToUpload!.name);
    formData.append('name', this.f.productName.value);
    formData.append('code', this.f.productCode.value);
    if (this.selectedBrand) {
      formData.append('brand', this.selectedBrand.id.toString());
    }
    if (this.selectedCategory) {
      formData.append('category', this.selectedCategory.id.toString());
    }
    if (this.selectedSubCategory) {
      formData.append('sub_category', this.selectedSubCategory.id.toString());
    }
    formData.append('details', this.f.productDetails.value);
    formData.append('purchase_rate', this.f.purchaseRate.value);
    formData.append('free_price_percentage', this.f.freeUserPricePercentage.value);
    formData.append('free_user_price', this.f.freeUserPrice.value);
    formData.append('max_retail_price', this.f.maxRetailPrice.value);
    formData.append('premium_one_price_percentage', this.f.premiumOneUserPricePercentage.value);
    formData.append('premium_one_user_price', this.f.premiumOneUserPrice.value);
    formData.append('premium_two_price_percentage', this.f.premiumTwoUserPricePercentage.value);
    formData.append('premium_two_user_price', this.f.premiumTwoUserPrice.value);
    formData.append('gst', this.f.gst.value)
    formData.append('shipping_charge', this.f.shippingCharge.value)
    formData.append('discount_percentage', this.f.discountPercentage.value);
    formData.append('specifications', this.f.productSpecifications.value);
    formData.append('is_out_of_stock', this.f.isOutOfStock.value);
    formData.append('is_finishing_soon', this.f.isFinishingSoon.value);

    return formData;
  }

}

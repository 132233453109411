import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Coupon } from 'src/app/models/interfaces/coupon';
import { ListItem } from 'src/app/models/interfaces/list-item';
import { OrderProductItem } from 'src/app/models/interfaces/order-product-item';
import { CouponSelectorDialogModel } from 'src/app/models/internal/coupon-selector-dialog-model';
import { ItemSelectorDialogModel } from 'src/app/models/internal/item-selector-dialog-model';
import { Utilities } from 'src/app/utilities/utilities';
import { CouponSelectorDialogComponent } from '../../dialogs/coupon-selector-dialog/coupon-selector-dialog.component';
import { ItemSelectorDialogComponent } from '../../dialogs/item-selector-dialog/item-selector-dialog.component';
import { EventEmitter } from '@angular/core';
import { OrderValueResponseData } from 'src/app/models/response/orders/order-value/order-value-response-data';

@Component({
  selector: 'app-order-product-list',
  templateUrl: './order-product-list.component.html',
  styleUrls: ['./order-product-list.component.scss']
})
export class OrderProductListComponent implements OnInit {

dataSource = new MatTableDataSource<OrderProductItem>();

 @Input() orderValue?: OrderValueResponseData;
 @Input() userId?: number;

  @Output() cartUpdateEvent: EventEmitter<any> = new EventEmitter();


  displayedColumns: string[] = ["id", "product_name", "quantity", "coupon", "delete"];

  constructor(private dialog: MatDialog,private utilities: Utilities) {
  }

  ngOnInit(): void {
    
  }

  openDialog(type: string) {

    const dialogRef = this.dialog.open(ItemSelectorDialogComponent, {
      width: '40%',
      data: { value: type } as ItemSelectorDialogModel

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        this.productAdded(result)
      }
    })
  }

  openCouponDialog(productId: number) {
    const dialogRef = this.dialog.open(CouponSelectorDialogComponent, {
      width: '40%',
      data: { cartValue: this.orderValue?.total_cost, productId:  productId, userId: this.userId} as CouponSelectorDialogModel
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != undefined && result != "") {
        var itemList = this.dataSource.data;
        const filteredItem = itemList.filter(item => item.product.id == productId);
        if (filteredItem.length > 0) {
          filteredItem[0].coupon = result as Coupon
          // may need the array update
        }
        this.updateDataSource(itemList)
      }
    })
  }

  productAdded(product: ListItem) {
    var itemList = this.dataSource.data;
    const filteredItem = itemList.filter(item => item.product.id == product.id);
    if (filteredItem.length > 0) {
      this.utilities.showSnackbar("Item already added")
      return
    }
    const productItem:OrderProductItem  = {product: product, quantity: 1, coupon: undefined};
    itemList.push(productItem)
    this.updateDataSource(itemList)
  }

  deleteAfterConfirmation(index: number){
    var itemList = this.dataSource.data;
    itemList.splice(index, 1);
    this.updateDataSource(itemList)
  }

  removeCoupon(index: number) {
    var itemList = this.dataSource.data;
    itemList[index].coupon = undefined;
    this.updateDataSource(itemList)
  }

  updateDataSource(itemList: OrderProductItem[]) {
    this.dataSource.data = itemList
    this.refreshCart();
  }

  refreshCart(){
      this.cartUpdateEvent.emit(this.dataSource.data)
  }

  onAddClick(): void {
    this.openDialog('product')
  }

  showCoupon(product: ListItem): void {
    this.openCouponDialog(product.id);
  }
}

<mat-card>
    <mat-form-field class="full-width-field" appearance="outline" (click)="openDialog()">
        <mat-label>Select User</mat-label>
        <input matInput placeholder="User" [value]="selectedUser?.name" readonly>
    </mat-form-field>
    <mat-card-content *ngIf="analyticsResponseData">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef> Top Brands </th>
                <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> Top Categories </th>
                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="subcategory">
                <th mat-header-cell *matHeaderCellDef> Top Sub Categories </th>
                <td mat-cell *matCellDef="let element"> {{element.subcategory}} </td>
            </ng-container>

            <ng-container matColumnDef="search">
                <th mat-header-cell *matHeaderCellDef> Top Search </th>
                <td mat-cell *matCellDef="let element"> {{element.search}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
</mat-card>
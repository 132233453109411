import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Coupon } from 'src/app/models/interfaces/coupon';
import { CouponType } from 'src/app/models/internal/coupon-type';
import { CouponTypes } from 'src/app/models/objects/coupon-types';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import { Utilities } from 'src/app/utilities/utilities';
import { ConfirmationDialogComponent } from '../../alert/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss']
})
export class CouponListComponent implements AfterViewInit {

  totalItemsCount: number = 0;
  defaultPageSize: number = 20;

  dataSource = new MatTableDataSource<Coupon>();
  displayedColumns: string[] = ["name", "type", "endDate", "delete"];
  availableCouponTypes!: CouponType[]

  couponSearchText?: string
  couponFilterType?: string
  couponFilterStartDate?: Date
  couponFilterEndDate?: Date

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private router: Router,
    private couponService: CouponService,
    private utilities: Utilities,
    private dialog: MatDialog) {
    this.availableCouponTypes = CouponTypes
    this.getCoupons()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  onAddClick() {
    this.router.navigate(['./coupons/create'])
  }

  onFilterSubmit() {
    this.getCoupons()
  }

  onFilterClear() {
    this.couponSearchText = undefined;
    this.couponFilterType = undefined;
    this.getCoupons()
  }

  deleteCoupon(id: string) {

    this.couponService.deleteCoupon(id)
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            this.utilities.showSnackbar(data.message)
            this.getCoupons();
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  deleteAfterConfirmation(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Do you really want to delete this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteCoupon(id);
      }
    });
  }

  navigateToDetails(id: string) {
    this.router.navigate(['./coupons/' + id])
  }

  getCoupons() {
    this.couponService.getAllCoupon(this.getParams())
      .pipe(first())
      .subscribe(
        data => {
          if (data.data?.results) {
            this.totalItemsCount = data.data.count
            this.dataSource = new MatTableDataSource<Coupon>(data.data.results);
            if (data.data.results.length < 1 && this.totalItemsCount > 0) {
              this.showPreviousPage()
            }
          } else {
            this.utilities.showSnackbar(data.message)
          }
        },
        error => {
          this.utilities.showSnackbar(error.error.message ?? "Something went wrong")
        }
      )
  }

  pageChanged(event: PageEvent) {
    this.getCoupons()
  }

  showPreviousPage() {
    if (this.paginator.pageIndex > 0) {
      this.paginator.pageIndex -= 1
      this.getCoupons()
    }
  }

  getParams(): any {
    let params: any = {}
    params["limit"] = (this.paginator?.pageSize ?? this.defaultPageSize);
    params["offset"] = (this.paginator?.pageIndex ?? 0) * params["limit"];
    if (this.couponSearchText) {
      params["search"] = this.couponSearchText;
    }
    if (this.couponFilterType) {
      params["type"] = this.couponFilterType;
    }
    if (this.couponFilterStartDate) {
      params["start_date"] = this.utilities.getFormattedDateDDMMYYYY(this.couponFilterStartDate)
    }
    if (this.couponFilterEndDate) {
      params["end_date"] = this.utilities.getFormattedDateDDMMYYYY(this.couponFilterEndDate)
    }
    return params;
  }

}
